import React, { Component } from 'react';
import SideMenu from './sideMenu.js';
import { CSVReader } from 'react-papaparse';
import Modal from 'react-modal';
//---------CONNECTIONS------------//
import img_elWhatsapp from './images/svgicons/whatsapp.svg';
import img_elWechat from './images/svgicons/wechat.svg';
import img_elLine from './images/svgicons/line.svg';
import img_elSnapchat from './images/svgicons/snapchat.svg';
import img_elInstagram from './images/svgicons/instagram.svg';
import img_elTwitter from './images/svgicons/twitter.svg';
import img_elViber from './images/svgicons/viber.svg';
import img_elTelegram from './images/svgicons/telegram.svg';
import img_elSms from './images/svgicons/sms.svg';
import img_elqq from './images/svgicons/qq.svg';
import img_elPinterest from './images/svgicons/pinterest.svg';
import img_elLinkedin from './images/svgicons/linkedin.svg';
import img_elYoutube from './images/svgicons/youtube.svg';
import img_elBlChat from './images/svgicons/blchat.svg';
import img_elMessenger from './images/svgicons/messenger.svg';
import img_elKik2 from './images/svgicons/kik.svg';
import img_elF_icon from './images/svgicons/facebook.svg';
import img_elShape from './images/svgicons/reddit.svg';
import img_elClientChat from './images/svgicons/clientchat.svg';
import img_elHubspot from './images/svgicons/hubspot.svg';


import img_elSa from './images/svgicons/sabot.svg';
import img_elTts from './images/svgicons/ttsbot.svg';
import img_elStt from './images/svgicons/sttbot.svg';
import img_elVrBot from './images/svgicons/vrbot.svg';
import img_elLp from './images/svgicons/lpbot.svg';
import img_elLt from './images/svgicons/ltbot.svg';
import img_elPiBot from './images/svgicons/pibot.svg';
import img_elLc from './images/svgicons/lcbot.svg';
import img_elCsvt from './images/svgicons/esbot.svg';
import img_elFaq from './images/svgicons/faqbot.svg';
import img_elRoom from './images/svgicons/videoroom.svg';

import img_elVr from './images/svgicons/vr.svg';
import img_elPi from './images/svgicons/pi.svg';
import img_elsa2 from './images/svgicons/sa.svg';
import img_eltts2 from './images/svgicons/tts.svg';
import img_elstt2 from './images/svgicons/stt.svg';
import img_ellp2 from './images/svgicons/lp.svg';
import img_ellc2 from './images/svgicons/lc.svg';
import img_ellt2 from './images/svgicons/lt.svg';


import img_elMd from './images/svgicons/md.svg';
import img_elOracle_logo from './images/svgicons/oracle.svg';
import img_elSf from './images/svgicons/sf.svg';
import img_elsap from './images/svgicons/sap.svg';
import img_elSs from './images/svgicons/siebel.svg';
import img_elzen from './images/svgicons/zen.svg';
import img_elact from './images/ec/act.png';
import img_elzoho from './images/svgicons/zoho.svg';


/*import img_update from './images/update.png';
import img_remove from './images/remove.png';*/
import img_save from './images/buttons/save.svg';
import img_save_disable from './images/buttons/save_disable.svg';
import img_new from './images/buttons/new.svg';
import img_new_disable from './images/buttons/new_disable.svg';
import img_update from './images/buttons/update.svg';
import img_remove from './images/buttons/remove.svg';
import img_remove_disable from './images/buttons/remove_disable.svg';
import img_blank_msg from './images/buttons/message.svg';

import img_loader from './images/BL-PreLoader.gif';
import img_elClose from './images/close.svg';
import img_prLoader from './images/provision.gif';
import img_Refresh from './images/buttons/refresh.svg';
import {Auth} from "aws-amplify";
import axios from 'axios';
import { default as Amplify, Storage} from "aws-amplify";
import FacebookLogin from 'react-facebook-login';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import ReactTooltip from 'react-tooltip';

var AWS = require("aws-sdk");
const jsonConfig = require("./Config.json");
const buttonRef = React.createRef();

const countryListJson = {"Canada": "No", "Sao Tome and Principe": "Yes", 
"Guinea-Bissau": "Yes - Pre-Registration Required", "Lithuania": "Yes", "Cambodia": "Yes", "Switzerland": "Yes", 
"Ethiopia": "Yes - Pre-Registration Required", "Aruba": "Yes", "Swaziland": "Yes - Pre-Registration Required", 
"Palestine": "Yes", "Argentina": "No", "Bolivia": "Yes", "Cameroon": "Yes - Pre-Registration Required", "Burkina Faso": "Yes",
 "Turkmenistan": "Yes", "Bahrain": "Yes", "Saudi Arabia": "Yes - Pre-Registration Required for non-Saudi Arabia-based companies .Pre-registration not supported for KSA-based companies", 
 "Virgin Islands (British Virgin Islands)": "Yes", "Japan": "Yes", "Cape Verde": "Yes", "Slovenia": "Yes", "Guatemala": "Yes", 
 "Bosnia and Herzegovina": "Yes", "Kuwait": "Yes - Pre-Registration Required", "Jordan": "Yes - Pre-Registration Required", 
 "St. Lucia": "Yes", "Dominica": "Yes", "Liberia": "Yes - Pre-Registration Required", "Maldives": "Yes", 
 "Timor-Leste (East Timor)": "Yes", "Jamaica": "Yes", "Oman": "Yes - Pre-Registration Required", 
 "Tanzania": "Yes - Pre-Registration Required", "Martinique": "Yes", "Greenland": "Yes", "Gabon": "Yes", 
 "Monaco": "No", "New Zealand": "No", "Yemen": "Yes", "Jersey": "Yes", "Andorra": "Yes", "Albania": "Yes", "Samoa": "Yes", 
 "United Arab Emirates": "Yes - Pre-Registration Required", "Guam": "No", "Uruguay": "No", "India": "No", "Azerbaijan": "Yes", 
 "Lesotho": "Yes", "Saint Vincent and the Grenadines": "Yes", "Kenya": "Yes - Pre-Registration Required",
  "South Korea": "No", "Tajikistan": "Yes", "Turkey": "Yes - Pre-Registration Required", 
  "Afghanistan": "Yes - Pre-Registration Required", "Bangladesh": "Yes", "Solomon Islands": "Yes", 
  "Turks and Caicos Islands": "Yes", "San Marino": "Yes", "Kyrgyzstan": "No", "French Polynesia": "Yes", "France": "Yes", 
  "Bermuda": "Yes", "Slovakia": "Yes", "Somalia": "Yes", "Peru": "No", "Vanuatu": "Yes", "Nauru": "No", "Seychelles": "Yes", 
  "Norway": "Yes", "Malawi": "Yes - Pre-Registration Required only for companies based in Malawi that plan to send more than 30,000 SMS per month. (Not required if company is not based in Malawi or if doing less than 30,000 SMS per month)", 
  "Cook Islands": "Yes", "Benin": "Yes - Pre-Registration Required", "Réunion": "Yes", 
  "Cuba": "Yes - Pre-Registration Required", "Montenegro": "Yes", "Togo": "Yes", "Armenia": "Yes - Pre-Registration Required",
   "Dominican Republic": "No", "Congo (Democratic Republic of the Congo)": "Yes", "Ukraine": "Yes", 
   "Ghana": "Yes - Pre-Registration Required", "Tonga": "Yes", "Indonesia": "Yes - Pre-Registration Required", "Libya": "Yes", 
   "Finland": "Yes", "Central African Republic": "Yes", "Mauritius": "Yes", "Liechtenstein": "Yes", 
   "Belarus": "Yes only for non Belarus based companies - Pre-Registration Required", "Cayman Islands (UK)": "No", 
   "Mali": "Yes", "Russia": "Yes - Pre-Registration Required,Russia Sender ID Registration has currently been paused.", 
   "Bulgaria": "Yes", "United States": "No", "Romania": "Yes", "Angola": "Yes", "Chad": "Yes", "South Africa": "No", 
   "Cyprus": "Yes", "Macau (PRC)": "Yes", "Sweden": "Yes", "Qatar": "Yes - Pre-Registration Required", 
   "Antigua & Barbuda": "Yes", "Malaysia": "No", "Austria": "Yes", "Vietnam": "Yes - Pre-Registration Required", 
   "Mozambique": "Yes", "Uganda": "Yes - Pre-Registration Required", "Hungary": "No", "Niger": "Yes", "Isle of Man": "Yes", 
   "Brazil": "Yes - Pre-Registration Required", "Laos (Lao People's Democratic Republic)": "Yes", "Falkland Islands": "Yes", 
   "Faroe Islands": "Yes", "Guinea": "Yes - Pre-Registration Required", "Panama": "No", "Costa Rica": "No", "Luxembourg": "Yes",
    "American Samoa": "Yes", "Bahamas": "No", "Gibraltar": "Yes", "Ireland": "Yes", "Pakistan": "Yes - Pre-Registration Required only for companies based in Pakistan that plan to send more than 30,000 SMS per month. (Not required if company is not based in Pakistan or if doing less than 30,000 SMS per month)", 
    "Nigeria": "Yes - Pre-Registration Required", "Ecuador": "No", 
    "Czech Republic": "Yes - Pre-Registration Required", "Brunei": "Yes", "Australia": "Yes", 
    "Iran": "Yes - Pre-Registration Required", "Algeria": "Yes", "El Salvador": "No", "St. Kitts and Nevis": "Yes", 
    "Chile": "No", "Puerto Rico": "No", "Belgium": "No", "Thailand": "Yes - Pre-Registration Required", "Haiti": "Yes", 
    "Belize": "Yes", "Hong Kong": "Yes - Pre-Registration Required", "Sierra Leone": "Yes", "Georgia": "Yes", "Gambia": "Yes",
     "Philippines": "Yes - Pre-Registration Required", "Moldova": "Yes", "Morocco": "Yes - Pre-Registration Required",
      "Croatia": "Yes", "Mongolia": "Yes", "Guernsey": "Yes", "Namibia": "Yes", "Grenada": "Yes", "Iraq": "Yes", 
      "Portugal": "Yes", "Estonia": "Yes", "Kosovo": "Yes", 
      "Mexico": "Yes - Pre-Registration Required.  /a Minimum 1,000 SMS per month.", 
      "Lebanon": "Yes - Pre-Registration Required only for companies based in Lebanon that plan to send more than 30,000 SMS per month. (Not required if company is not based in Lebanon or if doing less than 30,000 SMS per month)", "Uzbekistan": "Yes", 
      "Tunisia": "Yes - Pre-Registration Required only for companies based in Tunisia that plan to send more than 30,000 SMS per month. (Not required if company is not based in Tunisia or if doing less than 30,000 SMS per month)", "Djibouti": "Yes",
       "Rwanda": "Yes - Pre-Registration Required", "Spain": "Yes", "Colombia": "No", "Burundi": "Yes", "Taiwan": "No", "Fiji": "Yes", "Barbados": "Yes", "Madagascar": "Yes", "Italy": "Yes", "Bhutan": "Yes",
        "Sudan": "Yes - Pre-Registration Required", "Congo (Republic of the Congo)": "Yes - Pre-Registration Required", "Nepal": "Yes - Pre-Registration Required", "Malta": "Yes", "Netherlands": "Yes", "Suriname": "Yes", "Anguilla": "Yes", "Venezuela": "Yes",
        "Netherlands Antilles": "Yes", "Israel": "Yes - Pre-Registration Required only for companies based in Israel that plan to send more than 30,000 SMS per month. (Not required if company is not based in Israel or if doing less than 30,000 SMS per month)",
         "Macedonia": "Yes", "Iceland": "Yes", "Zambia": "Yes - Pre-Registration Required", "Senegal": "Yes", "Papua New Guinea": "Yes", "Trinidad and Tobago": "Yes", 
        "Zimbabwe": "Yes - Pre-Registration Required only for companies based in Zimbabwe that plan to send more than 30,000 SMS per month. (Not required if company is not based in Zimbabwe or if doing less than 30,000 SMS per month)",
         "Germany": "Yes", "Denmark": "Yes", "Kazakhstan": "Yes - Pre-Registration Required", "Poland": "Yes", "Mauritania": "Yes", "Ivory Coast (C\u00f4te d'Ivoire)": "Yes - Pre-Registration Required", "Mayotte": "Yes", 
      "Montserrat": "Yes", "New Caledonia": "Yes", "Guadeloupe and Martinique": "Yes", 
      "Sri Lanka": "Yes - Pre-Registration Required", "Latvia": "Yes", "South Sudan": "Yes - Pre-Registration Required", "Guyana": "Yes", "Syria": "No", "Honduras": "Yes", "Myanmar": "Yes - Pre-Registration Required", "Equatorial Guinea": "Yes", 
      "Egypt": "Yes - Pre-Registration Required", "Nicaragua": "No", "Singapore": "Yes", "Serbia": "Yes - Pre-Registration Optional", "Botswana": "Yes", 
      "United Kingdom": "Yes", "China": "No", "Greece": "Yes", "Paraguay": "No", "French Guiana": "No", "Comoros": "Yes"}


  export default class Interaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alphaFieldDisabled:true,
      alphaNumericEnabled:false,
      alphaNumericCountry:"0",
      userCountry:"United States",

      defaultCognitoId:'',
      defaultOrgName:'',
      defaultEmail:'',
      defaultOrgId:'',
      defaultUserName:'',
      defaultRoleName:'',
      backgoroundZindex:'',
      availableChannelsClick:true,
	  	pannelVal:'',
      boardList:[],
      disabledFilterStatus:true,
		activeInteractions:[],
		boardName:'',
		status:'',
		createDate:'',
    updateDate:'',
    createdByUser:'',
    updatedByUser:'',
		activeIndex: -1,
		activeList:-1,
		activeIcon:-1,
		ServiceIcon:-1,
		CrmIcon:-1,
		messengerPages:[],
		websiteList:[],
		websiteName:'',
		chatText:'',
		iconFile:'',
		activeBackground:'',
		boardId:'',
    fbName:'',
    pageLoadedforMessenger:false,
    createButtonStatus:true,
    boardNameStatus:false,
    saveButtonStatus:false,
    updateButtonStatus:true,
    eventFireStatus:true,
    addOption:'',
    websiteListIcon:[],
    entryPointWebsite:[],
    optionLabel:'',
    crmSetupArray:[],
    aiSetupArr:[],
    channelSetupArr:[],
    saveUpdtMsg:'',
    addMessengerId: '',
    messengerPageName:'',
    entryPointMessenger:[],
    boardNameReadonly:true,
		//-----BLUELEAP CHAT---//
		userName:'',
		olduserName:'',
		userPassword:'',
		userId:'',
		updateAdminButtonstat:false,
		updateAdmintxtstat:false,
		integrationUser:'',
		oldIntegrationUserName:'',
		integrationEmail:'',
		integrationPassword:'',
		integrationUserId:'',
		intStatus:false,
		intEmailStatus:false,
		isDisabled:false,
		otherFieldstate:false,
		baseUrl:'',
		checkPort:15000,
    checkingChat:false,
    transferMsg:'',
    noAgentsMsg:'',
    provisionedResourceName:'',
    blChatDeallocation:true,
    blueleapChatLoginUrl:'',
    ipAdressUrl:'',
    readonlyAllblChat:true,
    hideUpdatebuttonforBlAdmin:'none',
    hideEditbuttonforBlAdmin:'inline-block',
    hideEditbuttonBlUser:'inline-block',
    hideUpdatebuttonBlUser:'none',
    hideCreateButtonWait:'inline-block',
    panel3Display:'none',
    activeAdminDetails:'',
    changeTextboxborder:'transparent',
    activeIntegrationDetails:'',
    hideAllButtonforAdmin:'inline-block',
    hideAllButtonforIntUser:'inline-block',
    openIntUserUpdateButton:'none',
    openAdminUpdateButton:'none',
    waitBoxreadonly:true,
    activeWaitMsg:'',
    hideEditbuttonBlMsg:'inline-block',
    openWaitMsg:'none',
    transferMsgStatus:false,
    blueleapChatLoginUrlStatus:true,
    
		//-----SA---//
    analyse:[],
    createticket:[],
    utterenceArr:[],
    varIntentName:'',
    clarificationPrompt:'',
    abortStatement:'',
    saProvisionButtonStatus:false,
    disableUpdateUtterence:true,
    slotsArr:[],
    wholeSaBotArr:'',
    SaBotProvisioningStatus:false,
    subweightage:'',
    descWeightage:'',
    deallocateBotButton:false,
		//---ELASTIC SEARCH---//
		subTitle:'Create New Instance',
		isDisabledSearch:false,
		domainName: '',
		volumeSize:'',
		errorMsg:'',
		resourceName:'',
    checkingSearch:true,
    defaultmsgStatus:true,
		numberOfRows:0,
		checkedPattern:false,
		selectUniqueId:[],
    insertMsg:'',
    csvUploadUrl:null,
    typeMatch:'ExactMatch',
    getCsvFileName:'',
    getLastCsvFileName:'',
    getLastCsvRows:'',
    getLastCsvHeaders:[],
    modalGetCsvRowsIsOpen:false,
    modalconfirmDeleteCsvIsOpen:false,
    getTotalCsvRows:[],
    csvUploadStatus:true,
    //-----FAQ-----//
    questions:'',
    answers:'',
    questionList:[],
    createQuestionJson:'',
    pharseName:'',
    getfaqID:'',
    isEditable:false,
    editfaqId:'',
    editableJson:[],
    pharseEnable:'none',
    enableLiveChatStatus:'',
    dropDownVal:0,
    allFaqfieldReadonly:true,
    StatusForLfaq:true,
    //-----Client Chat-----//
	varBotName:'',
	varGenericErrorMessage:'',
  shippingId:0,
  uniqueId:0,
  clientChatTimeout:'1',
  clientChatTimeoutMessage:'',
  clientChatGreeting:'',
  clientChatColor:'#008CFF',
  endClientChat:'',
  
  //--------LIVE CHAT---------//
  websiteLiveName:'',
  websiteLiveList:[],
  liveIconFile:'',
  livechatText:'',
  livevarBotName:'',
  livevarGenericErrorMessage:'',
  addLiveChatWebsiteOption:'',
  LiveChatWebsiteOptionLabel:'',
  entryPointLiveChatWebsite:[],
  liveChatTimeout:'1',
  liveChatTimeoutMessage:'',
  liveChatGreeting:'',
  liveChatGreeting:'',
  liveChatColor:'#008CFF',
  endLiveChat:'',
		//----ZENDESK---//
    zenUser:'',
    apikey:'',
    zendeskUrl:'',
    zendeskDataLength:0,
    //-----SALES FORCE---//
    sfUsername:'',
    sfPassword:'',
    sfAccessToken:'',
    sfBaseUrl:'',
    sfCreateDisabled:false,
    sfUpdateDisabled:true,
    //----------SMS----------//
    twilioAccSid:'',
    twilioAccName:'',
    phoneNumber:'',
    twilioAuthToken:'',
    twilioMsgServiceId:'',
    twilioSubAccName:'',
    smsProvisionStatus:false,
    getAccSubid:'',
    getPhoneNumber:'',
    smsProvisionButtonStatus:false,
    getPhoneNumberList:[],
    listOfPhone:[],
    
    typeForSms:'',
    phoneForSms:'',
    incomingBoardId:'',
    addedPhoneNumber:[],
    responseMsg:'',
    responseSelection:'XX',
    smsPosition:0,
    antiSpamTimer:1,
    autoResponseStatus:false,
    phoneNumberJson:[],
    //----------ORACLE-------//
    orUsername:'',
    orPassword:'',
    orUrl:'',
    orCreateDisabled:false,
    orUpdateDisabled:true,
    //------VIDEO ROOM-------//
    changeVdoroomTimer:false,
    changeVdoroomPrivacy:false,
    changeSaveVideo:false,
    vdoSurround:'Blue',
    videoroomName:'',
    videoroomUrl:'',
    privacyText:'',
    videoroomTimer:1,
    videoroomButtonstatus:true,
    getVideoRoomUrl:'',
    //-------LOADER--------//
    isLoading:true,
    overlay:'block',
    changeOpacity:1,
    //--------OTHERS---------//
    timeDifference:'',
    inactiveIcon:'',
    settingsVal:false,
    modalIsOpen: false,
    modalDisplay:'none',
		availableIntegrations: [
			{
				name: "Messenger",
				icon: img_elMessenger,
				type:"channels",
				action:"enable",
        key:"",
        displayName:"Messenger"  
			},
			{
				name: "Chat",
				icon: img_elClientChat,
				type:"channels",
				action:"enable",
        key:"",
        displayName:"Chat System",
        extraClass:"moonbase",
        extraClassforIconName:"iconNameForCC"
			},
			{
				name: "Client Chat",
				icon: img_elBlChat,
				type:"channels",
				action:"enable",
        key:[],
        displayName:"Client Chat",
        
      },
      {
				name: "Live Chat",
				icon: img_elBlChat,
				type:"channels",
				action:"enable",
        key:[],
        displayName:"Live Chat",
        
			},
			{
				name: "Wechat",
				icon: img_elWechat,
				type:"channels",
        action:"disable",
        displayName:"Wechat"
			},
			{
			  name: "Whatsapp",
			  icon: img_elWhatsapp,
			  type:"channels",
        action:"disable",
        displayName:"Whatsapp"
			},
			
			{
			  name: "kik",
			  icon: img_elKik2,
			  type:"channels",
        action:"disable",
        displayName:"kik"
			},
			{
			  name: "line",
			  icon: img_elLine,
			  type:"channels",
			  action:"disable",
        key:"",
        displayName:"line"
			},
			{
			  name: "Snapchat",
			  icon: img_elSnapchat,
			  type:"channels",
			  action:"disable",
        key:"",
        displayName:"Snapchat"
			},
			
			{
			  name: "Instagram",
			  icon: img_elInstagram,
			  type:"channels",
			  action:"disable",
        key:"",
        displayName:"Instagram"
			},
			{
			  name: "Twitter",
			  icon: img_elTwitter,
			  type:"channels",
			  action:"disable",
        key:"",
        displayName:"Twitter"
			},
			{
			  name: "Viber",
			  icon: img_elViber,
			  type:"channels",
			  action:"disable",
        key:"",
        displayName:"Viber"
			},
			{
			  name: "Telegram",
			  icon: img_elTelegram,
			  type:"channels",
			  action:"disable",
        key:"",
        displayName:"Telegram"
			},
			{
			  name: "Facebook",
			  icon: img_elF_icon,
			  type:"channels",
			  action:"disable",
        key:"",
        displayName:"Facebook"
			},
			{
			  name: "Sms",
			  icon: img_elSms,
			  type:"channels",
			  action:"disable",
        key:"",
        displayName:"Sms"
      },
      {
			  name: "Hubspot",
			  icon: img_elHubspot,
			  type:"channels",
			  action:"disable",
        key:"",
        displayName:"Hubspot"
      },
      
			{
			  name: "QQ",
			  icon: img_elqq,
			  type:"channels",
			  action:"disable",
        key:"",
        displayName:"QQ"
			},
			{
			  name: "Pinterest",
			  icon: img_elPinterest,
			  type:"channels",
			  action:"disable",
        key:"",
        displayName:"Pinterest"
			},
			{
			  name: "Linkedin",
			  icon: img_elLinkedin,
			  type:"channels",
			  action:"disable",
        key:"",
        displayName:"Linkedin"
			},
			{
			  name: "Youtube",
			  icon: img_elYoutube,
			  type:"channels",
			  action:"disable",
        key:"",
        displayName:"Youtube"
			},
			{
			  name: "Reddit",
			  icon: img_elShape,
			  type:"channels",
			  action:"disable",
        key:"",
        displayName:"Reddit"
			},
		  ],
		availableServices: [
			{
			  name: "Sentiment Bot",
			  icon: img_elSa,
			  type:"aiservice",
        action:"enable",
        key:"",
        displayName:"Sentiment Bot"
			},
			/*{
			  name: "Text to Speech",
			  icon: img_elTts,
			  type:"aiservice",
        action:"disable",
        key:"",
        displayName:"Text to Speech Bot"
			},
			{
			  name: "Speech to Text",
			  icon: img_elStt,
			  type:"aiservice",
        action:"disable",
        key:"",
        displayName:"Speech to Text Bot"
			},
			{
			  name: "Language Processing",
			  icon: img_elLp,
			  type:"aiservice",
        action:"disable",
        key:"",
        displayName:"Language Bot"
			},
			{
			  name: "Language Translator",
			  icon: img_elLt,
			  type:"aiservice",
        action:"disable",
        key:"",
        displayName:"Translator Bot"
			},
			{
			  name: "Language Classifier",
			  icon: img_elLc,
			  type:"aiservice",
        action:"disable",
        key:"",
        displayName:"Classifier Bot"
      },
      {
			  name: "Visual Bot",
			  icon: img_elVrBot,
			  type:"aiservice",
        action:"disable",
        key:"",
        displayName:"Visual Bot"
      },
      {
			  name: "Personality Bot",
			  icon: img_elPiBot,
			  type:"aiservice",
        action:"disable",
        key:"",
        displayName:"Personality Bot"
			},*/
			{
			  name: "Lambda Faq",
			  icon: img_elFaq,
			  type:"aiservice",
			  action:"enable",
        key:"",
        displayName:"Query Bot"
			},
			{
			  name: "Elastic Search",
			  icon: img_elCsvt,
			  type:"aiservice",
			  action:"enable",
        key:"",
        displayName:"Dialogue Search"
      },
      {
			  name: "Visual Recognition",
			  icon: img_elVr,
			  type:"aiservice",
        action:"disable",
        key:"",
        displayName:"Visual Recognition"
      },
      {
			  name: "Personality Insights",
			  icon: img_elPi,
			  type:"aiservice",
        action:"disable",
        key:"",
        displayName:"Personality Insights"
      },
      {
			  name: "Sentiment Analysis",
			  icon: img_elsa2,
			  type:"aiservice",
        action:"disable",
        key:"",
        displayName:"Sentiment Analysis"
      },
      {
			  name: "Text to Speech",
			  icon: img_eltts2,
			  type:"aiservice",
        action:"disable",
        key:"",
        displayName:"Text to Speech"
      },
      {
			  name: "Speech to Text",
			  icon: img_elstt2,
			  type:"aiservice",
        action:"disable",
        key:"",
        displayName:"Speech to Text"
      },
      {
			  name: "Language Processing",
			  icon: img_ellp2,
			  type:"aiservice",
        action:"disable",
        key:"",
        displayName:"Language Processing"
      },
      {
			  name: "Language Classifier",
			  icon: img_ellc2,
			  type:"aiservice",
        action:"disable",
        key:"",
        displayName:"Language Classifier"
      },
      {
			  name: "Language Translator",
			  icon: img_ellt2,
			  type:"aiservice",
        action:"disable",
        key:"",
        displayName:"Language Translator"
      },
      {
			  name: "Video Room",
			  icon: img_elRoom,
			  type:"aiservice",
        action:"disable",
        key:"",
        displayName:"Video Room"
			}
		  ],
		  availableCrm: [
			{
			  name: "Microsoft Dynamics",
			  icon: img_elMd,
			  type:"crm",
        action:"disable",
        key:"",
        displayName:"Microsoft Dynamics"
			},
			{
			  name: "Oracle",
			  icon: img_elOracle_logo,
			  type:"crm",
        action:"disable",
        key:"",
        displayName:"Oracle"
			},
			{
			  name: "Sales Force",
			  icon: img_elSf,
			  type:"crm",
        action:"disable",
        key:"",
        displayName:"Sales Force"
			},
			{
			  name: "SAP",
			  icon: img_elsap,
			  type:"crm",
        action:"disable",
        key:"",
        displayName:"SAP"
			},
			{
			  name: "Siebel",
			  icon: img_elSs,
			  type:"crm",
        action:"disable",
        key:"",
        displayName:"Siebel"
			},
			{
			  name: "Zendesk",
			  icon: img_elzen,
			  type:"crm",
        action:"enable",
        addClass:"",
        key:"",
        displayName:"Zendesk"
			},
			{
			  name: "ZOHO",
			  icon: img_elzoho,
			  type:"crm",
        action:"disable",
        key:"",
        displayName:"ZOHO"
			}
    ],
    zendeskInput:[
        {
            "connector": "zendesk",
            "action": "createZenTicket",
            "label":"Create Ticket",
            "Text": "Create a support ticket",
            "status":"enabled"
        },
        {
            "connector": "zendesk",
            "action": "lookupZenTicket",
            "label":"Lookup Ticket",
            "Text": "Lookup a support ticket",
            "status":"enabled"
        },
        {
            "connector": "zendesk",
            "action": "updateZenTicket",
            "label":"Update Ticket",
            "Text": "Update a support ticket",
            "status":"enabled"
        },
        {
            "connector": "zendesk",
            "action": "listZenTickets",
            "label":"List Tickets",
            "Text": "List all the support tickets",
            "status":"enabled"
        }
      ],
      zendeskOutput:[
          {
              "connector": "zendesk",
              "action": "createZenTicket"
          },
          {
              "connector": "zendesk",
              "action": "lookupZenTicket"
          },
          {
              "connector": "zendesk",
              "action": "updateZenTicket"
          },
          {
              "connector": "zendesk",
              "action": "listZenTickets"
          }
    ],
      salesforceInput:[
          {
              "connector": "salesforce",
              "action": "createSfCase",
              "label":"Create Ticket",
              "Text": "Create a support case",
              "status":"enabled"
          },
          {
              "connector": "salesforce",
              "label":"Create Lead",
              "action": "createSfLead",
              "Text": "Create a lead",
              "status":"enabled"
          },
          {
              "connector": "salesforce",
              "action": "convertSfLead",
              "label":"Convert Lead",
              "Text": "Convert a lead",
              "status":"enabled"
          }
      ],
salesforceOutput:[
        {
            "connector": "salesforce",
            "action": "createSfCase",
        },
        {
            "connector": "salesforce",
            "action": "createSfLead",
        },
        {
            "connector": "salesforce",
            "action": "convertSfLead",
        }
    ],
    blueleapchatInput:[
        {
            "connector": "blueleapchat",
            "action": "enableLiveChat",
            "status": "enabled",
            "label":"Please transfer to me an agent",
            "Text": "Please transfer to me an agent"
        }
    ],
    blueleapchatOutput:[
        {
            "connector": "blueleapchat",
            "action": "enableLiveChat",
            "status": "enabled",
        }
    ],
    oracleInput:[
      {
          "connector": "oracle",
          "action": "enableLiveChat",
          "status": "enabled",
          "label":"Please transfer to me a live agent",
          "Text": "Please transfer to me a live agent"
      }
  ],
  oracleOutput:[
      {
          "connector": "oracle",
          "action": "enableLiveChat",
          "status": "enabled",
      }
  ],
    sentimentBotZendesk:[
      {
        "name":"Create a support ticket",
        "intentName": "CreateZendeskTicket",
        "intentVersion": "1",
        "status":true,
      },
      {
        "name":"Lookup a support ticket",
        "intentName": "showTicket",
        "intentVersion": "1",
        "status":true,
      },
      {
        "name":"Update a support ticket",
        "intentName": "UpdateTicket",
        "intentVersion": "1",
        "status":true,
      },
      {
        "name":"List all the support tickets",
        "intentName": "ShowAllTickets",
        "intentVersion": "3",
        "status":true,
      },
      
    ],
    sentimentBotSalesforce:[
      {
        "name":"Create a support case",
        "intentName": "ScreateCase",
        "intentVersion": "1",
        "status":true,
      },
      {
        "name":"Create a lead",
        "intentName": "ScreateLead",
        "intentVersion": "1",
        "status":true,
      },
      {
        "name":"Convert a lead",
        "intentName": "SleadConversion",
        "intentVersion": "1",
        "status":true,
      },
      
    ],
	};
	this.getActiveInteraction = this.getActiveInteraction.bind(this);
  this.sendwebsite = this.sendwebsite.bind(this);
  this.sendLivewebsite = this.sendLivewebsite.bind(this);
  
  this.activeMake = this.activeMake.bind(this);
  this.resetPanel = this.resetPanel.bind(this);

  this.updateBoard = this.updateBoard.bind(this);
  this.deleteBoard = this.deleteBoard.bind(this);
	this.sendcompName = this.sendcompName.bind(this);
	this.editAdminField = this.editAdminField.bind(this);
	this.updateAdminUser = this.updateAdminUser.bind(this);
	this.getAdminuserId = this.getAdminuserId.bind(this);
	this.editIntegration = this.editIntegration.bind(this);
	this.sendIntegrationCreds = this.sendIntegrationCreds.bind(this);
	this.updateSettings = this.updateSettings.bind(this);
	this.deallocateResource = this.deallocateResource.bind(this);
	this.setDefaultMsg = this.setDefaultMsg.bind(this);
	this.deallocateElasticSearch = this.deallocateElasticSearch.bind(this);
  //this.getCsvFile = this.getCsvFile.bind(this);
  this.createNewBoard = this.createNewBoard.bind(this);
  this.openNewBoard = this.openNewBoard.bind(this);
  this.submitPharse = this.submitPharse.bind(this);
  this.makeEntrypoint = this.makeEntrypoint.bind(this);
  this.makeLiveChatEntrypoint = this.makeLiveChatEntrypoint.bind(this);
  this.deleteWebsite = this.deleteWebsite.bind(this);
  this.deleteLiveWebsiteFromEntryPoint = this.deleteLiveWebsiteFromEntryPoint.bind(this);
  this.deleteWebEntry = this.deleteWebEntry.bind(this);
  this.deleteLiveWebEntry = this.deleteLiveWebEntry.bind(this);
  this.updateUtternce = this.updateUtternce.bind(this);
  this.deleteFaq = this.deleteFaq.bind(this);
  this.addMsgService = this.addMsgService.bind(this);
  this.updatebotStatus = this.updatebotStatus.bind(this);
  this.sendSalesforceCreds = this.sendSalesforceCreds.bind(this);
  this.updtSalesforceCreds = this.updtSalesforceCreds.bind(this);
  this.saveChatSettings=this.saveChatSettings.bind(this);
  this.saveLiveChatSettings=this.saveLiveChatSettings.bind(this);
  this.createSABot=this.createSABot.bind(this);
  this.updateSABot=this.updateSABot.bind(this);
  this.dateDifference=this.dateDifference.bind(this);
  this.updateSetup=this.updateSetup.bind(this);
  this.getBoardDataAfterSave=this.getBoardDataAfterSave.bind(this);
  this.closeModal = this.closeModal.bind(this);
  this.checkBeforeFblogin = this.checkBeforeFblogin.bind(this);
  this.manualRefresh = this.manualRefresh.bind(this);
  this.updateZendeskForLfaq = this.updateZendeskForLfaq.bind(this);
  this.changeStatusForLfaq = this.changeStatusForLfaq.bind(this);
  this.makeEntrypointForMessengerPages = this.makeEntrypointForMessengerPages.bind(this);
  this.deletePageEntry = this.deletePageEntry.bind(this);
  this.getIconfile = this.getIconfile.bind(this);
  this.getLiveIconfile = this.getLiveIconfile.bind(this);
  
  this.provisionTwilio = this.provisionTwilio.bind(this);
  this.deallocateTwilio = this.deallocateTwilio.bind(this);
  this.deleteMessengerPageFromAddedpage = this.deleteMessengerPageFromAddedpage.bind(this);
  
  this.doubleClickTimeout = 500; // default doubleclick timeout
  this.clickedTimeout = null;
  
  }
componentDidMount()
{
  this.getPageAccess();
  this.setState({
    chatIcon:jsonConfig.s3BucketUrl+'no-preview-available.png',
    livechatIcon:jsonConfig.s3BucketUrl+'no-preview-available.png',
  });
  var finalObj = this.state.sentimentBotZendesk.concat(this.state.sentimentBotSalesforce);
  this.setState({wholeSaBotArr:finalObj})
  
  
}
closeModal() {
  this.setState({
    modalDisplay: 'none',
    boardName:''
  });
}
awsConfig()
{
  AWS.config.update({
		credentials: new AWS.CognitoIdentityCredentials({
		  IdentityPoolId: jsonConfig.awsConfigCredentials.IdentityPoolId,
		  RoleArn: jsonConfig.awsConfigCredentials.RoleArn,
		}),
		region: jsonConfig.awsConfigRegion,
	  });
}


// async getUserCountry(){
//           var getUserById = await axios.get(jsonConfig.rdsApi+"/organizations/"+user.attributes.sub,{
//           headers: {
//             'X-API-KEY': jsonConfig.ApiKey,
//             'Content-Type': 'application/json'
//           }});
//           console.log("ORG ID",getUserById.data.data[0].organizationId);
// }
async getPageAccess()
{
      try {
        var user = await Auth.currentAuthenticatedUser();
        console.log(user);
        var getUserById = await axios.get(jsonConfig.rdsApi+"users/"+user.attributes.sub,{
          headers: {
            'X-API-KEY': jsonConfig.ApiKey,
            'Content-Type': 'application/json'
          }});
          console.log("ORG ID",getUserById.data.data[0].organizationId);


          var orgDetails = await axios.get(jsonConfig.rdsApi+"/organizations/"+getUserById.data.data[0].organizationId,{
          headers: {
            'X-API-KEY': jsonConfig.ApiKey,
            'Content-Type': 'application/json'
          }});
          console.log("userCountry",orgDetails.data.data.country);

          let userCountry = ''
          try{
            userCountry = orgDetails.data.data.country
          }
          catch(err){
            console.log(err)
          }




        this.setState({
          defaultOrgName:getUserById.data.data[0].organization.name,
          defaultOrgId:getUserById.data.data[0].organizationId,
          defaultUserName:user.attributes.name,
          defaultRoleName:getUserById.data.data[0].role.name,
          defaultCognitoId:user.username,
          defaultEmail:getUserById.data.data[0].email,
          userCountry: userCountry
          //domainName:getUserById.data.data[0].organization.name,
        })
        if(getUserById===null || getUserById===undefined)
        {
          //this.props.appActions.goToScreen('notfound',{ transitionId: 'fadeIn' });
        }
        else{
          if(getUserById.data.data[0].role.name==='System' || getUserById.data.data[0].role.name==='Admin')
          {

          }
          else{
            var resScreen = await axios.get(jsonConfig.rdsApi+"screenPermissionByRole/"+getUserById.data.data[0].roleId,{
              headers: {
                'X-API-KEY': jsonConfig.ApiKey,
                'Content-Type': 'application/json'
              }});
            if(resScreen.status===200)
            {
              if(resScreen.data.data[3].status==='false')
              {
                //this.props.appActions.goToScreen('notfound',{ transitionId: 'fadeIn' });
              }
            }
          }
        }
        /**/this.getBoardData(getUserById.data.data[0].organizationId);
      this.getSetUp(getUserById.data.data[0].organizationId);
      this.getGlobalSettings();
     
      this.getResources(getUserById.data.data[0].organizationId);
      this.getTicketDetails(getUserById.data.data[0].organizationId);
      } catch (error) {
        //this.props.appActions.goToScreen('notfound',{ transitionId: 'fadeIn' });
      }
      
      
}
tensecondsLoader=()=>
{
    
 /**/var timeout = setInterval(() => {
    var proxyurl = jsonConfig.proxyUrl;
    var trimDomain= this.trimFunction(this.state.defaultOrgName);
    var lowerCaseDomain=trimDomain.toLowerCase();
    var createInstance=jsonConfig.blueleapChatLoginUrl+lowerCaseDomain;
    if(this.state.pannelVal==='Chat')
    {
    axios.get(proxyurl+createInstance)
    .then((response) => {
      
      if(response.status===200)
      {
        clearInterval(timeout);
        this.setState({
          blueleapChatLoginUrlStatus:true,
          updateAdmintxtstat:false,
          intStatus:false,
          transferMsgStatus:false,
          intEmailStatus:false,
          
        })
        this.getBlueleapchat(this.state.defaultOrgId);
      }
      else{
        this.setState({
          updateAdmintxtstat:true,
          intStatus:true,
          transferMsgStatus:true,
          blueleapChatLoginUrlStatus:false,
          intEmailStatus:true,
        })
      }
    }, (error) => {
      //console.log(error.response.status);
      if(error.response.status===404)
      {
        clearInterval(timeout);
        this.setState({
          updateAdmintxtstat:true,
          intStatus:true,
          transferMsgStatus:true,
          blueleapChatLoginUrlStatus:true,
          intEmailStatus:true,
        })
      }
      else{ 
        
        this.setState({
          updateAdmintxtstat:true,
          intStatus:true,
          transferMsgStatus:true,
          intEmailStatus:true,
          blueleapChatLoginUrlStatus:false,
        })
      }
      
    });
    }
    else{
      clearInterval(timeout);
    }

    
 }, 10000);
}
elasticsearchTimeout=()=>
{
  var timeout2 = setInterval(() => {
    if(this.state.pannelVal==='Elastic Search')
    {
      let data = {
        "domainName":this.state.defaultOrgName.toLowerCase(),
        "Action":"geturl"
        }
    
          axios.post(jsonConfig.elasticSearchUrl, data,{
            headers: {
              'X-API-KEY': jsonConfig.ApiKey,
            }
          })
          .then((response) => {
            console.log(response);
            if(response.data.statusCode===200)
            {
              if(response.data.Processing===false)
              {
                clearInterval(timeout2);
                this.setState({csvUploadStatus:true});
                this.getelasticSearchData(this.state.defaultOrgId,'');
                this.serachElasticSearch(this.state.defaultOrgId);
              }
              else{
                this.setState({csvUploadStatus:false})
              }
              
            }
            else{
              this.setState({
                csvUploadUrl:null,
                csvUploadStatus:true
              })
            }
            
          }, (error) => {
            console.log(error);
          });
    }
    else{
      clearInterval(timeout2);
    }
    
  }, 10000);
}
saBotTimeout=()=>{
  var timeout3 = setInterval(() => {
    if(this.state.pannelVal==='Sentiment Bot')
    {
      let data = {
        "action": "getbot",
        "botName": this.state.defaultOrgName+"SABot"
        }
        
          axios.post(jsonConfig.getbotApi, data,{
            headers: {
              'X-API-KEY': jsonConfig.ApiKey,
            }
          })
          .then((response) => {
            console.log("SA RESP",response.data.body.status);
            if(response.data.body.status===null)
            {
              clearInterval(timeout3);
              this.setState({
                SaBotProvisioningStatus:false,
              })
            }
            else{
              if(response.data.body.status==='READY')
              {
                  clearInterval(timeout3);
                  this.setState({
                    SaBotProvisioningStatus:false,
                    deallocateBotButton:true
                  })
                  this.getTicketDetails(this.state.defaultOrgId);
              }
              else{
                this.setState({SaBotProvisioningStatus:true})
              }
            }
                
            
          }, (error) => {
            console.log(error);
          });
    }
    else{
      clearInterval(timeout3);
    }
    
  }, 5000);

}
manualRefresh()
{
  this.getelasticSearchData(this.state.defaultOrgId,'');
  this.serachElasticSearch(this.state.defaultOrgId);
}
  createLoader()
    {
        return(
        <div className="overlayLoader" style={{display:this.state.overlay,opacity:this.state.changeOpacity}}>
          <img src={img_loader} className="loaderClass" alt="loader"/>
        </div>
        )
    }
getSetUp(orgNameParam)
{
  var setupTable = "setup";
  var docClient = new AWS.DynamoDB.DocumentClient();
    var setUPparams = {
      TableName : setupTable,
      FilterExpression : "organizationId = :orgName",
      ExpressionAttributeValues: {
          ":orgName": orgNameParam
      }
  };
  docClient.scan(setUPparams, (err, data)=> {
      if (err) {
          console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
      } else {
        if(data.Items.length>0)
        {
          
        this.setState({
          crmSetupArray:JSON.parse(data.Items[0].crmJson),
          aiSetupArr:JSON.parse(data.Items[0].aiServicesJson),
          channelSetupArr:JSON.parse(data.Items[0].channelsJson)
        })
        //----------FOR CRM-----------------------//
        var checkSetup=JSON.parse(data.Items[0].crmJson);
        var findNameinCrm=this.state.availableCrm;
        var filterdata=checkSetup.filter(r=>r.setup==='Complete');

        for(var j=0;j<filterdata.length;j++)
        {
          var Legalindex = findNameinCrm.map(function(o) { return o.name; }).indexOf(filterdata[j].name);
          findNameinCrm[Legalindex]["addClass"]="progressFull";
        }
        this.setState({availableCrm:findNameinCrm})
        //-----------------FOR AI----------------//
        var checkAiSetup=JSON.parse(data.Items[0].aiServicesJson)
        var findNameinAi=this.state.availableServices;
        var filterAi=checkAiSetup.filter(r=>r.setup==='Complete');

        for(var k=0;k<filterAi.length;k++)
        {
          var LegalindexAi = findNameinAi.map(function(o) { return o.name; }).indexOf(filterAi[k].name);
          findNameinAi[LegalindexAi]["addClass"]="progressFull";
        }
        this.setState({availableServices:findNameinAi})

        //-----------------FOR CHANNELS----------------//
        var checkChannelSetup=JSON.parse(data.Items[0].channelsJson)
        var findNameinChannels=this.state.availableIntegrations;
        var filterChannels=checkChannelSetup.filter(r=>r.setup==='Complete');

        for(var m=0;m<filterChannels.length;m++)
        {
          var LegalindexChannel = findNameinChannels.map(function(o) { return o.name; }).indexOf(filterChannels[m].name);
          findNameinChannels[LegalindexChannel]["addClass"]="progressFull";
        }
        this.setState({availableIntegrations:findNameinChannels})
      }
        else{
          this.setState({
            crmSetupArray:[],
            aiSetupArr:[],
            channelSetupArr:[]
          })
        }
      }
  });
}

sortByProperty(property){  
  return function(a,b){  
    var x=new Date(a[property]);
    var y=new Date(b[property]);
     if(x > y)  
        return -1;  
     else if(x < y)  
        return 1;  
 
     return 0;  
  }  
}



getInteractionbyId(interactionId){

  console.log("interactionId",interactionId)
  var interactionBoardTable = "interactions";
  var docClient = new AWS.DynamoDB.DocumentClient();
    var params = {
      TableName : interactionBoardTable,
      Key: {
          "interactionId": interactionId
      }
  };
  docClient.get(params, (err, data)=> {
      if (err) {

        console.log(err)
      } else {
        console.log(data)
        // JSON.parse(atob(data.Items[0].json)).filter((x)=>x.name=="Sms")[0]["prefix"]
        console.log(JSON.parse(atob(data.Item.json)).filter((x)=>x.name=="Sms")[0]["prefix"])

        var prefix = JSON.parse(atob(data.Item.json)).filter((x)=>x.name=="Sms")[0]["prefix"]
        var suffix = JSON.parse(atob(data.Item.json)).filter((x)=>x.name=="Sms")[0]["suffix"]
        var communicationType = JSON.parse(atob(data.Item.json)).filter((x)=>x.name=="Sms")[0]["communicationType"]

        this.setState({

          messagePrefix: prefix ? prefix : '',
          messageSuffix:suffix ? suffix : '',
          communicationType:communicationType?communicationType:'Outbound_Only'

        })


      }
  });



  
}




getBoardData(orgIdParam)
  {
    this.setState({
      isLoading:true,
      overlay:'block',
      changeOpacity:1
    })
    this.awsConfig();
    var interactionBoardTable = "interactions";
    var docClient = new AWS.DynamoDB.DocumentClient();
      var params = {
        TableName : interactionBoardTable,
        FilterExpression : "organizationId = :val1",
        ExpressionAttributeValues: {
            ":val1": orgIdParam
        }
    };
    docClient.scan(params, (err, data)=> {
        if (err) {
            console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
            this.setState({
              isLoading:true,
              overlay:'block',
              changeOpacity:1
            })
        } else {
          if(data.Items.length>0)
          {
              var options={day: '2-digit',year: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit'}
              data.Items.sort(this.sortByProperty("updatedAt"));

              try{
                var prefix = JSON.parse(atob(data.Items[0].json)).filter((x)=>x.name=="Sms")[0]["prefix"]
                var suffix = JSON.parse(atob(data.Items[0].json)).filter((x)=>x.name=="Sms")[0]["suffix"]
                var communicationType = JSON.parse(atob(data.Items[0].json)).filter((x)=>x.name=="Sms")[0]["communicationType"]
              }
              catch(err){
                var prefix=null
                var suffix=null
                var communicationType=null
              }


              this.setState({
                boardId:data.Items[0].interactionId,
                disabledFilterStatus:false,
                boardList:data.Items,
                activeInteractions:JSON.parse(atob(data.Items[0].json)),
                messagePrefix: prefix ? prefix : '',
                messageSuffix:suffix ? suffix : '',
                communicationType:communicationType?communicationType:'Outbound_Only',
                activeList:0,
                saveButtonStatus:true,
                createButtonStatus:false,
                boardName:data.Items[0].interactionName,
                status:data.Items[0].boardStatus,
                createDate:new Date(data.Items[0].createdAt).toLocaleTimeString([],options),
                updateDate:new Date(data.Items[0].updatedAt).toLocaleTimeString([],options),
                createdByUser :data.Items[0].createdBy,
                updatedByUser:data.Items[0].updatedBy,
                boardNameStatus:true,
                activeBackground:'workflow_illustration_v5',
                boardNameReadonly:false,
              })
              this.dateDifference(data.Items[0].updatedAt)
          }
          else{
            this.setState({
              disabledFilterStatus:true,
              boardList:[],
              activeInteractions:[],
              activeList:-1
            })
          }
            this.setState({
              isLoading:false,
              overlay:'none',
              changeOpacity:0
            })
        }
    });
  }
  getBoardDataAfterSave(orgIdParam)
  {
    this.setState({
      isLoading:true,
      overlay:'block',
      changeOpacity:1
    })
    this.awsConfig();
    var interactionBoardTable = "interactions";
    var docClient = new AWS.DynamoDB.DocumentClient();
      var params = {
        TableName : interactionBoardTable,
        FilterExpression : "organizationId = :val1",
        ExpressionAttributeValues: {
            ":val1": orgIdParam
        }
    };
    docClient.scan(params, (err, data)=> {
        if (err) {
            console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
            this.setState({
              isLoading:true,
              overlay:'block',
              changeOpacity:1
            })
        } else {
          if(data.Items.length>0)
          {
              data.Items.sort(this.sortByProperty("updatedAt"));
              this.setState({
                boardList:data.Items,
                boardNameReadonly:false,
              })
          }
          else{
            this.setState({boardList:[]})
          }
            this.setState({
              isLoading:false,
              overlay:'none',
              changeOpacity:0
            })
        }
    });
  }
//--------GET ACTIVE INTERACTION DATA-------//
getActiveInteraction(interactionid,interactionJson,interactionName,boardStatus,createdAt,updatedAt,createdBy,updatedBy,key)
{
  var options={day: '2-digit',year: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit'}
  this.getFaq(interactionid);
  this.dateDifference(updatedAt);
	this.setState({
		activeInteractions:JSON.parse(atob(interactionJson)),
		boardName:interactionName,
		status:boardStatus,
		createDate:new Date(createdAt).toLocaleTimeString([], options),
		updateDate:new Date(updatedAt).toLocaleTimeString([], options),
		activeList:key,
		activeBackground:'workflow_illustration_v5',
    boardId:interactionid,
    boardNameStatus:true,
    createButtonStatus:false,
    saveButtonStatus:true,
    updateButtonStatus:false,
    createdByUser:createdBy,
    updatedByUser:updatedBy,
    boardNameReadonly:false,
	})
	
}

resetPanel() {
  this.setState({
    pannelVal:'',
    activeIcon:-1,
    ServiceIcon:-1,
    CrmIcon:-1,
    activeIndex:-1,
    activeBackground:'workflow_illustration_v5',
  });
}

//------------MESSENGER----------//
getMessengerPages(orgIdParam)
  {
    var d = new Date();
    var n = d.getTime();
    
    var a=window.setTimeout(() => {
      this.setState({
        overlay:'block',
        changeOpacity:1
      });      
    }, 2000);
    
    //----GET ALL MESSENGER PAGES BY ORGANIZATION---//
    var messengerTable = "messenger";
    var docClient = new AWS.DynamoDB.DocumentClient();
      var params = {
        TableName : messengerTable,
        FilterExpression : "organizationId = :val1",
        ExpressionAttributeValues: {
            ":val1": orgIdParam
        }
    };
    
    docClient.scan(params, (err, data)=> {
        if (err) {
            console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
        } else {
          var d1 = new Date();
          var n1 = d1.getTime();
          var td=n1-n;
          if(td<2000)
          {
            clearTimeout(a);
            /*window.setTimeout(() => {
              this.setState({
                overlay:'none',
                changeOpacity:0
              });      
            }, 4000-td);*/
            this.setState({
              overlay:'none',
              changeOpacity:0
            });  
          }
          else{
            window.setTimeout(() => {
              this.setState({
                overlay:'none',
                changeOpacity:0
              });      
            }, td);
            
          }
          
          //window.clearTimeout();
          this.setState({messengerPages:data.Items});
        }
    });
    
  }
  checkBeforeFblogin()
  {
    this.setState({pageLoadedforMessenger:true})
  }
  responseFacebook = async(response) => {
    
    var docClient = new AWS.DynamoDB.DocumentClient();
    var messengerTable = "messenger";
    //----------------------------//
    
    if(response.name) {

      //var pageId = "100342108111458";
      //var apiURL = "http://localhost:3000/";
      this.setState({
        userAccessToken: response.accessToken,
        FbuserId:response.id
      });

      //---------GET PAGE ID--------------//
      var pageDetails = await this.getPageDetails();
      var pData=pageDetails.data;
      
      for(let m=0;m<pData.length;m++)
      {
        var navexpToken=await this.getNeverExpToken(pData[m].access_token);
        var messengerParams = {
          TableName:messengerTable,
          Item:{
            pageId: pData[m].id,
            pagename:pData[m].name,
            appSecret: jsonConfig.appSecretKey,
            verifyToken: jsonConfig.verifyToken,
            pageAccessToken: navexpToken,
            organizationName: this.state.defaultOrgName,
            organizationId: this.state.defaultOrgId,
            organizationRole: this.state.defaultRoleName,
            cognitoId:this.state.defaultCognitoId
          }
        };
        docClient.put(messengerParams, (err, data) => {
          if (err) {
            console.error("Unable to add item messenger table. Error JSON:", JSON.stringify(err, null, 2));
            //NotificationManager.error('Failed to add page','Error');
          } else {
            console.log("Added messenger with organization item:", JSON.stringify(data, null, 2));
            //NotificationManager.success('Page successfully added','Success');
          }
        });
      }
      NotificationManager.success('Page successfully subscribed','Success');
      var findMsngr=this.state.channelSetupArr;
      var Legalindex = findMsngr.map(function(o) { return o.name; }).indexOf("Messenger");               
      findMsngr[Legalindex]["setup"]="Complete";/**/
      this.updateSetup(findMsngr,this.state.aiSetupArr,this.state.crmSetupArray);
      this.getMessengerPages(this.state.defaultOrgId);
      this.setState({pageLoadedforMessenger:false});
       
    } else {
      console.log("Login Cancelled");
      NotificationManager.error('Page subscription failed','Error');
      this.setState({pageLoadedforMessenger:false});
    }
  }

  async getNeverExpToken(accesstoken)
  {
    try {
      var res = await axios.get('https://graph.facebook.com/v8.0/oauth/access_token?grant_type=fb_exchange_token&client_id='+jsonConfig.facebookLoginAppId+'&client_secret='+jsonConfig.appSecretKey+'&fb_exchange_token='+accesstoken);
      console.log("EXP",res);
    } catch (error) {
      console.log("NEVER EXPIRED ERR",error)
    }
    return res.data.access_token;
  }
  async getPageDetails()
  {
    try {
      var res = await axios({
        method: 'get',
        url:'https://graph.facebook.com/v8.0/'+this.state.FbuserId+'/accounts?access_token='+this.state.userAccessToken
      });
      console.log("PAGE DETAILS",res);
    } catch (error) {
      console.error(error);
    }
    return res.data;
  
  }
  PageSubscribe()
  {
    try {
      var res = axios({
        method: 'post',
        url:'https://graph.facebook.com/v8.0/'+this.state.pageId+'/subscribed_apps?subscribed_fields=messages&access_token='+this.state.pageAccessToken
      });
      console.log("SUBSCRIBE",res);
    } catch (error) {
      console.error(error);
    }
    return res.data;
  
  }
  pageIdSelect = event => {
    let index = event.nativeEvent.target.selectedIndex;
    this.setState({ 
      addMessengerId: event.target.value,
      messengerPageName:event.nativeEvent.target[index].text
    });
  }
  deletePageEntry(pageID,pageIndex)
  {
    console.log("COG ID",this.state.defaultCognitoId);
    var messengerTable = "messenger";
    var docClient = new AWS.DynamoDB.DocumentClient();
    var delParams = {
      TableName:messengerTable,
      Key:{
          "pageId": pageID,
      },
      ConditionExpression :"cognitoId = :val",
      ExpressionAttributeValues: {
          ":val": this.state.defaultCognitoId
      }
    };
    console.log(delParams);
    docClient.delete(delParams,(err, data)=> {
      if (err) {
          console.error("Unable to delete item. Error JSON:", JSON.stringify(err, null, 2));
      } else {
        if(this.state.messengerPages.length===1)
        {
          var findMsngr=this.state.channelSetupArr;
          var Legalindex = findMsngr.map(function(o) { return o.name; }).indexOf("Messenger");               
          findMsngr[Legalindex]["setup"]="inComplete";/**/
          var tempChannels=this.state.availableIntegrations;
          tempChannels[0]["addClass"]="";
        }
          
          console.log("DeleteItem succeeded:", JSON.stringify(data, null, 2));
          NotificationManager.success('Pagess successfully deleted','Success');
          this.updateSetup(findMsngr,this.state.aiSetupArr,this.state.crmSetupArray);
          this.getMessengerPages(this.state.defaultOrgId);
          console.log("LENGTH",this.state.messengerPages.length);
      }
    });
  }
  makeEntrypointForMessengerPages()
  { 
    var entrypointTable = "entryPoint";
    var docClient = new AWS.DynamoDB.DocumentClient();
    var opt=this.state.addMessengerId;
    var optLabel=this.state.messengerPageName
    
    var blankArr=[];
    if(opt==='' || opt==="0" || opt===0)
    {
      NotificationManager.error('Please select facebook pages','Error');
    }
    else{
        var insertParams = {
        TableName:entrypointTable,
        Item:{
          entryPointIdentifier:opt,
          interactionId:this.state.boardId,
          EntryPointIdentifierService: 'Messenger',
          EntryPointIdentifierValue: optLabel,
          pageId:opt
        }
      };
      docClient.put(insertParams, (err, data) => {
        if (err) {
          console.error("Unable to add item Client chat table. Error JSON:", JSON.stringify(err, null, 2));
        } else {
          console.log("Indentifier details inserted");
        }
      });
    blankArr.push(opt);
    
    var findMessengerJson=this.state.activeInteractions;
    var Legalindex = findMessengerJson.map(function(o) { return o.name; }).indexOf("Messenger");
    findMessengerJson[Legalindex]["key"]=[];
    findMessengerJson[Legalindex]["key"].push(opt);

    var interactionTable='interactions';
    var params = {
      TableName:interactionTable,
      Key:{
        "interactionId": this.state.boardId
      },
      "UpdateExpression": "set json = :boardData",
      "ExpressionAttributeValues": {
        ":boardData":btoa(JSON.stringify(findMessengerJson)),
      }
    };
    docClient.update(params, (err, data) => {
      if (err) {
        console.error("Unable to update interaction. Error JSON:", JSON.stringify(err, null, 2));
      } else {
        console.log("Update interaction table");
        NotificationManager.success('Facebook page succeessfully added','Success');
        this.getEntryPointMessengerData();
      }
    });
    }
  }
  deleteMessengerPageFromAddedpage(entryPointId,index,interactionid)
  {
    var interactionTable='interactions';
    var entrypointTable = "entryPoint";
    var docClient = new AWS.DynamoDB.DocumentClient();
    var delParams = {
      TableName:entrypointTable,
      Key:{
          "entryPointIdentifier": entryPointId,
      },
    };

    docClient.delete(delParams, (err, data)=> {
      if (err) {
          console.error("Unable to delete item. Error JSON:", JSON.stringify(err, null, 2));
      } else {
          console.log("Delete page succeeded:", JSON.stringify(data, null, 2));

          var findMessengerJson=this.state.activeInteractions;
          var Legalindex = findMessengerJson.map(function(o) { return o.name; }).indexOf("Messenger");
          var filterdArray=findMessengerJson[Legalindex]["key"];
          
          filterdArray.splice(filterdArray.findIndex(item => item === entryPointId), 1)

          var interactionParams = {
            TableName:interactionTable,
            Key:{
              "interactionId": this.state.boardId
            },
            "UpdateExpression": "set json = :boardData",
            "ExpressionAttributeValues": {
              ":boardData":btoa(JSON.stringify(findMessengerJson)),
            }
          };
          docClient.update(interactionParams, (err, data) => {
            if (err) {
              console.error("Unable to update interaction. Error JSON:", JSON.stringify(err, null, 2));
            } else {
              console.log("Update interaction table");
              NotificationManager.success('Page successfully Removed','Success');
              this.setState({activeInteractions:findMessengerJson})
              this.getEntryPointMessengerData();
            }
          });
      }
    });
  }
  getEntryPointMessengerData()
  {
    var entrypointTable = "entryPoint";
    var docClient = new AWS.DynamoDB.DocumentClient();
      var params = {
        TableName : entrypointTable,
        FilterExpression : "EntryPointIdentifierService = :serviceName AND interactionId = :interactionid",
        ExpressionAttributeValues: {
            ":serviceName": 'Messenger',
            ":interactionid": this.state.boardId,
        }
    };
    
    docClient.scan(params, (err, data)=> {
        if (err) {
            console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
        } else {    
          
          if(data.Items.length>0)
          {
            this.setState({entryPointMessenger:data.Items})
          }
        else{
            this.setState({entryPointMessenger:[]})
          }
        }
    });
  
  }
  //----------CLIENT CHAT----------//
  getClientChatData(orgIdParam)
  {
    var clientchatTable = "clientChat";
    var docClient = new AWS.DynamoDB.DocumentClient();
      var params = {
        TableName : clientchatTable,
        FilterExpression : "organizationId = :val1",
        ExpressionAttributeValues: {
            ":val1": orgIdParam
        }
    };
    
    docClient.scan(params, (err, data)=> {
        if (err) {
            console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
        } else {
          var websiteListIcon=[];
          
          if(data.Items.length>0)
          {
            for (var i = 0; i < data.Items.length; i++) 
            {
              websiteListIcon[i] = {label: data.Items[i].website, value: data.Items[i].clientchatid};
            }
              this.setState({
                websiteList:data.Items,
                websiteListIcon:websiteListIcon,		
              })
              
          }
          else{
            this.setState({
              websiteList:[],
              websiteListIcon:websiteListIcon,		
            })
          }
        }
    });
  }
  getClientChatSettings(orgNameParam)
  {
    var d = new Date();
    var n = d.getTime();
    
    var a=window.setTimeout(() => {
      this.setState({
        overlay:'block',
        changeOpacity:1
      });      
    }, 2000);

    var clientchatSettingsTable = "clientChatSettings";
    var docClient = new AWS.DynamoDB.DocumentClient();
      var params = {
        TableName : clientchatSettingsTable,
        FilterExpression : "organizationId = :orgName",
        ExpressionAttributeValues: {
            ":orgName": orgNameParam
        }
    };
    
    docClient.scan(params, (err, data)=> {
        if (err) {
            console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
        } else {
         
        var d1 = new Date();
        var n1 = d1.getTime();
        var td=n1-n;
        if(td<2000)
              {
                clearTimeout(a);
                /*window.setTimeout(() => {
                  this.setState({
                    overlay:'none',
                    changeOpacity:0
                  });      
                }, 4000-td);*/
                this.setState({
                  overlay:'none',
                  changeOpacity:0
                });  
              }
              else{
                window.setTimeout(() => {
                  this.setState({
                    overlay:'none',
                    changeOpacity:0
                  });      
                }, td);
                
              }
         
          if(data.Items.length>0)
          {
            if(data.Items[0].clientChatIcon!=='' || data.Items[0].clientChatIcon!=='')
            {
              this.setState({chatIcon:data.Items[0].clientChatIcon})
            }
            else{
              this.setState({chatIcon:jsonConfig.s3BucketUrl+'no-preview-available.png'})
            }
            this.setState({
              varBotName:data.Items[0].botName,
              varGenericErrorMessage:data.Items[0].genericErrorMessage,
              chatText:data.Items[0].clientChatText,
              clientChatTimeout:data.Items[0].clientChatTimeout,
              clientChatTimeoutMessage:data.Items[0].timeoutMessage,
              clientChatGreeting:data.Items[0].greeting,
              clientChatColor:data.Items[0].chatColorCode,
              endClientChat:data.Items[0].closeChatMessage
            })
          }
        }
    });
  }
  websiteHandler = event => {
    this.setState({
      websiteName: event.target.value
    });
  }
  async getIconfile(event) {
    
    
    this.setState({iconFile: event.target.files[0]});
    Amplify.configure({
      Storage: {
          AWSS3: {
              bucket: jsonConfig.clientchatBucket, //REQUIRED -  Amazon S3 bucket
          }
      }
    });
    
    const file = event.target.files[0];
    
    let uploadResult = await Storage.put(file.name, file, {contentType: file.type});
    let getImgS3=await Storage.get(uploadResult.key);
    var picSplit=getImgS3.split("?");
    console.log(picSplit);
    this.setState({
      chatIcon:picSplit[0]
    });
  }
  chattextHandler = event => {
    this.setState({
      chatText: event.target.value
    });
  }
  endClientChatHandler = event => {
    this.setState({
      endClientChat: event.target.value
    });
  }
  sendwebsite()
  {
    if(this.state.websiteName==='' || this.state.websiteName===null || this.state.websiteName===undefined)
    {
      NotificationManager.error('Please enter website name','Error');
    }
    else{
    var cognitoID = this.state.defaultCognitoId;
    var clientchatTable = "clientChat";
    var chatid=Math.floor((Math.random()*1000000000000000000)+1);
    var docClient = new AWS.DynamoDB.DocumentClient();
    var insertParams = {
      TableName:clientchatTable,
      Item:{
        clientChatId:chatid.toString(),
        cognitoid: cognitoID,
        website: this.state.websiteName,
        organizationName: this.state.defaultOrgName,
        organizationId: this.state.defaultOrgId,
        organizationRole:this.state.defaultRoleName
      }
    };
    docClient.put(insertParams, (err, data) => {
      if (err) {
        console.error("Unable to add item Client chat table. Error JSON:", JSON.stringify(err, null, 2));
      } else {
        NotificationManager.success('Website successfully added','Success');
        this.setState({
          websiteName:''
        })
        
        this.getClientChatData(this.state.defaultOrgId);
      }
    });
  }
  }
  deleteWebEntry(clientChatId)
  {
    var clientchatTable = "clientChat";
    var docClient = new AWS.DynamoDB.DocumentClient();
    var delParams = {
      TableName:clientchatTable,
      Key:{
          "clientChatId": clientChatId,
      },
    };
    docClient.delete(delParams,(err, data)=> {
      if (err) {
          console.error("Unable to delete item. Error JSON:", JSON.stringify(err, null, 2));
          NotificationManager.success('Failed to delete website','Success');
      } else {
          console.log("DeleteItem succeeded:", JSON.stringify(data, null, 2));
          NotificationManager.success('Website successfully deleted','Success');
          this.getClientChatData(this.state.defaultOrgId);
      }
    });
    
  }
  async saveChatSettings()
  {
    var tempTimeout='';
    var tempTimeoutMsg='';
    var tempEndClientChat='';
    if(this.state.endClientChat===undefined)
    {
      tempEndClientChat='';
    }
    else{
      tempEndClientChat=this.state.endClientChat;
    }
    if(this.state.clientChatTimeout===undefined)
    {
      tempTimeout='1';
    }
    else{
      tempTimeout=this.state.clientChatTimeout;
    }
    if(this.state.clientChatTimeoutMessage===undefined)
    {
      tempTimeoutMsg='';
    }
    else{
      tempTimeoutMsg=this.state.clientChatTimeoutMessage;
    }
    if(this.state.clientChatColor===undefined || this.state.clientChatColor===null || this.state.clientChatColor==='')
    {
      var tempChatColor='#008CFF';
    }
    else{
      var tempChatColor=this.state.clientChatColor;
    }
    if(this.state.chatText==='' || this.state.varGenericErrorMessage==='' || this.state.varBotName==='' || this.state.clientChatGreeting==='' || tempEndClientChat==='')
    {
      NotificationManager.error('Please fill up all the field','Error'); 
    }
    else{
      var clientchatSettingsTable = "clientChatSettings";
      var docClient = new AWS.DynamoDB.DocumentClient();
      const file = this.state.iconFile;
    if(file==='' || file===null || file==='null' || file===undefined)
    {
        
        if(this.state.chatIcon===jsonConfig.s3BucketUrl+'no-preview-available.png')
        {
          var changeFileName='';
        }
        else{
          var changeFileName=this.state.chatIcon;
        }
    }
    else{
      //-----UPLOAD ICON------------//
      AWS.config.update({
        region: jsonConfig.awsConfigRegion, // Put your aws region here
        accessKeyId: jsonConfig.accessKeyId,
        secretAccessKey: jsonConfig.secretAccessKey
      })
      Amplify.configure({
        Storage: {
            AWSS3: {
                bucket: jsonConfig.clientchatBucket, //REQUIRED -  Amazon S3 bucket
            }
        }
      });
      
      
      let uploadResult = await Storage.put(file.name, file, {contentType: file.type});
      let getImgS3=await Storage.get(uploadResult.key);
      var picSplit=getImgS3.split("?");
      console.log(picSplit);
      this.setState({
        chatIconUrl:picSplit[0]
      });

        changeFileName=this.state.chatIconUrl;

        
        
    }
    var updtChatSettingsParams = {
      TableName:clientchatSettingsTable,
      Key:{
        "organizationId": this.state.defaultOrgId,
      },
      "UpdateExpression": "set clientChatIcon = :val1,clientChatText = :val2,genericErrorMessage = :val3,botName = :val4,clientChatTimeout = :val5,timeoutMessage = :val6,greeting = :val7,chatColorCode = :val8 ,closeChatMessage = :val9",
      "ExpressionAttributeValues": {
        ":val1": changeFileName,
        ":val2":this.state.chatText,
        ":val3":this.state.varGenericErrorMessage,
        ":val4":this.state.varBotName,
        ":val5":tempTimeout,
        ":val6":tempTimeoutMsg,
        ":val7":this.state.clientChatGreeting,
        ":val8":tempChatColor,
        ":val9":tempEndClientChat
      },
    };
    console.log(updtChatSettingsParams);
      docClient.update(updtChatSettingsParams,(err, data)=> {
        if (err) {
          console.error("Unable to update item. Error JSON:", JSON.stringify(err, null, 2));
          NotificationManager.error('Chat settings failed','Error'); 
        } else {
          console.log("UpdateItem succeeded:", JSON.stringify(data, null, 2));
          var findCC=this.state.channelSetupArr;
          var Legalindex = findCC.map(function(o) { return o.name; }).indexOf("Client Chat");               
          findCC[Legalindex]["setup"]="Complete";/**/
          this.updateSetup(findCC,this.state.aiSetupArr,this.state.crmSetupArray);
          NotificationManager.success('Chat settings successfully saved','Success');
          this.getClientChatSettings(this.state.defaultOrgId)
        }
      });
    }
    
  }
  
  websiteIdSelect = event => {
    let index = event.nativeEvent.target.selectedIndex;
    this.setState({ 
      addOption: event.target.value,
      optionLabel:event.nativeEvent.target[index].text
    });
  }
  getEntryPointClientChatData()
  {
    var entrypointTable = "entryPoint";
    var clientchatTable = "clientChat";
    var docClient = new AWS.DynamoDB.DocumentClient();
      var params = {
        TableName : entrypointTable,
        FilterExpression : "EntryPointIdentifierService = :serviceName AND interactionId = :interactionid",
        ExpressionAttributeValues: {
            ":serviceName": 'Client Chat',
            ":interactionid": this.state.boardId,
        }
    };
    
    docClient.scan(params, (err, data)=> {
        if (err) {
            console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
        } else {
          
          if(data.Items.length>0)
          {
          var getClienchatparams = {
            TableName : clientchatTable,
            FilterExpression : "clientChatId= :chatID",
            ExpressionAttributeValues: {
                ":chatID": data.Items[0].entryPointIdentifier,
            }
        };
        docClient.scan(getClienchatparams, (err, data)=> {
          if (err) {
              console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
          } else {
            /*this.setState({
              varBotName:data.Items[0].botName,
	            varGenericErrorMessage:data.Items[0].genericErrorMessage,
            })*/
          }
          });
          
          this.setState({entryPointWebsite:data.Items})
        }
        else{
          this.setState({entryPointWebsite:[]})
        }
        }
    });
  
  }
  makeEntrypoint()
  {
    var entrypointTable = "entryPoint";
    var docClient = new AWS.DynamoDB.DocumentClient();
    var opt=this.state.addOption;
    var optLabel=this.state.optionLabel
    
    var blankArr=[];
    if(opt==='' || opt==="0" || opt===0)
    {
      NotificationManager.error('Please select website','Error');
    }
    else{
    /*for(var j=0;j<opt.length;j++)
    {*/
      /**/var insertParams = {
        TableName:entrypointTable,
        Item:{
          entryPointIdentifier:opt,
          interactionId:this.state.boardId,
          EntryPointIdentifierService: 'Client Chat',
          EntryPointIdentifierValue: optLabel,
        }
      };
      docClient.put(insertParams, (err, data) => {
        if (err) {
          console.error("Unable to add item Client chat table. Error JSON:", JSON.stringify(err, null, 2));
        } else {
          console.log("Indentifier details inserted");
        }
      });
      blankArr.push(opt);
    /*}*/
    //var findClientChatJson=JSON.parse(atob(localStorage.getItem('sendBoardData')));
    var findClientChatJson=this.state.activeInteractions;
    
    var Legalindex = findClientChatJson.map(function(o) { return o.name; }).indexOf("Client Chat");
    
    findClientChatJson[Legalindex]["key"]=[];
    findClientChatJson[Legalindex]["key"].push(opt);
    //findClientChatJson[Legalindex].key.push(opt);
    //findClientChatJson[Legalindex]["key"] = blankArr;
    

    /**/var interactionTable='interactions';
    var params = {
      TableName:interactionTable,
      Key:{
        "interactionId": this.state.boardId
      },
      "UpdateExpression": "set json = :boardData",
      "ExpressionAttributeValues": {
        ":boardData":btoa(JSON.stringify(findClientChatJson)),
      }
    };
    docClient.update(params, (err, data) => {
      if (err) {
        console.error("Unable to update interaction. Error JSON:", JSON.stringify(err, null, 2));
      } else {
        console.log("Update interaction table");
        NotificationManager.success('Website successfully added','Success');
        this.getEntryPointClientChatData();
      }
    });
    }
  }
  deleteWebsite(entryPointId,keyIndex)
  {
    var interactionTable='interactions';
    var entrypointTable = "entryPoint";
    var docClient = new AWS.DynamoDB.DocumentClient();
    var delParams = {
      TableName:entrypointTable,
      Key:{
          "entryPointIdentifier": entryPointId,
      },
    };
    docClient.delete(delParams, (err, data)=> {
      if (err) {
          console.error("Unable to delete item. Error JSON:", JSON.stringify(err, null, 2));
      } else {
          console.log("DeleteItem succeeded:", JSON.stringify(data, null, 2));

          var findClientChatJson=this.state.activeInteractions;
          var Legalindex = findClientChatJson.map(function(o) { return o.name; }).indexOf("Client Chat");
          var filterdArray=findClientChatJson[Legalindex]["key"];
          
          filterdArray.splice(filterdArray.findIndex(item => item === entryPointId), 1)

          var interactionParams = {
            TableName:interactionTable,
            Key:{
              "interactionId": this.state.boardId
            },
            "UpdateExpression": "set json = :boardData",
            "ExpressionAttributeValues": {
              ":boardData":btoa(JSON.stringify(findClientChatJson)),
            }
          };
          docClient.update(interactionParams, (err, data) => {
            if (err) {
              console.error("Unable to update interaction. Error JSON:", JSON.stringify(err, null, 2));
            } else {
              console.log("Update interaction table");
              NotificationManager.success('Website successfully Removed','Success');
              this.getEntryPointClientChatData();
            }
          });
      }
    });
    
    
  }
//-------------LIVE CHAT--------------//
getLiveChatData(orgIdParam)
{
  var livechatTable = "liveChat";
    var docClient = new AWS.DynamoDB.DocumentClient();
      var params = {
        TableName : livechatTable,
        FilterExpression : "organizationId = :val1",
        ExpressionAttributeValues: {
            ":val1": orgIdParam
        }
    };
    
    docClient.scan(params, (err, data)=> {
        if (err) {
            console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
        } else {
          var websiteListIcon=[];
          
          if(data.Items.length>0)
          {
            for (var i = 0; i < data.Items.length; i++) 
            {
              websiteListIcon[i] = {label: data.Items[i].website, value: data.Items[i].clientchatid};
            }
              this.setState({
                websiteLiveList:data.Items,
                websiteListIcon:websiteListIcon,		
              })
              
          }
          else{
            this.setState({
              websiteLiveList:[],
              websiteListIcon:websiteListIcon,		
            })
          }
        }
    });
}
getLiveChatSettings(orgNameParam)
  {
    var d = new Date();
    var n = d.getTime();
    
    var a=window.setTimeout(() => {
      this.setState({
        overlay:'block',
        changeOpacity:1
      });      
    }, 2000);

    var livechatSettingsTable = "liveChatSettings";
    var docClient = new AWS.DynamoDB.DocumentClient();
      var params = {
        TableName : livechatSettingsTable,
        FilterExpression : "organizationId = :orgName",
        ExpressionAttributeValues: {
            ":orgName": orgNameParam
        }
    };
    
    docClient.scan(params, (err, data)=> {
        if (err) {
            console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
        } else {
         
        var d1 = new Date();
        var n1 = d1.getTime();
        var td=n1-n;
        if(td<2000)
              {
                clearTimeout(a);
                /*window.setTimeout(() => {
                  this.setState({
                    overlay:'none',
                    changeOpacity:0
                  });      
                }, 4000-td);*/
                this.setState({
                  overlay:'none',
                  changeOpacity:0
                });  
              }
              else{
                window.setTimeout(() => {
                  this.setState({
                    overlay:'none',
                    changeOpacity:0
                  });      
                }, td);
                
              }
         
          if(data.Items.length>0)
          {
            if(data.Items[0].liveChatIcon==='' || data.Items[0].liveChatIcon===null)
            {
              this.setState({livechatIcon:jsonConfig.s3BucketUrl+'no-preview-available.png'})
            }
            else{
              this.setState({livechatIcon:data.Items[0].liveChatIcon})
            }
            this.setState({
              
              livevarBotName:data.Items[0].liveChatBotName,
              livevarGenericErrorMessage:data.Items[0].liveChatgenericErrorMessage,
              livechatText:data.Items[0].liveChatText,
              liveChatTimeout:data.Items[0].livechatTimeout,
              liveChatTimeoutMessage:data.Items[0].timeoutMessage,
              liveChatGreeting:data.Items[0].greeting,
              liveChatColor:data.Items[0].chatColorCode,
              endLiveChat:data.Items[0].closeChatMessage
            })
          }
        }
    });
  }
  getEntryPointLiveChatData()
  {
    var entrypointTable = "entryPoint";
    var livechatTable = "liveChat";
    var docClient = new AWS.DynamoDB.DocumentClient();
      var params = {
        TableName : entrypointTable,
        FilterExpression : "EntryPointIdentifierService = :serviceName AND interactionId = :interactionid",
        ExpressionAttributeValues: {
            ":serviceName": 'Live Chat',
            ":interactionid": this.state.boardId,
        }
    };
    
    docClient.scan(params, (err, data)=> {
        if (err) {
            console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
        } else {
          
          if(data.Items.length>0)
          {
          var getLiveChatparams = {
            TableName : livechatTable,
            FilterExpression : "liveChatId= :chatID",
            ExpressionAttributeValues: {
                ":chatID": data.Items[0].entryPointIdentifier,
            }
        };
        docClient.scan(getLiveChatparams, (err, data)=> {
          if (err) {
              console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
          } else {
            /*this.setState({
              varBotName:data.Items[0].botName,
	            varGenericErrorMessage:data.Items[0].genericErrorMessage,
            })*/
          }
          });
          
          this.setState({entryPointLiveChatWebsite:data.Items})
        }
        else{
          this.setState({entryPointLiveChatWebsite:[]})
        }
        }
    });
  
  }
websiteLiveHandler = event => {
  this.setState({
    websiteLiveName: event.target.value
  });
}
livechattextHandler = event => {
  this.setState({
    livechatText: event.target.value
  });
}
endLiveChatHandler = event => {
  this.setState({
    endLiveChat: event.target.value
  });
}
livevarBotNameHandler = event => {
  this.setState({
    livevarBotName: event.target.value
  });
}
liveChatGreetingHandler = event => {
  this.setState({
    liveChatGreeting: event.target.value
  });
}
livevarGenericErrorMessageHandler = event => {
  this.setState({
    livevarGenericErrorMessage: event.target.value
  });
}
liveChatTimeoutHandler = event => {
  this.setState({
    liveChatTimeout: event.target.value
  });
}
liveChatTimeoutMessageHandler = event => {
  this.setState({
    liveChatTimeoutMessage: event.target.value
  });
}

liveChatColorHandler = event => {
  this.setState({
    liveChatColor: event.target.value
  });
}

liveChatWebsiteIdSelect = event => {
  let index = event.nativeEvent.target.selectedIndex;
  this.setState({ 
    addLiveChatWebsiteOption: event.target.value,
    LiveChatWebsiteOptionLabel:event.nativeEvent.target[index].text
  });
}
sendLivewebsite()
{
  if(this.state.websiteLiveName==='' || this.state.websiteLiveName===null || this.state.websiteLiveName===undefined)
  {
    NotificationManager.error('Please enter website name','Error');
  }
  else{
  var cognitoID = this.state.defaultCognitoId;
  var livechatTable = "liveChat";
  var chatid=Math.floor((Math.random()*1000000000000000000)+1);
  var docClient = new AWS.DynamoDB.DocumentClient();
  var insertParams = {
    TableName:livechatTable,
    Item:{
      liveChatId:chatid.toString(),
      cognitoid: cognitoID,
      website: this.state.websiteLiveName,
      organizationName: this.state.defaultOrgName,
      organizationId: this.state.defaultOrgId,
      organizationRole:this.state.defaultRoleName
    }
  };
  docClient.put(insertParams, (err, data) => {
    if (err) {
      console.error("Unable to add item Client chat table. Error JSON:", JSON.stringify(err, null, 2));
    } else {
      NotificationManager.success('Website successfully added','Success');
      this.setState({
        websiteLiveName:''
      })
      
      this.getLiveChatData(this.state.defaultOrgId);
    }
  });
  }
}
async getLiveIconfile(event) {
  
  this.setState({liveIconFile: event.target.files[0]});
  Amplify.configure({
    Storage: {
        AWSS3: {
            bucket: jsonConfig.livechatBucket, //REQUIRED -  Amazon S3 bucket
        }
    }
  });
  
  const file = event.target.files[0];
  
  let uploadResult = await Storage.put(file.name, file, {contentType: file.type});
  let getImgS3=await Storage.get(uploadResult.key);
  var picSplit=getImgS3.split("?");
  console.log(picSplit);
  this.setState({
    livechatIcon:picSplit[0]
  });
}
async saveLiveChatSettings()
  {
    var tempTimeout='';
    var tempTimeoutMsg='';
    var tempendLive='';
    if(this.state.endLiveChat===undefined)
    {
      tempendLive='';
    }
    else{
      tempendLive=this.state.endLiveChat;
    }
    if(this.state.liveChatTimeout===undefined)
    {
      tempTimeout='1';
    }
    else{
      tempTimeout=this.state.liveChatTimeout;
    }
    if(this.state.liveChatTimeoutMessage===undefined)
    {
      tempTimeoutMsg='';
    }
    else{
      tempTimeoutMsg=this.state.liveChatTimeoutMessage;
    }
    if(this.state.liveChatColor===undefined || this.state.liveChatColor===null || this.state.liveChatColor==='')
    {
        var tempLiveChatColor='#008CFF'
    }
    else{
        var tempLiveChatColor=this.state.liveChatColor;
    }
    
    if(this.state.livechatText==='' || this.state.livevarGenericErrorMessage==='' || this.state.livevarBotName==='' || tempTimeoutMsg==='' || this.state.liveChatGreeting==='' || tempendLive==='')
    {
      NotificationManager.error('Please fill up all the field','Error'); 
    }
    else{
      var livechatSettingsTable = "liveChatSettings";
      var docClient = new AWS.DynamoDB.DocumentClient();
      const file = this.state.liveIconFile;
    if(file==='' || file===null || file==='null' || file===undefined)
    {
        if(this.state.livechatIcon===jsonConfig.s3BucketUrl+'no-preview-available.png')
        {
          var changeFileName='';
        }
        else{
          var changeFileName=this.state.livechatIcon;
        }
        
    }
    else{
      AWS.config.update({
        region: jsonConfig.awsConfigRegion, // Put your aws region here
        accessKeyId: jsonConfig.accessKeyId,
        secretAccessKey: jsonConfig.secretAccessKey
      })
      Amplify.configure({
        Storage: {
            AWSS3: {
                bucket: jsonConfig.livechatBucket, //REQUIRED -  Amazon S3 bucket
            }
        }
      });
      
      
      let uploadResult = await Storage.put(file.name, file, {contentType: file.type});
      let getImgS3=await Storage.get(uploadResult.key);
      var picSplit=getImgS3.split("?");
      this.setState({
        livechatIconUrl:picSplit[0]
      });

        changeFileName=this.state.livechatIconUrl;
        
        
    }
    var updtLiveChatSettingsParams = {
      TableName:livechatSettingsTable,
      Key:{
        "organizationId": this.state.defaultOrgId,
      },
      "UpdateExpression": "set liveChatIcon = :val1,liveChatText = :val2,liveChatgenericErrorMessage = :val3,liveChatBotName = :val4,livechatTimeout = :val5,timeoutMessage = :val6,greeting = :val7,chatColorCode = :val8 ,closeChatMessage = :val9",
      "ExpressionAttributeValues": {
        ":val1": changeFileName,
        ":val2":this.state.livechatText,
        ":val3":this.state.livevarGenericErrorMessage,
        ":val4":this.state.livevarBotName,
        ":val5":tempTimeout,
        ":val6":tempTimeoutMsg,
        ":val7":this.state.liveChatGreeting,
        ":val8":tempLiveChatColor,
        ":val9":tempendLive
      },
    };
    console.log(updtLiveChatSettingsParams);
      docClient.update(updtLiveChatSettingsParams,(err, data)=> {
        if (err) {
          console.error("Unable to update item. Error JSON:", JSON.stringify(err, null, 2));
          NotificationManager.error('Chat settings failed','Error'); 
        } else {
          console.log("UpdateItem succeeded:", JSON.stringify(data, null, 2));

          /**/var findCC=this.state.channelSetupArr;
          var Legalindex = findCC.map(function(o) { return o.name; }).indexOf("Live Chat");               
          findCC[Legalindex]["setup"]="Complete";
          this.updateSetup(findCC,this.state.aiSetupArr,this.state.crmSetupArray);

          NotificationManager.success('Chat settings successfully saved','Success');
          this.getLiveChatSettings(this.state.defaultOrgId)
        }
      });
    }
    
  }
  makeLiveChatEntrypoint()
  {
    var entrypointTable = "entryPoint";
    var docClient = new AWS.DynamoDB.DocumentClient();
    var opt=this.state.addLiveChatWebsiteOption;
    var optLabel=this.state.LiveChatWebsiteOptionLabel
    
    var blankArr=[];
    if(opt==='' || opt==="0" || opt===0)
    {
      NotificationManager.error('Please select website','Error');
    }
    else{
    var insertParams = {
        TableName:entrypointTable,
        Item:{
          entryPointIdentifier:opt,
          interactionId:this.state.boardId,
          EntryPointIdentifierService: 'Live Chat',
          EntryPointIdentifierValue: optLabel,
        }
      };
      docClient.put(insertParams, (err, data) => {
        if (err) {
          console.error("Unable to add item Client chat table. Error JSON:", JSON.stringify(err, null, 2));
        } else {
          console.log("Indentifier details inserted");
        }
      });
      blankArr.push(opt);
    
    var findLiveChatJson=this.state.activeInteractions;
    
    var Legalindex = findLiveChatJson.map(function(o) { return o.name; }).indexOf("Live Chat");
    
    findLiveChatJson[Legalindex]["key"]=[];
    findLiveChatJson[Legalindex]["key"].push(opt);
    
    

    /**/var interactionTable='interactions';
    var params = {
      TableName:interactionTable,
      Key:{
        "interactionId": this.state.boardId
      },
      "UpdateExpression": "set json = :boardData",
      "ExpressionAttributeValues": {
        ":boardData":btoa(JSON.stringify(findLiveChatJson)),
      }
    };
    docClient.update(params, (err, data) => {
      if (err) {
        console.error("Unable to update interaction. Error JSON:", JSON.stringify(err, null, 2));
      } else {
        console.log("Update interaction table");
        NotificationManager.success('Website successfully added','Success');
        this.getEntryPointLiveChatData();
      }
    });
    }
  }
  deleteLiveWebEntry(liveChatId)
  {
    var livechatTable = "liveChat";
    var docClient = new AWS.DynamoDB.DocumentClient();
    var delParams = {
      TableName:livechatTable,
      Key:{
          "liveChatId": liveChatId,
      },
    };
    docClient.delete(delParams,(err, data)=> {
      if (err) {
          console.error("Unable to delete item. Error JSON:", JSON.stringify(err, null, 2));
          NotificationManager.success('Failed to delete website','Success');
      } else {
          console.log("DeleteItem succeeded:", JSON.stringify(data, null, 2));
          NotificationManager.success('Website successfully deleted','Success');
          this.getLiveChatData(this.state.defaultOrgId);
      }
    });
    
  }
  deleteLiveWebsiteFromEntryPoint(entryPointId,keyIndex)
  {
    var interactionTable='interactions';
    var entrypointTable = "entryPoint";
    var docClient = new AWS.DynamoDB.DocumentClient();
    var delParams = {
      TableName:entrypointTable,
      Key:{
          "entryPointIdentifier": entryPointId,
      },
    };
    docClient.delete(delParams, (err, data)=> {
      if (err) {
          console.error("Unable to delete item. Error JSON:", JSON.stringify(err, null, 2));
      } else {
          console.log("DeleteItem succeeded:", JSON.stringify(data, null, 2));

          var findLiveChatJson=this.state.activeInteractions;
          var Legalindex = findLiveChatJson.map(function(o) { return o.name; }).indexOf("Live Chat");
          var filterdArray=findLiveChatJson[Legalindex]["key"];
          
          filterdArray.splice(filterdArray.findIndex(item => item === entryPointId), 1)

          var interactionParams = {
            TableName:interactionTable,
            Key:{
              "interactionId": this.state.boardId
            },
            "UpdateExpression": "set json = :boardData",
            "ExpressionAttributeValues": {
              ":boardData":btoa(JSON.stringify(findLiveChatJson)),
            }
          };
          docClient.update(interactionParams, (err, data) => {
            if (err) {
              console.error("Unable to update interaction. Error JSON:", JSON.stringify(err, null, 2));
            } else {
              console.log("Update interaction table");
              NotificationManager.success('Website successfully Removed','Success');
              this.getEntryPointLiveChatData();
            }
          });
      }
    });
    
    
  }
//-----------BLUELEAP CHAT------------//
chatsystemRefresh=()=>
{
  this.getBlueleapchat (this.state.defaultOrgId);
}
getBlueleapchat (orgNameParam)
  {
    var d = new Date();
    var n = d.getTime();
    
    var a=window.setTimeout(() => {
      this.setState({
        overlay:'block',
        changeOpacity:1
      });      
    }, 2000);
    var blueLeapTable = "blueleapChat";
    var docClient = new AWS.DynamoDB.DocumentClient();
    var searchParams = {
      TableName : blueLeapTable,
      FilterExpression : "organizationId = :orgName",
        ExpressionAttributeValues: {
            ":orgName": orgNameParam
        }
    };
    docClient.scan(searchParams,(err, data)=> {
      if (err) {
        console.log("Error", err);
      } else {
        console.log("GET DATA",data.Items);
        this.setState({
          checkPort:15000,
        });
        
          var d1 = new Date();
          var n1 = d1.getTime();
          var td=n1-n;
          if(td<2000)
          {
            clearTimeout(a);
            /*window.setTimeout(() => {
              this.setState({
                overlay:'none',
                changeOpacity:0
              });      
            }, 2001-td);*/
            this.setState({
              overlay:'none',
              changeOpacity:0
            }); 
            
          }
          else{
            window.setTimeout(() => {
              this.setState({
                overlay:'none',
                changeOpacity:0
              });      
            }, td);
            
          }

        if(data.Items.length>0)
        {
          
          if(data.Items[0].provisioningStatus==='started')
            {
              
                this.setState({
                  orgName:data.Items[0].organizationName,
                  checkPort:data.Items[0].port,
                  baseUrl:data.Items[0].baseUrl,
                  provision:data.Items[0].provisioningStatus,
                  isDisabled:true,
                  otherFieldstate:true,
                  provisionedResourceName:this.state.defaultOrgName+"_chat",
                  blChatDeallocation:false,
                  blueleapChatLoginUrl:data.Items[0].loginUrl1,
                  ipAdressUrl:data.Items[0].baseUrl,
                  //blueleapChatLoginUrlStatus:true
                });
                
               if(data.Items[0].transferWaitMsg===undefined)
                {
                  this.setState({
                    transferMsg:'',
                    //transferMsgStatus:false
                  })
                }
                else{
                  this.setState({
                    transferMsg:data.Items[0].transferWaitMsg,
                    transferMsgStatus:true
                  })
                }
                if(data.Items[0].noAgentsAvailble===undefined)
                {
                  this.setState({noAgentsMsg:''})
                }
                else{
                  this.setState({noAgentsMsg:data.Items[0].noAgentsAvailble})
                }
                
                if(data.Items[0].adminuserSecret===undefined)
                {
                  this.setState({
                    userName:'',
                    userPassword:'',
                    userId:'',
                    olduserName:'',
                    updateAdminButtonstat:false,
                    updateAdmintxtstat:false,
                  })
                }
                else{
                    let getSecretData={
                      "action": "getsecret",
                      "secretName": data.Items[0].adminuserSecret
                    }
                    axios.post(jsonConfig.secretManagerApi,getSecretData,{
                      headers: {
                        'X-API-KEY': jsonConfig.ApiKey,
                      }
                    }).then((response) => {
                      console.log("GET ADMIN USER SECRET",response);
                      this.setState({
                        userName:response.data.body.username,
                        userPassword:response.data.body.password,
                        olduserName:response.data.body.username,
                        userId:data.Items[0].adminUserId,
                        updateAdminButtonstat:true,
                        updateAdmintxtstat:true
                      })/**/
                    }, (error) => {
                      console.log(error);
                    });
                    
                }
                if(data.Items[0].integrationuserSecret===undefined)
                {
                  this.setState({
                    integrationUser:'',
                    integrationEmail:'',
                    integrationPassword:'',
                    integrationUserId:'',
                    oldIntegrationUserName:'',
                    intStatus:false,
                    intEmailStatus:false
                  })

                }
                else{
                  let getSecretData={
                    "action": "getsecret",
                    "secretName": data.Items[0].integrationuserSecret
                  }
                  axios.post(jsonConfig.secretManagerApi,getSecretData,{
                    headers: {
                      'X-API-KEY': jsonConfig.ApiKey,
                    }
                  }).then((response) => {
                    this.setState({
                      integrationUser:response.data.body.username,
                      oldIntegrationUserName:response.data.body.username,
                      integrationEmail:atob(data.Items[0].integrationEmail),
                      integrationPassword:response.data.body.password,
                      integrationUserId:data.Items[0].integrationUserId,
                      intStatus:true,
                      intEmailStatus:true
                    })
                  }, (error) => {
                    console.log(error);
                  });
                  
                }
            }
            else{
              this.setState({
                userName:'',
                olduserName:'',
                userPassword:'',
                userId:'',
                updateAdminButtonstat:false,
                updateAdmintxtstat:false,
                integrationUser:'',
                oldIntegrationUserName:'',
                integrationEmail:'',
                integrationPassword:'',
                integrationUserId:'',
                intStatus:false,
                intEmailStatus:false,
                isDisabled:false,
                otherFieldstate:false,
                baseUrl:'',
              });
            }
        }
        else{
          this.setState({
            updateAdmintxtstat:true,
            intStatus:true,
            intEmailStatus:true,
            transferMsgStatus:true
          })
        }
      }
    });
    
  }
  orgnameChange = event => {
    this.setState({
      orgName: event.target.value
    });
  }
  usernameChange = event => {
    this.setState({
      userName: event.target.value
    });
  }
  passwordChange = event => {
    this.setState({
      userPassword: event.target.value
    });
  }
  
  loginurlChange = event => {
    this.setState({
      baseUrl: event.target.value
    });
  }

  intUserChange = event => {
    this.setState({
      integrationUser: event.target.value
    });
  }

  intEmailChange = event => {
    this.setState({
      integrationEmail: event.target.value
    });
  }

  intPasswordChange = event => {
    this.setState({
      integrationPassword: event.target.value
    });
  }
  transferHandler = event => {
    this.setState({
      transferMsg: event.target.value
    });
  }
  noagentsHandler = event => {
    this.setState({
      noAgentsMsg: event.target.value
    });
  }
  
  sendcompName()
  {
    this.setState({
      //overlay:'block',
      //changeOpacity:1,
      blueleapChatLoginUrlStatus:false
    })
    var trimDomain= this.trimFunction(this.state.defaultOrgName);
    var lowerCaseDomain=trimDomain.toLowerCase();
    var createInstance=jsonConfig.blueleapChatLoginUrl+lowerCaseDomain;
    
    var port = Math.floor(1000 + Math.random() * 9000);
    var blueLeapTable = "blueleapChat";
    var docClient = new AWS.DynamoDB.DocumentClient();
    
    var dataCreate={
      "OrganizationId":this.state.defaultOrgId,
      "companyname":this.state.defaultOrgName,
      "port": port,
      "action":"create"
    }
    axios.post(jsonConfig.dockerAccessUrl, dataCreate,{
      headers: {
        'X-API-KEY': jsonConfig.ApiKey,
    }
    })
    .then((response) => {
      console.log("BL CHAT RESP",response);
      var params = {
        TableName:blueLeapTable,
        Item:{
          organizationId:this.state.defaultOrgId,
          baseUrl: jsonConfig.ec2url + ':'+ port,
          loginUrl1:createInstance,
          port:port,
          provisioningStatus:'started',
          blueleapChatName:this.state.defaultOrgName,
        }
      };
      docClient.put(params, (err, data) => {
        if (err) {
          NotificationManager.error('Blueleap Chat system provisioning has failed','Error');
        } else {
          NotificationManager.success('Blueleap Chat system will be provisioned shortly','Success');
          this.getBlueleapchat(this.state.defaultOrgId);
          this.setState({
            provisionedResourceName:this.state.defaultOrgName+"_chat",
            blChatDeallocation:false,
            isDisabled:true,
            //overlay:'none',
            //changeOpacity:0,
            updateAdmintxtstat:true,
            intStatus:true,
            transferMsgStatus:true,
          })
          this.tensecondsLoader();
        }
      })
      
      
    }, (error) => {
      console.log(error);
      NotificationManager.error('Blueleap Chat system provisioning has failed','Error');
    });
    
  }
  
  editAdminField()
  {
    this.setState({
      updateAdminButtonstat:false,
      updateAdmintxtstat:false,
      hideUpdatebuttonforBlAdmin:'inline-block',
      hideEditbuttonforBlAdmin:'none',
      panel3Display:'flex',
      activeAdminDetails:'activeAdminDetails',
      changeTextboxborder:'1px solid #a9d7ff',
      hideAllButtonforAdmin:'none',
      openAdminUpdateButton:'inline-block',
      openIntUserUpdateButton:'none'
    })
  }
  closePanel3Pop()
  {
    this.setState({
      updateAdminButtonstat:true,
      updateAdmintxtstat:true,
      hideUpdatebuttonforBlAdmin:'none',
      hideEditbuttonforBlAdmin:'inline-block',
      hideAllButtonforAdmin:'inline-block',
      panel3Display:'none',
      activeAdminDetails:'',
      changeTextboxborder:'',
      hideEditbuttonBlUser:'inline-block',
      hideUpdatebuttonBlUser:'none',
      hideAllButtonforIntUser:'inline-block',
      activeIntegrationDetails:'',
      intStatus:true,
      updateButtonstat:true,
      openAdminUpdateButton:'none',
      openIntUserUpdateButton:'none',
      activeWaitMsg:'',
      waitBoxreadonly:true,
      hideEditbuttonBlMsg:'inline-block',
      openWaitMsg:'none',
      transferMsgStatus:true,
    })
  }
  async updateCredentials()
  {
    if(this.state.userName==='' || this.state.userPassword==='')
    {
      NotificationManager.error('Please fill up all the fields','Error');
    }
    else{
    var adminData={
      "organizationId": this.state.defaultOrgId,
      "action": "getuserid",
      "username":this.state.userName,
      "password":this.state.userPassword,
    }
    var getAdminId=await axios.post(jsonConfig.userDeatilsApi, adminData,{
      headers: {
        'X-API-KEY': jsonConfig.ApiKey,
      }
    })
    
    if(getAdminId.data.statusCode===200)
    {
      
        var blueLeapTable = "blueleapChat";
        var docClient = new AWS.DynamoDB.DocumentClient();
        /**/var updtParams = {
          TableName:blueLeapTable,
          Key:{
            "organizationId": this.state.defaultOrgId,
          },
          "UpdateExpression": "set userName = :val1,userPassword = :val2,adminUserId = :val3,adminuserSecret = :val4",
          "ExpressionAttributeValues": {
            ":val1": btoa(this.state.userName),
            ":val2": btoa(this.state.userPassword),
            ":val3":getAdminId.data.body.data.userId,
            ":val4": "adminuserSecret_"+this.trimFunction(this.state.defaultOrgId),
          },
        };
        docClient.update(updtParams, (err, data)=> {
          if (err) {
            console.error("Unable to update item. Error JSON:", JSON.stringify(err, null, 2));
          } else {

            if(this.state.integrationEmail!=='' || this.state.transferMsg!=='')
            {
              var findChat=this.state.channelSetupArr;
              var Legalindex = findChat.map(function(o) { return o.name; }).indexOf("Chat");               
              findChat[Legalindex]["setup"]="Complete";/**/
              this.updateSetup(findChat,this.state.aiSetupArr,this.state.crmSetupArray)
            }
            NotificationManager.success('Username & Password successfully updated','Success');
            var updtsecretParams={
              "action": "createsecret",
              "secretName": "adminuserSecret_"+this.trimFunction(this.state.defaultOrgId),
              "secretString": {
                  "username": this.state.userName,
                  "password": this.state.userPassword,
                }
            }
            
            /**/axios.post(jsonConfig.secretManagerApi,updtsecretParams,{
              headers: {
                'X-API-KEY': jsonConfig.ApiKey,
              }
            }).then((response) => {
              
          }, (error) => {
            console.log(error);
          });
            this.getBlueleapchat(this.state.defaultOrgId);
          }
        });
        
        
    }
    else{
      NotificationManager.error('User updation failed','Error');
    }
  }
  }
  async updateAdminUser()
  {
    var updateAdminData={
      "organizationId": this.state.defaultOrgId,
      "action": "update",
      "username":this.state.userName,
      "password":this.state.userPassword,
      "userId":this.state.userId,
      "oldusername":this.state.olduserName
    }
    var updateAdmindetails=await axios.post(jsonConfig.userDeatilsApi, updateAdminData,{
      headers: {
        'X-API-KEY': jsonConfig.ApiKey,
      }
    })
    if(updateAdmindetails.data.statusCode===200)
    {
      var blueLeapTable = "blueleapChat";
      var docClient = new AWS.DynamoDB.DocumentClient();
      var updtParams = {
        TableName:blueLeapTable,
        Key:{
          "organizationId": this.state.defaultOrgId,
        },
        "UpdateExpression": "set userName = :val1,userPassword = :val2,adminuserSecret = :val3",
        "ExpressionAttributeValues": {
          ":val1": btoa(this.state.userName),
          ":val2": btoa(this.state.userPassword),
          ":val3": "adminuserSecret_"+this.trimFunction(this.state.defaultOrgId),
        },
      };
      docClient.update(updtParams, (err, data)=> {
        if (err) {
          console.error("Unable to update item. Error JSON:", JSON.stringify(err, null, 2));
        } else {
          NotificationManager.success('Username & Password successfully updated','Success');
          var updtsecretParams={
            "action": "updatesecret",
            "secretName": "adminuserSecret_"+this.trimFunction(this.state.defaultOrgId),
            "secretString": {
                "username": this.state.userName,
                "password": this.state.userPassword,
              }
          }
          axios.post(jsonConfig.secretManagerApi,updtsecretParams,{
            headers: {
              'X-API-KEY': jsonConfig.ApiKey,
            }
          }).then((response) => {
            
            this.setState({
              hideUpdatebuttonforBlAdmin:'none',
              hideEditbuttonforBlAdmin:'inline-block'
            })

        }, (error) => {
          console.log(error);
        });
          this.getBlueleapchat(this.state.defaultOrgId);
          this.closePanel3Pop()
        }
      }); 
    }
    else{
      NotificationManager.error('Admin details updation failed','Error');
    }
  }
  async getAdminuserId()
  {
    var adminData={
      "organizationId": this.state.defaultOrgId,
      "action": "test",
      "userId":this.state.userId,
    }
    var getAdminId=await axios.post(jsonConfig.userDeatilsApi, adminData,{
      headers: {
        'X-API-KEY': jsonConfig.ApiKey,
      }
    })
    if(getAdminId.data.statusCode===200)
    {
      NotificationManager.success('Connection Successful','Success');
    }
    else{
      NotificationManager.error('User testing failed','Error');
    }
  }
  editIntegration()
  {
    this.setState({
      intStatus:false,
      updateButtonstat:false,
      hideEditbuttonBlUser:'none',
      hideUpdatebuttonBlUser:'inline-block',
      activeIntegrationDetails:'activeIntegrationDetails',
      panel3Display:'flex',
      changeTextboxborder:'1px solid #a9d7ff',
      hideAllButtonforIntUser:'none',
      openAdminUpdateButton:'none',
      openIntUserUpdateButton:'inline-block'
    })
  }
  editTransferWait()
  {
    this.setState({
      panel3Display:'flex',
      changeTextboxborder:'1px solid #a9d7ff',
      activeWaitMsg:'activeWaitMsg',
      waitBoxreadonly:false,
      hideEditbuttonBlMsg:'none',
      openWaitMsg:'inline-block',
      transferMsgStatus:false,
      hideCreateButtonWait:'none',
    })
  }
  async sendIntegrationCreds()
  {
    if(this.state.integrationUser==='' || this.state.integrationEmail==='' || this.state.integrationPassword==='')
    {
      NotificationManager.error('Please fill up all the fields','Error');
    }
    else{
    var integrationData={
      "organizationId": this.state.defaultOrgId,
      "email":this.state.integrationEmail,
      "action": "create",
      "username":this.state.integrationUser,
      "password":this.state.integrationPassword,
    }
    //----------CALL API FOR INTEGRATION USERID------------//
    var getIntUserId=await axios.post(jsonConfig.userDeatilsApi, integrationData,{
      headers: {
        'X-API-KEY': jsonConfig.ApiKey,
      }
    })
    
    if(getIntUserId.data.statusCode===200)
    {
      var blueLeapTable='blueleapChat';
      var docClient = new AWS.DynamoDB.DocumentClient();
      var updtParams = {
        TableName:blueLeapTable,
        Key:{
          "organizationId": this.state.defaultOrgId,
        },
        "UpdateExpression": "set integrationUser = :val1,integrationEmail = :val2,integrationPassword = :val3,integrationUserId = :val4,integrationuserSecret = :val5",
        "ExpressionAttributeValues": {
          ":val1": btoa(this.state.integrationUser),
          ":val2": btoa(this.state.integrationEmail),
          ":val3": btoa(this.state.integrationPassword),
          ":val4": getIntUserId.data.body.user._id,
          ":val5":"integrationuserSecret_"+this.trimFunction(this.state.defaultOrgId)
        },
      };
      //console.log("CHK",this.state.integrationUser+this.state.integrationEmail);
      docClient.update(updtParams, (err, data)=> {
        if (err) {
          console.error("Unable to update item. Error JSON:", JSON.stringify(err, null, 2));
        } else {
            if(this.state.userName!=='' || this.state.transferMsg!=='')
            {
              var findChat=this.state.channelSetupArr;
              var Legalindex = findChat.map(function(o) { return o.name; }).indexOf("Chat");               
              findChat[Legalindex]["setup"]="Complete";/**/
              this.updateSetup(findChat,this.state.aiSetupArr,this.state.crmSetupArray)
            }
          NotificationManager.success('User successfully created','Success');
          this.getBlueleapchat(this.state.defaultOrgId);
        }
      });
      var updtsecretParams={
        "action": "createsecret",
        "secretName": "integrationuserSecret_"+this.trimFunction(this.state.defaultOrgId),
        "secretString": {
            "username": this.state.integrationUser,
            "password": this.state.integrationPassword,
            "apiUrl":""
          }
      }
      await axios.post(jsonConfig.secretManagerApi,updtsecretParams,{
        headers: {
          'X-API-KEY': jsonConfig.ApiKey,
        }
      });
    }
    else{
      NotificationManager.error('User already exsist','Error');
    }
  }
}
async updateSettings()
  {
    if(this.state.integrationUser==='' || this.state.integrationEmail==='' || this.state.integrationPassword==='')
    {
      NotificationManager.error('Please fill up all the fields','Error');
    }
    else{
    var integrationData={
      "organizationId": this.state.defaultOrgId,
      "email":this.state.integrationEmail,
      "action": "update",
      "username":this.state.integrationUser,
      "password":this.state.integrationPassword,
      "userId":this.state.integrationUserId,
      "oldusername":this.state.oldIntegrationUserName
    }
    
    //----------CALL API FOR INTEGRATION USERID------------//
    var getIntUserId=await axios.post(jsonConfig.userDeatilsApi, integrationData,{
      headers: {
        'X-API-KEY': jsonConfig.ApiKey,
      }
    })
    
    if(getIntUserId.data.statusCode===200)
    {
      var blueLeapTable='blueleapChat';
      var docClient = new AWS.DynamoDB.DocumentClient();
      var updtParams = {
        TableName:blueLeapTable,
        Key:{
          "organizationId": this.state.defaultOrgId,
        },
        "UpdateExpression": "set integrationUser = :val1,integrationEmail = :val2,integrationPassword = :val3,integrationUserId = :val4,integrationuserSecret = :val5",
        "ExpressionAttributeValues": {
          ":val1": btoa(this.state.integrationUser),
          ":val2": btoa(this.state.integrationEmail),
          ":val3": btoa(this.state.integrationPassword),
          ":val4": getIntUserId.data.body.user._id,
          ":val5": "integrationuserSecret_"+this.trimFunction(this.state.defaultOrgId),
        },
      };
      //console.log("CHK",this.state.integrationUser+this.state.integrationEmail);
      docClient.update(updtParams,(err, data)=> {
        if (err) {
          console.error("Unable to update item. Error JSON:", JSON.stringify(err, null, 2));
        } else {
          NotificationManager.success('User successfully updated','Success');
          this.getBlueleapchat(this.state.defaultOrgId);
          this.closePanel3Pop();
        }
      });
      var updtsecretParams={
        "action": "updatesecret",
        "secretName": "integrationuserSecret_"+this.trimFunction(this.state.defaultOrgId),
        "secretString": {
            "username": this.state.integrationUser,
            "password": this.state.integrationPassword,
            "apiUrl":"",
            "apiKey":""
          }
      }
      await axios.post(jsonConfig.secretManagerApi,updtsecretParams,{
        headers: {
          'X-API-KEY': jsonConfig.ApiKey,
        }
      });

      
    }
    else{
      NotificationManager.error('Please fill up all the field','Error');
    }
  }
  }
  async testIntegratedProfile()
  {
    var integrationData={
      "organizationId": this.state.defaultOrgId,
      "email":this.state.integrationEmail,
      "action": "test",
      "username":this.state.integrationUser,
      "password":this.state.integrationPassword,
      "userId":this.state.integrationUserId
    }
    //----------CALL API FOR INTEGRATION USERID------------//
    var getIntUserId=await axios.post(jsonConfig.userDeatilsApi, integrationData,{
      headers: {
        'X-API-KEY': jsonConfig.ApiKey,
      }
    })
    
    if(getIntUserId.data.statusCode===200)
    {
      NotificationManager.success('Connection successfull','Success');
      this.getBlueleapchat(this.state.defaultOrgId);
    }
    else{
      NotificationManager.error('Connection failed','Error');
    }
  }
  getResources(orgIdParam)
  {
    var interactionTable = "interactions";
    var docClient = new AWS.DynamoDB.DocumentClient();
    var searchParams = {
      TableName : interactionTable,
      FilterExpression : "organizationId = :val1",
      ExpressionAttributeValues: {
          ":val1": orgIdParam
      }
    };
    docClient.scan(searchParams, (err, data)=> {
      if (err) {
          console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
      } else {
          for(var i=0;i<data.Items.length;i++)
          {
            var jsonSet=data.Items[i].json;
            var extractSet=JSON.parse(atob(jsonSet));
            const result = extractSet.find( ({ name }) => name === 'Chat' );
            if(result!==undefined)
            {
              this.setState({checkingChat:true})
            }
            else{
              this.setState({checkingChat:false})
            }
          }
        }
    });
  }
  
  deallocateResource()
  {
    if(this.state.checkingChat===false)
    {
        this.setState({
          overlay:'block',
          changeOpacity:1
        })
        var dataDelete={
          "OrganizationId":this.state.defaultOrgId,
          "port": this.state.checkPort,
          "blueleapchatname": this.state.defaultOrgName,
          "action":"delete"
        }
        
        axios.post(jsonConfig.dockerAccessUrl, dataDelete,{
          headers: {
            'X-API-KEY': jsonConfig.ApiKey,
          }
        })
        .then((response) => {
          
          //----------UPDATE CHAT TABLE------------//
        var blueLeapTable = "blueleapChat";
        var docClient = new AWS.DynamoDB.DocumentClient();
        var updtParams = {
          TableName:blueLeapTable,
          Key:{
            "organizationId": this.state.defaultOrgId,
          },
          
        };
        docClient.delete(updtParams, (err, data)=> {
          if (err) {
            console.error("Unable to update item. Error JSON:", JSON.stringify(err, null, 2));
            NotificationManager.error('Blueleap Chat system deprovisioning has failed','Error');
          } else {
            this.setState({
              provisionedResourceName:'',
              checkingChat:true,
              ipAdressUrl:'',
              blueleapChatLoginUrl:'',
              isDisabled:false,
              overlay:'none',
              changeOpacity:0,
              blueleapChatLoginUrlStatus:true,

              transferMsg:'',
              noAgentsMsg:'',
              integrationPassword:'',
              integrationEmail:'',
              integrationUser:'',
              userName:'',
              userPassword:''
            });
            var tempIntegration=this.state.availableIntegrations;
            tempIntegration[1]["addClass"]="";
            this.setState({availableIntegrations:tempIntegration});

            var findChat=this.state.channelSetupArr;
            console.log(findChat);
            var Legalindex = findChat.map(function(o) { return o.name; }).indexOf("Chat");
            console.log("index",Legalindex);             
            findChat[Legalindex]["setup"]="inComplete";
            this.updateSetup(findChat,this.state.aiSetupArr,this.state.crmSetupArray);
            //this.getSetUp(this.state.defaultOrgName);
            //this.chatsystemRefresh();
            NotificationManager.success('Blueleap Chat system has deprovisioned','Success');
            
          }
        });
        }, (error) => {
          console.log(error);
          NotificationManager.error('Organization deallocation failed','Error');
        });

        var twilioTable = "twilioSubAccount";
        var docClient = new AWS.DynamoDB.DocumentClient();
        var twilioParams = {
          TableName:twilioTable,
          Key:{
            "organizationId": this.state.defaultOrgId,
          },
          
        };
        docClient.delete(twilioParams, (err, data)=> {
          if (err) {
            console.error("Unable to delete twilio for deallocate chat system. Error JSON:", JSON.stringify(err, null, 2));
            
          } else {
            console.log("delete twilio for deallocate chat system", JSON.stringify(err, null, 2));
          }
        });
        //this.getBlueleapchat(this.state.defaultOrgId);
    }
    else{
      NotificationManager.error('Please remove chat system from interaction board first','Error');
    }
            
  }
  addMsgService()
  {
    if(this.state.transferMsg==='' || this.state.noAgentsMsg==='')
    {
      NotificationManager.error('Please fill up all the fields','Error');
    }
    else{
    var blueLeapTable = "blueleapChat";
    var docClient = new AWS.DynamoDB.DocumentClient();
    var updtParams = {
      TableName:blueLeapTable,
      Key:{
        "organizationId": this.state.defaultOrgId,
      },
      "UpdateExpression": "set transferWaitMsg = :val1,noAgentsAvailble = :val2",
      "ExpressionAttributeValues": {
        ":val1": this.state.transferMsg,
        ":val2": this.state.noAgentsMsg,
      },
    };
    docClient.update(updtParams,(err, data)=> {
      if (err) {
        console.error("Unable to update item. Error JSON:", JSON.stringify(err, null, 2));
        NotificationManager.error('Messege updation failed','Error');
      } else {
        if(this.state.userName!=='' || this.state.integrationEmail!=='')
        {
          var findChat=this.state.channelSetupArr;
          var Legalindex = findChat.map(function(o) { return o.name; }).indexOf("Chat");               
          findChat[Legalindex]["setup"]="Complete";/**/
          this.updateSetup(findChat,this.state.aiSetupArr,this.state.crmSetupArray)
        }
        

        NotificationManager.success('Messege successfully updated','Success');
        this.getBlueleapchat(this.state.defaultOrgId);
        this.closePanel3Pop()
      }
    });
  }
    
  }
//--------------------------------//
//----------SENTIMENT ANALYSIS----//
async getTicketDetails(orgNameParam)
{
      var d = new Date();
      var n = d.getTime();
      
      var a=window.setTimeout(() => {
        this.setState({
          overlay:'block',
          changeOpacity:1
        });      
      }, 2000);
      var SATable = "sentimentAnalysisBot";
      var docClient = new AWS.DynamoDB.DocumentClient();
      var getBotParams = {
        TableName : SATable,
        FilterExpression : "organizationId = :orgId",
        ExpressionAttributeValues: {
            ":orgId": orgNameParam
        }
      };
      docClient.scan(getBotParams, (err, data)=> {
        if (err) {
            console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
        } else {
          var d1 = new Date();
          var n1 = d1.getTime();
          var td=n1-n;
          if(td<2000)
                {
                  clearTimeout(a);
                  this.setState({
                    overlay:'none',
                    changeOpacity:0
                  });  
                }
                else{
                  window.setTimeout(() => {
                    this.setState({
                      overlay:'none',
                      changeOpacity:0
                    });      
                  }, td);
                  
                }
          
          if(data.Items.length>0)
          {
              this.setState({
                analyse:JSON.parse(data.Items[0].intents),
                clarificationPrompt:data.Items[0].clarificationPrompt,
                abortStatement:data.Items[0].abortStatement,
                saProvisionButtonStatus:true,
                //sentimentBotZendesk:JSON.parse(data.Items[0].zendeskJson),
                //sentimentBotSalesforce:JSON.parse(data.Items[0].salesforceJson)
              })
              
              if(data.Items[0].zendeskJson!==undefined)
              {
                this.setState({sentimentBotZendesk:JSON.parse(data.Items[0].zendeskJson)})
              }
              if(data.Items[0].salesforceJson!==undefined)
              {
                this.setState({sentimentBotSalesforce:JSON.parse(data.Items[0].salesforceJson)})
              }
          }
        }
      });

}
getUttrence=event=>{
  this.setState({varIntentName:event.target.value})
  let getUtterenceParam={
    "action": "getintent",
    "intentName": event.target.value
  }
  axios.post(jsonConfig.getbotApi,getUtterenceParam,{
    headers: {
      'X-API-KEY': jsonConfig.ApiKey,
    }
  }).then((response) => {
      if(response.data.body===null || response.data.body==='null')
      {
        this.setState({
          utterenceArr:[],
          slotsArr:[],
          disableUpdateUtterence:true
        })
      }
      else{
        this.setState({
          utterenceArr:response.data.body.sampleUtterances,
          slotsArr:response.data.body.slots,
          disableUpdateUtterence:false
        })
      }
      
  }, (error) => {
    console.log(error);
  });
}
utterenceHandler=(event,index)=>
{
      var tempArr=this.state.utterenceArr; 
      tempArr[index]=event.target.value;
      this.setState({utterenceArr:tempArr})
}
async updateUtternce()
{
  
    var updateParams={
      "action":"updateintent",
      "intentName": this.state.varIntentName,
      "sampleUtterances": this.state.utterenceArr
    }
    var updtUtterence=await axios.post(jsonConfig.getbotApi, updateParams);
    if(updtUtterence.status===200)
    {
      NotificationManager.success('Utterence successfully updated','Success');
      //this.getTicketDetails();
    }
    else{
      NotificationManager.error('Utterence updation failed','Error');
    }
    
}
clarificationPromptHandler = event => {
  this.setState({
    clarificationPrompt: event.target.value
  });
}
abortStatementHandler = event => {
  this.setState({
    abortStatement: event.target.value
  });
}
async updatebotStatus()
{
  var SATable = "sentimentAnalysisBot";
  var docClient = new AWS.DynamoDB.DocumentClient();
      var updateBotData={
        "action": "updatebot",
        "botName": this.state.varIntentName,
        "clarificationPrompt": {
          "messages": [
            {
              "contentType": "PlainText",
              "content": this.state.clarificationPrompt,
              "groupNumber": 1
            }
          ],
        "maxAttempts": 3
        },
        "abortStatement": {
          "messages": [
            {
              "contentType": "PlainText",
              "content": this.state.abortStatement,
              "groupNumber": 5
            }
          ]
        }
    }
    var updtStatus=await axios.post(jsonConfig.getbotApi, updateBotData);
      if(updtStatus.status===200)
      {
        NotificationManager.success('Utterence successfully updated','Success');
        var updtParams = {
          TableName:SATable,
          Key:{
            "organizationId": this.state.defaultOrgId,
          },
          "UpdateExpression": "set abortStatement = :val1,clarificationPrompt = :val2",
          "ExpressionAttributeValues": {
            ":val1": this.state.abortStatement,
            ":val2": this.state.clarificationPrompt,
          },
        };
        docClient.update(updtParams, (err, data)=> {
          if (err) {
            console.error("Unable to update item. Error JSON:", JSON.stringify(err, null, 2));
          } else {
           console.log('Updated to SA table','Success');
            //this.getBlueleapchat(this.state.defaultOrgName);
          }
        });
        //this.getTicketDetails();
      }
      else{
        NotificationManager.error('Utterence updation failed','Error');
      }
}
changeStatusForSABot=(event,name,index)=>{
  if(name=='zendesk')
  {
    var temZendeskSaBotArr=this.state.sentimentBotZendesk;
    temZendeskSaBotArr[index].status=event.target.checked;
    this.setState({sentimentBotZendesk:temZendeskSaBotArr})
    
  }
  else{
    var temSalesforceSaBotArr=this.state.sentimentBotSalesforce;
    temSalesforceSaBotArr[index].status=event.target.checked;
    this.setState({sentimentBotSalesforce:temSalesforceSaBotArr})
  }
  var finalObj = this.state.sentimentBotZendesk.concat(this.state.sentimentBotSalesforce);
  this.setState({wholeSaBotArr:finalObj})
  
}
createSABot()
{
  this.setState({SaBotProvisioningStatus:true})
  /**/var SATable = "sentimentAnalysisBot";
  var docClient = new AWS.DynamoDB.DocumentClient();
  var requestJson=[];
  var welcomemsg='';
  this.state.sentimentBotZendesk.forEach(item => {
      if (item.status === true)
      {
          var intent={}
          intent.intentName=item.intentName
          intent.intentVersion=item.intentVersion
          requestJson.push(intent);
          welcomemsg+="\n-"+item.name
      }
    })
    this.state.sentimentBotSalesforce.forEach(item => {
      if (item.status === true)
      {
          var intent={}
          intent.intentName=item.intentName
          intent.intentVersion=item.intentVersion
          requestJson.push(intent);
          welcomemsg+="\n-"+item.name
      }
    })
    
  /**/let createBotData={
    "action": "createbot",
    "botName": this.state.defaultOrgName+"SABot",
    "intents":requestJson,
    "clarificationPrompt":welcomemsg
  }
  
  axios.post(jsonConfig.getbotApi,createBotData,{
    headers: {
      'X-API-KEY': jsonConfig.ApiKey,
    }
  }).then((response) => {
      var CreateBotparams = {
        TableName:SATable,
        Item:{
          organizationId: this.state.defaultOrgId,
          botname:this.state.defaultOrgName+"SABot",
          intents: JSON.stringify(this.state.wholeSaBotArr),
          welcomemsg:welcomemsg,
          zendeskJson:JSON.stringify(this.state.sentimentBotZendesk),
          salesforceJson:JSON.stringify(this.state.sentimentBotSalesforce),
        }
      };
      docClient.put(CreateBotparams, (err, data) => {
        if (err) {
          NotificationManager.error('Bot creation failed','Error');
          console.error("Unable to add item sentiment analysis table. Error JSON:", JSON.stringify(err, null, 2));
        } else {
          NotificationManager.success('Bot successfully created','Success');
          this.saBotTimeout();
          this.getTicketDetails(this.state.defaultOrgId);
          //this.setState({SaBotProvisioningStatus:false})
        }
      });
    
  }, (error) => {
    console.log(error);
  });
}
updateSABot()
{
  this.setState({SaBotProvisioningStatus:true})
  var SATable = "sentimentAnalysisBot";
  var docClient = new AWS.DynamoDB.DocumentClient();
  var requestJson=[];
  var welcomemsg='';
  this.state.sentimentBotZendesk.forEach(item => {
      if (item.status === true)
      {
          var intent={}
          intent.intentName=item.intentName
          intent.intentVersion=item.intentVersion
          requestJson.push(intent);
          welcomemsg+="\n-"+item.name
      }
    })
    this.state.sentimentBotSalesforce.forEach(item => {
      if (item.status === true)
      {
          var intent={}
          intent.intentName=item.intentName
          intent.intentVersion=item.intentVersion
          requestJson.push(intent);
          welcomemsg+="\n-"+item.name
      }
    })
    
  /**/let updateBotData={
    "action": "updatebot",
    "botName": this.state.defaultOrgName+"SABot",
    "intents":requestJson,
    "clarificationPrompt":welcomemsg
  }
  
  axios.post(jsonConfig.getbotApi,updateBotData,{
    headers: {
      'X-API-KEY': jsonConfig.ApiKey,
    }
  }).then((response) => {
      if(response.data.statusCode===200)
      {
        var updateBotParams = {
          TableName:SATable,
          Key:{
            "organizationId": this.state.defaultOrgId
          },
          "UpdateExpression": "set intents = :val1,welcomemsg = :val2,zendeskJson = :val3,salesforceJson = :val4",
          "ExpressionAttributeValues": {
            ":val1": JSON.stringify(this.state.wholeSaBotArr),
            ":val2": welcomemsg,
            ":val3": JSON.stringify(this.state.sentimentBotZendesk),
            ":val4": JSON.stringify(this.state.sentimentBotSalesforce)
          }
        };
        docClient.update(updateBotParams, (err, data) => {
          if (err) {
            NotificationManager.error('Bot updation failed','Error');
            console.error("Unable to add item sentiment analysis table. Error JSON:", JSON.stringify(err, null, 2));
            this.setState({SaBotProvisioningStatus:false})
          } else {
            NotificationManager.success('Bot successfully updated','Success');
            var findSa=this.state.aiSetupArr;
            var Legalindex = findSa.map(function(o) { return o.name; }).indexOf("Sentiment Bot");
            console.log("Legalindex",Legalindex);
            if(Legalindex!==-1)
            {
              findSa[Legalindex]["setup"]="Complete";
              this.updateSetup(this.state.channelSetupArr,findSa,this.state.crmSetupArray);
              //this.setState({aiSetupArr:findSa})
            }          
            else{

            }

            this.getTicketDetails(this.state.defaultOrgId);
            this.saBotTimeout();
           /* window.setTimeout(() => {
              this.setState({SaBotProvisioningStatus:false})      
            }, 5000);*/
            
          }
        });
      }
      else{
        NotificationManager.error('Bot updation failed','Error');
        this.saBotTimeout();
      }
    
  }, (error) => {
    console.log(error);
  });
}
deallocateBot=()=>{
  var sentimentBotTable = "sentimentAnalysisBot";
  var docClient = new AWS.DynamoDB.DocumentClient();
  let deleteBotData={
    "action": "deletebot",
    "botName": this.state.defaultOrgName+"SABot",
  }
  axios.post(jsonConfig.getbotApi, deleteBotData,{
    headers: {
      'X-API-KEY': jsonConfig.ApiKey,
    }
  })
  .then((response) => {
    console.log();
    //----------DELETE BOT TABLE------------//
  var deleteParams = {
    TableName:sentimentBotTable,
    Key:{
      "organizationId": this.state.defaultOrgId,
    },
  };
  docClient.delete(deleteParams, (err, data)=> {
    if (err) {
      console.error("Unable to update item. Error JSON:", JSON.stringify(err, null, 2));
      NotificationManager.error('Dialogue Search deprovisioning has failed','Error');
    } else {
          var tempServices=this.state.availableServices;
          tempServices[9]["addClass"]="";
          this.setState({
            availableServices:tempServices,
            deallocateBotButton:false,
            saProvisionButtonStatus:false,
          });

        var findSA=this.state.aiSetupArr;
        var Legalindex = findSA.map(function(o) { return o.name; }).indexOf("Sentiment Bot");               
        findSA[Legalindex]["setup"]="inComplete";/**/

        

        this.updateSetup(this.state.channelSetupArr,findSA,this.state.crmSetupArray);
        this.saBotTimeout();
        this.getTicketDetails(this.state.defaultOrgId);
        NotificationManager.success('Sentiment Bot successfully deprovisioned','Success');
        
    }
  });
  
  }, (error) => {
    console.log(error);
    NotificationManager.error('Sentiment Bot deprovisioning has failed','Error');
  });
}
//-------------------------------//
//--------ELASTIC SERACH----------//
closeGetCsvRowsModal=()=>
{
  this.setState({modalGetCsvRowsIsOpen:false})
}

closeConfirmDeleteCsvModal=()=>
{
  this.setState({modalconfirmDeleteCsvIsOpen:false})
}
openConfirmForDeleteCsv=()=>
{
  this.setState({modalconfirmDeleteCsvIsOpen:true})
}
cancelDeleteCsv=()=>
{
  this.setState({modalconfirmDeleteCsvIsOpen:false})
}
getCsvRows=()=>
{
  this.setState({modalGetCsvRowsIsOpen:true})
  this.getLastUploadedCsvData();
}
deleteCsvRows=()=>
{
  this.setState({modalconfirmDeleteCsvIsOpen:false})
  var data={
    "domainName": this.state.domainName,
    "url": this.state.csvUploadUrl,
    "Action": "deletedata"
  }
  axios.post(jsonConfig.elasticSearchUrl, data,{
    headers: {
      'X-API-KEY': jsonConfig.ApiKey,
    }
  })
  .then((response) => {

    var elasticSearchTable = "elasticSearch";
    var docClient = new AWS.DynamoDB.DocumentClient();
    var defaultMessegeParams = {
        TableName:elasticSearchTable,
        Key:{
          "organizationId": this.state.defaultOrgId
        },
        "UpdateExpression": "set csvFileName = :val1,csvRows = :val2 ,csvHeaders = :val3",
        "ExpressionAttributeValues": {
          ":val1":'',
          ":val2":'',
          ":val3":''
        }
      };
      docClient.update(defaultMessegeParams, (err, data) => {
        if (err) {
          NotificationManager.error('Csv rows failed to delete','Error');
          console.error("Unable to update es. Error JSON:", JSON.stringify(err, null, 2));
        } else {
            NotificationManager.success('All csv rows successfully deleted','Success');
            this.handleRemoveFile();
            this.setState({
              numberOfRows:0,
              insertMsg:'',
              uniqueId:0,
              shippingId:0,
              selectUniqueId:[],
            })
            //document.getElementById("getCsvName").innerHTML='';
            this.getelasticSearchData(this.state.defaultOrgId,'');
        }
      });

  }, (error) => {
    console.log(error);
    NotificationManager.error('Csv rows failed to delete','Error');
  });
}
getLastUploadedCsvData=()=>
{
  var data={
    "domainName": this.state.domainName,
    "url": this.state.csvUploadUrl,
    "Action": "getdata"
  }
  axios.post(jsonConfig.elasticSearchUrl, data,{
    headers: {
      'X-API-KEY': jsonConfig.ApiKey,
    }
  })
  .then((response) => {
    console.log(response);
     this.setState({getTotalCsvRows:response.data.body}) 
    
    
  }, (error) => {
    console.log(error);
  });
}
getelasticSearchData(orgNameParam,status)
  {
    var d = new Date();
    var n = d.getTime();
    
    var a=window.setTimeout(() => {
      this.setState({
        overlay:'block',
        changeOpacity:1
      });      
    }, 2000);

    var elasticSearchTable = "elasticSearch";
    var docClient = new AWS.DynamoDB.DocumentClient();
      var searchParams = {
        TableName : elasticSearchTable,
        FilterExpression : "organizationId = :orgName",
        ExpressionAttributeValues: {
            ":orgName": orgNameParam
        }
    };
    
    docClient.scan(searchParams, (err, data)=> {
        if (err) {
            console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
        } else {
          var d1 = new Date();
          var n1 = d1.getTime();
          var td=n1-n;
          if(td<2000)
          {
            clearTimeout(a);
            this.setState({
              overlay:'none',
              changeOpacity:0
            });  
          }
          else{
            window.setTimeout(() => {
              this.setState({
                overlay:'none',
                changeOpacity:0
              });      
            }, td);
            
          }
          if(data.Items.length===0)
          {
            this.setState({
              volumeSize:'XX',
              domainName:'',
              resourceName:'',
              checkingSearch:true
            });
          }
          else
          {
            console.log("GET LAST CSV FILE NAME",data.Items[0]);
            this.setState({
              subTitle: 'My Instance',
              domainName: data.Items[0].domainName,
              resourceName:this.state.defaultOrgName,
              domainID:data.Items[0].domainId,
              volumeSize:data.Items[0].volumeSize,
              csvUploadUrl:data.Items[0].searhURL,
              isDisabledSearch:true,
              errorMsg:data.Items[0].defaultMessege,
              typeMatch:data.Items[0].matchType,
              defaultmsgStatus:false,
              checkingSearch:false,
              getLastCsvFileName:data.Items[0].csvFileName,
              getLastCsvRows:data.Items[0].csvRows,
              
            });
            if(data.Items[0].csvHeaders===undefined || data.Items[0].csvHeaders==='' || data.Items[0].csvHeaders===null)
            {
              this.setState({getLastCsvHeaders:[]})
            }
            else{
              this.setState({getLastCsvHeaders:JSON.parse(data.Items[0].csvHeaders)})
            }
            if(data.Items[0].searhURL==null && status!=='deallocate')
            {
              this.setState({csvUploadStatus:false})
              let data = {
                "domainName":this.state.domainName,
                "Action":"geturl"
                }
            
            axios.post(jsonConfig.elasticSearchUrl, data,{
              headers: {
                'X-API-KEY': jsonConfig.ApiKey,
              }
            })
            .then((response) => {
              if(response.data.Endpoint==null)
              {
                this.setState({
                  csvUploadStatus:false
                })
                console.log("Please wait for 10 minutes");
              }
              else{
                this.setState({
                  csvUploadUrl:"https://"+response.data.Endpoint,
                  csvUploadStatus:true
                })
                var updateSearchparams = {
                  TableName:elasticSearchTable,
                  Key:{
                    "organizationId": this.state.defaultOrgId
                  },
                  "UpdateExpression": "set searhURL = :checkUrl",
                  "ExpressionAttributeValues": {
                    ":checkUrl": "https://"+response.data.Endpoint
                  }
                };
        
                
                docClient.update(updateSearchparams, (err, data) => {
                  if (err) {
                    console.error("Unable to update elastic table. Error JSON:", JSON.stringify(err, null, 2));
                  } else {
                    console.log("Update elastic table succeeded:", JSON.stringify(data, null, 2));
                    this.setState({checkingSearch:false})
                  }
                });
                
              }
              
            }, (error) => {
              console.log(error);
            });
            }
            else{
              this.setState({
                csvUploadUrl:data.Items[0].searhURL,
              });
            }
          }
          
        }
    });
    
  }
  domainNameHandler = event => {
    var createDomainName=event.target.value
    this.setState({
      domainName: createDomainName.toLowerCase()
    });
  }
  volumeSizeHandler = event => {
    this.setState({
      volumeSize: event.target.value
    });
    
  }

  /////

  sampleTextInput = event => {

    const value = (event.target.value) ? event.target.value.replace(/[^0-9a-zA-Z ]+/ig, "") : ''; // allow alphanumeric and space only

    if (event.target.value !== value) {
      event.target.value = value;
    }

   
    this.setState({
      alphaNumericSenderId: event.target.value
    });
    
  }

  isAlphaNumeric = event =>{
    if(event.target.checked){
      this.setState({
        alphaNumericEnabled:true // checkbox ticked
      },() => this.countryStatus())

    }
    else{
      this.setState({
        alphaFieldDisabled:true, // if unticked, disable entry 
        alphaNumericEnabled:false,
        alphaNumericSenderId:"" // remove any text
      })

    }

    
  }


  countryStatus(){


    if (countryListJson[this.state.alphaNumericCountry]=="Yes"){
      
      if(this.state.alphaNumericEnabled){ // if alphanumeric checkbox is ticked

        this.setState({
          alphaFieldDisabled:false // dont disable alphanumeric field
        })
      }
    }

    else if (countryListJson[this.state.alphaNumericCountry]=="No"){

      NotificationManager.warning('Selected country doesnt allow alphanumeric');

      this.setState({
        alphaFieldDisabled:true,
        alphaNumericSenderId:""
      })

    }
    else {

      if (this.state.alphaNumericCountry=="0"){
        return
      }

      if(this.state.alphaNumericEnabled){ 
      NotificationManager.warning('Manual registration required, admin will email you once completed');

      this.setState({
        alphaFieldDisabled:false
      })
      
      }
    }


  }




  setCountry= event => {
    this.setState({
      alphaNumericCountry: event.target.value
    },()=>this.countryStatus());


    
  }


  setCommunicationType = event => {

    // if sms is the first node, communciation type should be Inbound_Only

    var findSms=this.state.activeInteractions;
    var indexSms = findSms.map(function(o) { return o.name; }).indexOf("Sms");

    var commType = event.target.value


    if (indexSms==0){
      commType = "Inbound_Only"
      
    }




    this.setState({
      communicationType: commType
    });
    
  }

  getCommunicationType(){

    var findSms=this.state.activeInteractions;
    var indexSms = findSms.map(function(o) { return o.name; }).indexOf("Sms");

    

    if (indexSms==0){
      return "Inbound_Only"
      
    }



    return this.state.communicationType
    
  }


  communicationTypeDisabled(){
        // if sms is the first node, communication type dropdown will be disabled (check function setCommunicationType() )

        var findSms=this.state.activeInteractions;
        var indexSms = findSms.map(function(o) { return o.name; }).indexOf("Sms");
    
    
    
        if (indexSms==0){
          return true
          
        }

        return false
  }


  checkPrefixSuffixDisabled(){

    // if sms is the first node, prefix and suffix should be disabled

    var findSms=this.state.activeInteractions;
    var indexSms = findSms.map(function(o) { return o.name; }).indexOf("Sms");


    var prefix_suffix_disabled = false

    if (indexSms==0){
      prefix_suffix_disabled = true
      
    }

    return prefix_suffix_disabled


  }



  setMessagePrefix = event => {

    this.setState({
      messagePrefix: event.target.value
    });
    
    
  }

  getMessagePrefix(){

    // if sms is the first node,messagePrefix is empty

    var findSms=this.state.activeInteractions;
    var indexSms = findSms.map(function(o) { return o.name; }).indexOf("Sms");


    if (indexSms==0){
      return ""
      
    }

    return this.state.messagePrefix

  }

  setMessageSuffix= event => {

    this.setState({
      messageSuffix: event.target.value
    });

    
  }



  getMessageSuffix(){
  
    // if sms is the first node,messageSuffix is empty
    var findSms=this.state.activeInteractions;
    var indexSms = findSms.map(function(o) { return o.name; }).indexOf("Sms");


    if (indexSms==0){
      return ""
      
    }

    return this.state.messageSuffix

  }




  ////
  errorMsgHandler = event => {
    this.setState({
      errorMsg: event.target.value
    });
  }
  sendElasticCreds()
  {
    if(this.state.volumeSize === undefined || this.state.volumeSize===null || this.state.volumeSize==='' || this.state.volumeSize==='XX')
    {
      NotificationManager.error('Please select volume size first','Error');
    }
    else{
    var tempDomainName=this.state.defaultOrgName;
    var cognitoID = this.state.defaultCognitoId;
    var docClient = new AWS.DynamoDB.DocumentClient();
      let data = {
          "domainName": tempDomainName.toLowerCase(),
          "ElasticsearchVersion":"7.7",
          "InstanceType":this.state.volumeSize,
          "Action":"createdomain"
       }
    axios.post(jsonConfig.elasticSearchUrl, data,{
      headers: {
        'X-API-KEY': jsonConfig.ApiKey,
      }
    })
    .then((response) => {
      console.log("ELASTIC RESP",response)
      if(response.data.statusCode===200)
      {
        /*var tempInteraction=this.state.activeInteractions;
        var Legalindex = tempInteraction.map(function(o) { return o.name; }).indexOf("Elastic Search");*/
        //------------------------------------//
        var elasticSearchTable = "elasticSearch";
        
        var Searchparams = {
          TableName:elasticSearchTable,
          Item:{
            organizationId: this.state.defaultOrgId,
            domainId:response.data.DomainId,
            cognitoid: cognitoID,
            domainName:response.data.DomainName,
            searhURL:null,
            volumeSize:this.state.volumeSize,
            organizationRole: this.state.defaultRoleName
          }
        };
        docClient.put(Searchparams, (err, data) => {
          if (err) {
            console.error("Unable to add item Elastic search table. Error JSON:", JSON.stringify(err, null, 2));
            NotificationManager.error('Provisioning Dialogue search has failed','Error');
          } else {
            NotificationManager.success('Dialogue Search has started provisioning','Success');
            
            this.setState({
              defaultmsgStatus:false,
              csvUploadStatus:false
            })
            this.getelasticSearchData(this.state.defaultOrgId,'');
            this.elasticsearchTimeout();
          }
        });
      }
      else{
          console.log("ERROR");
          this.setState({csvUploadStatus:true})
          NotificationManager.error('Provisioning Dialogue search has failed','Error');
      }
      
    }, (error) => {
      console.log(error);
      this.setState({csvUploadStatus:true})
      NotificationManager.error('Provisioning Dialogue search has failed','Error');
    });
    //this.setState({csvUploadStatus:false})
    }
  }
  typeMatchHandler=event=>
  {
    this.setState({typeMatch:event.target.value})
  }
  setDefaultMsg()
  {
    console.log("HII",this.state.typeMatch);
    var temMatch=''
    if(this.state.typeMatch===undefined)
    {
      temMatch='ExactMatch';
    }
    else{
      temMatch=this.state.typeMatch;
    }
    if(this.state.errorMsg==='')
    {
      NotificationManager.error('Please enter value','Error');
    }
    else{
      var elasticSearchTable = "elasticSearch";
      var docClient = new AWS.DynamoDB.DocumentClient();
      
      var defaultMessegeParams = {
        TableName:elasticSearchTable,
        Key:{
          "organizationId": this.state.defaultOrgId
        },
        "UpdateExpression": "set defaultMessege = :val1,matchType = :val2",
        "ExpressionAttributeValues": {
          ":val1":this.state.errorMsg,
          ":val2":temMatch,
        }
      };
      docClient.update(defaultMessegeParams, (err, data) => {
        if (err) {
          console.error("Unable to update es. Error JSON:", JSON.stringify(err, null, 2));
          NotificationManager.error('Messege updation failed','Error');
        } else {
          console.log("Update es table");

          var findEs=this.state.aiSetupArr;
          var Legalindex = findEs.map(function(o) { return o.name; }).indexOf("Elastic Search");               
          findEs[Legalindex]["setup"]="Complete";/**/
          this.updateSetup(this.state.channelSetupArr,findEs,this.state.crmSetupArray)

          NotificationManager.success('Messege successfully updated','Success');
          this.getelasticSearchData(this.state.defaultOrgId,'');
        }
      });
    }
    
  }
  serachElasticSearch(orgIdParam)
  {
    var interactionTable = "interactions";
    var docClient = new AWS.DynamoDB.DocumentClient();
    var searchParams = {
      TableName : interactionTable,
      FilterExpression : "organizationId = :val1",
      ExpressionAttributeValues: {
          ":val1": orgIdParam
      }
    };
    docClient.scan(searchParams, (err, data)=> {
      if (err) {
          console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
      } else {
        for(var i=0;i<data.Items.length;i++)
        {
          var jsonSet=data.Items[i].json;
          var extractSet=JSON.parse(atob(jsonSet));
          const result = extractSet.find( ({ name }) => name === 'Elastic Search' );
          if(result!==undefined)
          {
            this.setState({checkingSearch:true})
          }
          else{
            this.setState({checkingSearch:false})
          }
        }
      }
    });
  }
  deallocateElasticSearch()
  {
    var dataDelete={
      "domainName":this.state.domainName,
      "ElasticsearchVersion": "7.1",
      "InstanceType": this.state.volumeSize,
      "Action": "deletedomain"
    }
    axios.post(jsonConfig.elasticSearchUrl, dataDelete,{
      headers: {
        'X-API-KEY': jsonConfig.ApiKey,
      }
    })
    .then((response) => {
      //----------UPDATE ELASTIC SEARCH TABLE------------//
    var elasticSearchTable = "elasticSearch";
    var docClient = new AWS.DynamoDB.DocumentClient();
    var deleteParams = {
      TableName:elasticSearchTable,
      Key:{
        "organizationId": this.state.defaultOrgId,
      },
    };
    docClient.delete(deleteParams, (err, data)=> {
      if (err) {
        console.error("Unable to update item. Error JSON:", JSON.stringify(err, null, 2));
        NotificationManager.error('Dialogue Search deprovisioning has failed','Error');
      } else {
            var tempServices=this.state.availableServices;
            tempServices[9]["addClass"]="";
            this.setState({availableServices:tempServices});

          var findEs=this.state.aiSetupArr;
          var Legalindex = findEs.map(function(o) { return o.name; }).indexOf("Elastic Search");               
          findEs[Legalindex]["setup"]="inComplete";/**/

          

          this.updateSetup(this.state.channelSetupArr,findEs,this.state.crmSetupArray);
          //this.getSetUp(this.state.defaultOrgName);
          NotificationManager.success('Dialogue Search successfully deprovisioned','Success');
          this.getelasticSearchData(this.state.defaultOrgId,'deallocate');
          this.elasticsearchTimeout();
          this.setState({
            isDisabled:false,
            checkingSearch:true,
            isDisabledSearch:true,
            csvUploadUrl:null,
            csvUploadStatus:false,
          })
      }
    });
    
    }, (error) => {
      console.log(error);
      NotificationManager.error('Dialogue Search deprovisioning has failed','Error');
    });
    
  }
  handleOpenDialog = (e) => {
    if (buttonRef.current) {
      buttonRef.current.open(e)
      
    }
  }
  handleRemoveFile = (e) => {
    if (buttonRef.current) {
      buttonRef.current.removeFile(e)
    }
  }
  handleOnFileLoad = (data) => {
    console.log("data",data)
    var headers=data[0].data;
    var lines=data;
    console.log("headers",headers);
    console.log("last_element",data[3].data[0])
    if(data[data.length-1].data[0]==='')
    {
      var tempLength=data.length-2
    }
    else{
       tempLength=data.length-1
    }
    this.setState({
      numberOfRows:tempLength,
      selectUniqueId:headers,
      tempHeaders:headers,
      csvRows:lines
    })
    var x=document.getElementById("getCsvName").innerHTML;
    this.setState({getCsvFileName:x})
  }
  
  csvPatternChange= event => {
    this.setState({
      checkedPattern: event.target.checked
    });
    if(event.target.checked!==true)
    {
      this.setState({
        selectUniqueId: this.state.tempHeaders,
        numberOfRows:this.state.csvRows.length-1
      });
    }
    else{
      this.setState({
        selectUniqueId: this.state.tempHeaders,
        numberOfRows:this.state.csvRows.length
      });
    }
  }
  uploadCsv()
  {
    var baseurl=this.state.csvUploadUrl;
    var result = "";
    
    if(this.state.uniqueId===0)
    {
      NotificationManager.warning('Please Select Question','Warning');
    }
    else
    {
        if(this.state.shippingId===0)
        {
          NotificationManager.warning('Please Select Data Column','Warning');
        }
        else{
            var uniqueKey=this.state.uniqueId-1;
            var shippingKey=this.state.shippingId-1;
            var filtered=this.state.csvRows
            if(this.state.checkedPattern===true)
            {
              for(let j=0;j<this.state.csvRows.length;j++)
              {
                let currentline=this.state.csvRows[j].data;
                if(currentline[uniqueKey]!==undefined)
                {
                  let a='{"index": {"_index": "data","_type": "_doc","_id": "'+currentline[uniqueKey]+'"}}\n';
                  let b='{"question": "'+currentline[uniqueKey]+'","answer": "'+currentline[shippingKey]+'"}\n';
                  result += a+b;
                }
                
               
              }
            }
            else{
              for(let i=1;i<this.state.csvRows.length;i++){
                let currentline=this.state.csvRows[i].data;
                if(currentline[uniqueKey]!==undefined)
                {
                  let a='{"index": {"_index": "data","_type": "_doc","_id": "'+currentline[uniqueKey]+'"}}\n';
                  let b='{"question": "'+currentline[uniqueKey]+'","answer": "'+currentline[shippingKey]+'"}\n';
                  result += a+b;
                }
                
              }
            }
           
          
          console.log("TOTAL LINE",result);
          let dataCsv=result+"\n";
          const headersCsv = {
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization':'Basic YWRtaW46S09MZW50VGVjaDYwKiE='
          }
          
          axios.post(baseurl+'/_bulk',dataCsv,{
            headers: headersCsv
          })
            .then((response) => {
              if(response.data.errors===true)
              {
                NotificationManager.error('CSV upload failed','Error');
              }
              else{
                NotificationManager.success(this.state.numberOfRows+' rows successfully inserted','Success');
                var elasticSearchTable = "elasticSearch";
                var docClient = new AWS.DynamoDB.DocumentClient();

                var defaultMessegeParams = {
                  TableName:elasticSearchTable,
                  Key:{
                    "organizationId": this.state.defaultOrgId
                  },
                  "UpdateExpression": "set csvFileName = :val1,csvRows = :val2 ,csvHeaders = :val3",
                  "ExpressionAttributeValues": {
                    ":val1":this.state.getCsvFileName,
                    ":val2":this.state.numberOfRows,
                    ":val3":JSON.stringify(this.state.tempHeaders)
                  }
                };
                docClient.update(defaultMessegeParams, (err, data) => {
                  if (err) {
                    console.error("Unable to update es. Error JSON:", JSON.stringify(err, null, 2));
                  } else {
                    console.log("Update es table");
                    this.getelasticSearchData(this.state.defaultOrgId,'');
                  }
                });
                this.setState({
                  insertMsg:this.state.numberOfRows+' rows successfully inserted'
                 })
              }
              
                  }, (error) => {
                    console.log(error);
                    NotificationManager.error('CSV upload failed.Invalid unique id or data column','Error');
          });
        }
    }
  }
  uniqueIdHandler = event => {
    this.setState({
      uniqueId: event.target.value
    });
    
  }
  shippingIdHandler = event => {
    this.setState({
      shippingId: event.target.value
    });
    
  }
  
  //--------------------LAMBDA FAQ------------------//
  
  getFaq(interactionidFaq)
  {
    var faqTable = "FAQLambda";
    var docClient = new AWS.DynamoDB.DocumentClient();
      
    if(interactionidFaq==='')
    {
      NotificationManager.error('You need to save board first','Error');
      this.setState({allFaqfieldReadonly:true})
    }
    else{
        var d = new Date();
        var n = d.getTime();
        
        var a=window.setTimeout(() => {
          this.setState({
            overlay:'block',
            changeOpacity:1
          });      
        }, 2000);
      this.setState({allFaqfieldReadonly:false})
      var faqParams = {
        TableName : faqTable,
        FilterExpression : "interactionId = :boardid",
        ExpressionAttributeValues: {
            ":boardid": interactionidFaq
        }
    };
    
    docClient.scan(faqParams, (err, data)=> {
        if (err) {
            console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
        } else {
        var d1 = new Date();
        var n1 = d1.getTime();
        var td=n1-n;
        if(td<2000)
              {
                clearTimeout(a);
                this.setState({
                  overlay:'none',
                  changeOpacity:0
                });  
              }
              else{
                window.setTimeout(() => {
                  this.setState({
                    overlay:'none',
                    changeOpacity:0
                  });      
                }, td);
                
              }
          if(data.Items.length===0)
          {
            this.setState({
              createQuestionJson:'',
              pharseName:'',
              enableLiveChatStatus:'',
              questionList:[],
            })
          }
          else
          {
            
            this.setState({
              createQuestionJson:atob(data.Items[0].json),
              getfaqID:data.Items[0].interactionId,
              questionList:JSON.parse(decodeURIComponent(escape(atob(data.Items[0].json)))).FAQ,
              enableLiveChatStatus:JSON.parse(atob(data.Items[0].json)).status,
            })
            if(JSON.parse(atob(data.Items[0].json)).chat===undefined || JSON.parse(atob(data.Items[0].json)).chat===null)
            {
              this.setState({pharseName:''})
            }
            else{
              this.setState({pharseName:JSON.parse(atob(data.Items[0].json)).chat})
            }
            
            if(JSON.parse(atob(data.Items[0].json)).input!==undefined)
            {
                var tempGetAllIcon=JSON.parse(atob(data.Items[0].json)).input;
                console.log("ZEN",JSON.parse(atob(data.Items[0].json)).input[0]);
                /*if(JSON.parse(atob(data.Items[0].json)).input[0].connector==='zendesk')
                {
                  
                  this.setState({zendeskInput:JSON.parse(atob(data.Items[0].json)).input})
                }
                else if(JSON.parse(atob(data.Items[0].json)).input[0].connector==='salesforce')
                {
                  this.setState({salesforceInput:JSON.parse(atob(data.Items[0].json)).input})
                }
                else if(JSON.parse(atob(data.Items[0].json)).input[0].connector==='blueleapchat')
                {
                  this.setState({blueleapchatInput:JSON.parse(atob(data.Items[0].json)).input})
                }
                else if(JSON.parse(atob(data.Items[0].json)).input[0].connector==='oracle')
                {
                  this.setState({oracleInput:JSON.parse(atob(data.Items[0].json)).input})
                }
                else{
                  
                }*/
                var filterZendeskdata=tempGetAllIcon.filter(r=>r.connector==='zendesk');
                if(filterZendeskdata.length>0)
                {
                  this.setState({zendeskInput:filterZendeskdata})
                }
                
                var filtersfdata=tempGetAllIcon.filter(r=>r.connector==='salesforce');
                if(filtersfdata.length>0)
                {
                  this.setState({salesforceInput:filtersfdata})
                }
                var filterbldata=tempGetAllIcon.filter(r=>r.connector==='blueleapchat');
                if(filterbldata.length>0)
                {
                  this.setState({blueleapchatInput:filterbldata})
                }
                var filterordata=tempGetAllIcon.filter(r=>r.connector==='oracle');
                if(filterordata.length>0)
                {
                  this.setState({oracleInput:filterordata})
                } 
            }
            else{

            }
            
          }
          
        }
    });

    }
    
  }
  questionsHandler = event => {
    this.setState({
      questions: event.target.value
    });
  }
  answersHandler = event => {
    this.setState({
      answers: event.target.value
    });
  }
  
  createQuestions()
  {
    AWS.config.update({
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: jsonConfig.awsConfigCredentials.IdentityPoolId,
        RoleArn: jsonConfig.awsConfigCredentials.RoleArn,
      }),
      region: jsonConfig.awsConfigRegion,
    });
    var faqID=Math.floor((Math.random()*1000000000000000000)+1);
    var cognitoID = this.state.defaultCognitoId;
    var docClient = new AWS.DynamoDB.DocumentClient();
    var lambdafaqTable = "FAQLambda";
    //var createQuestionJson=this.state.createQuestionJson;
    var questionData = {
      faqid:faqID,
      question:this.state.questions,
      answer:this.state.answers
    }
    if (this.state.createQuestionJson === '' ||  this.state.createQuestionJson == null) {
      var objData = {
        "FAQ": ""
      };
      var arryData = [];
      arryData.push(questionData);
      objData['FAQ'] = arryData;
      
      //------INSERT TO TABLE------------//
      var faqParams = {
        TableName:lambdafaqTable,
        Item:{
          interactionId:this.state.boardId.toString(),
          cognitoid: cognitoID,
          organizationName:this.state.defaultOrgName,
          organizationId:this.state.defaultOrgId,
          organizationRole:this.state.defaultRoleName,
          json:btoa(unescape(encodeURIComponent(JSON.stringify(objData))))
        }
      };
      docClient.put(faqParams, (err, data) => {
        if (err) {
          console.error("Unable to add item FAQ table. Error JSON:", JSON.stringify(err, null, 2));
          NotificationManager.error('FAQ failed to add','Error');
        } else {
          console.log("FAQ details inserted");
          NotificationManager.success('FAQ added successfully','Success');
          this.setState({
            answers:'',
            questions:''
          })
          this.getFaq(this.state.boardId);
        }
      });

    } else {
      var createQuestionJson = JSON.parse(this.state.createQuestionJson);
      createQuestionJson['FAQ'].push(questionData)
      console.log("createQuestionJson",btoa(unescape(encodeURIComponent(JSON.stringify(createQuestionJson)))))
      //-----UPDATE FAQTABLE-----//
      var updatePilotparams = {
        TableName:lambdafaqTable,
        Key:{
          "interactionId": this.state.boardId.toString()
        },
        "UpdateExpression": "set json = :faqJson",
        "ExpressionAttributeValues": {
          ":faqJson": btoa(unescape(encodeURIComponent(JSON.stringify(createQuestionJson))))
        }
      };

      
      docClient.update(updatePilotparams, (err, data) => {
        if (err) {
          console.error("Unable to update faq table. Error JSON:", JSON.stringify(err, null, 2));
          NotificationManager.error('FAQ failed to add','Error');
        } else {
          console.log("Update faq table succeeded:", JSON.stringify(data, null, 2));
          NotificationManager.success('FAQ added successfully','Success');
          this.setState({
            answers:'',
            questions:''
          })
          this.getFaq(this.state.boardId);
        }
      });
    }
  }
  editQuestion(question,answer,id,i)
  {
    var createQuestionJson = JSON.parse(this.state.createQuestionJson);
    //var arr=this.state.questionList;
    createQuestionJson['FAQ'].splice(i, 1);
    console.log("createQuestionJson2",createQuestionJson);
    this.setState({
      isEditable:true,
      editfaqId:id,
      questions:question,
      answers: answer,
      editableJson:createQuestionJson
      //questionList:arr
    });

  }
  updateQuestion()
  {
    var docClient = new AWS.DynamoDB.DocumentClient();
    var lambdafaqTable = "FAQLambda";
    var questionData = {
      faqid:this.state.editfaqId,
      question:this.state.questions,
      answer:this.state.answers
    }
    var createQuestionJson =this.state.editableJson;
    createQuestionJson['FAQ'].push(questionData)
    
    var updatePilotparams = {
      TableName:lambdafaqTable,
      Key:{
        "interactionId": this.state.boardId.toString()
      },
      "UpdateExpression": "set json = :faqJson",
      "ExpressionAttributeValues": {
        ":faqJson":btoa(unescape(encodeURIComponent(JSON.stringify(createQuestionJson))))
      }
    };

    
    docClient.update(updatePilotparams, (err, data) => {
      if (err) {
        console.error("Unable to update faq table. Error JSON:", JSON.stringify(err, null, 2));
      } else {
        NotificationManager.success('FAQ successfully updated','Success');
        this.setState({
          isEditable:false,
          answers:'',
          questions:''
        })
        this.getFaq(this.state.boardId);
      }
    });
  }
  deleteFaq(ques,ans,faqid,keyIndex)
  {
    var docClient = new AWS.DynamoDB.DocumentClient();
    var lambdafaqTable = "FAQLambda";
    var filterdArray=this.state.questionList;
    var createQuestionJson = JSON.parse(this.state.createQuestionJson);
    filterdArray.splice(keyIndex, 1);
    createQuestionJson['FAQ']=[];
    createQuestionJson['FAQ']=filterdArray;
    var updateFaqParams = {
      TableName:lambdafaqTable,
      Key:{
        "interactionId": this.state.boardId.toString()
      },
      "UpdateExpression": "set json = :faqJson",
      "ExpressionAttributeValues": {
        ":faqJson": btoa(JSON.stringify(createQuestionJson))
      }
    };

    
    docClient.update(updateFaqParams, (err, data) => {
      if (err) {
        console.error("Unable to update faq table. Error JSON:", JSON.stringify(err, null, 2));
      } else {
        NotificationManager.success('FAQ successfully deleted','Success');
        this.getFaq(this.state.boardId);
      }
    });/**/

  }
  
  zendeskSettingsForLfaqHandler=(event,index)=>
  {
      var tempArr=this.state.zendeskInput; 
      tempArr[index].Text=event.target.value;
      this.setState({zendeskInput:tempArr})
  }
  updateZendeskForLfaq()
  {
    console.log(this.state.boardId);
      var docClient = new AWS.DynamoDB.DocumentClient();
      var lambdafaqTable = "FAQLambda";
      if(this.state.createQuestionJson==='' || this.state.createQuestionJson===null || this.state.createQuestionJson===undefined)
      {
        var getFaqList= {
          "FAQ": []
        };
      }
      else{
        getFaqList=JSON.parse(this.state.createQuestionJson);
      }
      var tempzenInput=this.state.zendeskInput;
      var tempzenOutput=this.state.zendeskOutput;

      var tempFaqlistInput=getFaqList["input"];
      var tempFaqlistOutput=getFaqList["output"];

      var filterzenInput=tempFaqlistInput.filter(r=>r.connector!=='zendesk');
      var filterzenOutput=tempFaqlistOutput.filter(r=>r.connector!=='zendesk');
      
      for(var i=0;i<tempzenInput.length;i++)
      {
        filterzenInput.push(tempzenInput[i]);
        filterzenOutput.push(tempzenOutput[i]);
      }

      getFaqList["input"]=filterzenInput;
      getFaqList["output"]=filterzenOutput;

      var tempCreateNewArr=getFaqList;
      console.log(getFaqList);
    
    var updatePilotparams = {
      TableName:lambdafaqTable,
      Key:{
        "interactionId": this.state.boardId.toString()
      },
      "UpdateExpression": "set json = :faqJson",
      "ExpressionAttributeValues": {
        ":faqJson": btoa(JSON.stringify(tempCreateNewArr))
      }
    };

    
    docClient.update(updatePilotparams, (err, data) => {
      if (err) {
        console.error("Unable to update faq table. Error JSON:", JSON.stringify(err, null, 2));
      } else {
        NotificationManager.success('Zendesk settings successfully updated','Success');
        this.getFaq(this.state.boardId);
      }
    });
      
  }
  salesforceSettingsForLfaqHandler=(event,index)=>
  {
      var tempArr=this.state.salesforceInput; 
      tempArr[index].Text=event.target.value;
      this.setState({salesforceInput:tempArr})
  }
  updateSalesforceForLfaq()
  {
    console.log(this.state.boardId);
      var docClient = new AWS.DynamoDB.DocumentClient();
      var lambdafaqTable = "FAQLambda";
      if(this.state.createQuestionJson==='' || this.state.createQuestionJson===null || this.state.createQuestionJson===undefined)
      {
        var getFaqList= {
          "FAQ": []
        };
      }
      else{
        getFaqList=JSON.parse(this.state.createQuestionJson);
      }
      var tempsfInput=this.state.salesforceInput;
      var tempsfOutput=this.state.salesforceOutput;

      var tempFaqlistInput=getFaqList["input"];
      var tempFaqlistOutput=getFaqList["output"];

      var filtersfInput=tempFaqlistInput.filter(r=>r.connector!=='salesforce');
      var filtersfOutput=tempFaqlistOutput.filter(r=>r.connector!=='salesforce');
      console.log("FILTER SF",filtersfInput)
      
      /**/for(var i=0;i<tempsfInput.length;i++)
      {
        //getFaqList["input"].push(tempsfInput[i]);
        //getFaqList["output"].push(tempsfOutput[i]);
        //console.log(getFaqList["input"][i]);
        filtersfInput.push(tempsfInput[i]);
        filtersfOutput.push(tempsfOutput[i]);
      }
      getFaqList["input"]=filtersfInput;
      getFaqList["output"]=filtersfOutput;
      console.log("LATEST",getFaqList);
      //getFaqList["input"]=this.state.salesforceInput;
      //getFaqList["output"]=this.state.salesforceOutput;
      var tempCreateNewArr=getFaqList;
     
    
   var updatePilotparams = {
      TableName:lambdafaqTable,
      Key:{
        "interactionId": this.state.boardId.toString()
      },
      "UpdateExpression": "set json = :faqJson",
      "ExpressionAttributeValues": {
        ":faqJson": btoa(JSON.stringify(tempCreateNewArr))
      }
    };

    console.log("SEND PARAMS",updatePilotparams);
    docClient.update(updatePilotparams, (err, data) => {
      if (err) {
        console.error("Unable to update faq table. Error JSON:", JSON.stringify(err, null, 2));
      } else {
        NotificationManager.success('Salesforce settings successfully updated','Success');
        this.getFaq(this.state.boardId);
      }
    });
    
      
  }
  pharseHandler = (event,name,index) => {
    if(name==='chat')
    {
      var tempArr=this.state.blueleapchatInput; 
      tempArr[index].Text=event.target.value;
      this.setState({blueleapchatInput:tempArr})
    }
    else{
      tempArr=this.state.oracleInput; 
      tempArr[index].Text=event.target.value;
      this.setState({oracleInput:tempArr})
    }
    
  }
  submitPharse(event,name)
  {
    var docClient = new AWS.DynamoDB.DocumentClient();
      var lambdafaqTable = "FAQLambda";
      
      if(this.state.createQuestionJson==='' || this.state.createQuestionJson===null || this.state.createQuestionJson===undefined)
      {
        var getFaqList= {
          "FAQ": []
        };
      }
      else{
        getFaqList=JSON.parse(this.state.createQuestionJson);
      }
      
      
      if(name==='chat')
      {
        var tempsfInput=this.state.blueleapchatInput;
        var tempsfOutput=this.state.blueleapchatOutput;

        var tempFaqlistInput=getFaqList["input"];
        var tempFaqlistOutput=getFaqList["output"];

        var filtersfInput=tempFaqlistInput.filter(r=>r.connector!=='blueleapchat');
        var filtersfOutput=tempFaqlistOutput.filter(r=>r.connector!=='blueleapchat');
        
        
      }
      else{
        var tempsfInput=this.state.oracleInput;
        var tempsfOutput=this.state.oracleOutput;

        var tempFaqlistInput=getFaqList["input"];
        var tempFaqlistOutput=getFaqList["output"];

        var filtersfInput=tempFaqlistInput.filter(r=>r.connector!=='oracle');
        var filtersfOutput=tempFaqlistOutput.filter(r=>r.connector!=='oracle');
       
      }
      for(var i=0;i<tempsfInput.length;i++)
      {
        filtersfInput.push(tempsfInput[i]);
        filtersfOutput.push(tempsfOutput[i]);
      }
      getFaqList["input"]=filtersfInput;
      getFaqList["output"]=filtersfOutput;
      var tempCreateNewArr=getFaqList;
     
    
    var updatePilotparams = {
      TableName:lambdafaqTable,
      Key:{
        "interactionId": this.state.boardId.toString()
      },
      "UpdateExpression": "set json = :faqJson",
      "ExpressionAttributeValues": {
        ":faqJson": btoa(JSON.stringify(tempCreateNewArr))
      }
    };

    console.log("INPUT",tempCreateNewArr)
    docClient.update(updatePilotparams, (err, data) => {
      if (err) {
        console.error("Unable to update faq table. Error JSON:", JSON.stringify(err, null, 2));
      } else {
        NotificationManager.success(name+' settings successfully updated','Success');
        this.getFaq(this.state.boardId);
      }
    });

      
  }
  
  changeStatusForLfaq(event,name,index)
  {
    if(event.target.checked===true)
    {
      var tmpChk='enabled'
    }
    else{
      tmpChk='disabled'
    }
    //this.setState({StatusForLfaq:event.target.checked})
    if(name==='salesforce')
    {
      var tempArr=this.state.salesforceInput;
      var tempOutput=this.state.salesforceOutput;
      tempArr[index].status=tmpChk;
      this.setState({salesforceInput:tempArr})
      
    }
    else if(name==='zendesk')
    {
      tempArr=this.state.zendeskInput;
      tempOutput=this.state.zendeskOutput;
      tempArr[index].status=tmpChk;
      this.setState({zendeskInput:tempArr})
    }
    else if(name==='oracle')
    {
      tempArr=this.state.oracleInput;
      tempOutput=this.state.oracleOutput;
      console.log();
      tempArr[index].status=tmpChk;
      this.setState({oracleInput:tempArr})
    }
    else{
      tempArr=this.state.blueleapchatInput;
      tempOutput=this.state.blueleapchatOutput;
      tempArr[index].status=tmpChk;
      this.setState({blueleapchatInput:tempArr})
      
    }
    var docClient = new AWS.DynamoDB.DocumentClient();
    var lambdafaqTable = "FAQLambda";

    if(this.state.createQuestionJson==='' || this.state.createQuestionJson===null || this.state.createQuestionJson===undefined)
      {
        var getFaqList= {
          "FAQ": []
        };
      }
      else{
        getFaqList=JSON.parse(this.state.createQuestionJson);
      }
    getFaqList["input"]=tempArr;
    getFaqList["output"]=tempOutput;
    var tempCreateNewArr=getFaqList;
     
    
    var updatePilotparams = {
      TableName:lambdafaqTable,
      Key:{
        "interactionId": this.state.boardId.toString()
      },
      "UpdateExpression": "set json = :faqJson",
      "ExpressionAttributeValues": {
        ":faqJson": btoa(JSON.stringify(tempCreateNewArr))
      }
    };

    
    docClient.update(updatePilotparams, (err, data) => {
      if (err) {
        console.error("Unable to update faq table. Error JSON:", JSON.stringify(err, null, 2));
      } else {
        NotificationManager.success('Status successfully updated','Success');
        this.getFaq(this.state.boardId);
      }
    });
  }
  //-----------ZENDESK------//
  getZendeskData(orgNameParam)
  {
    var d = new Date();
    var n = d.getTime();
    
    var a=window.setTimeout(() => {
      this.setState({
        overlay:'block',
        changeOpacity:1
      });      
    }, 2000);
    var zendeskSettingsTable = "zendeskSettings";
    var docClient = new AWS.DynamoDB.DocumentClient();
      var zendeskParams = {
        TableName : zendeskSettingsTable,
        FilterExpression : "organizationId = :orgId",
        ExpressionAttributeValues: {
            ":orgId": orgNameParam
        }
    };
    
    docClient.scan(zendeskParams, (err, data)=> {
        if (err) {
            console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
        } else {
          var d1 = new Date();
          var n1 = d1.getTime();
          var td=n1-n;
          if(td<2000)
          {
            clearTimeout(a);
            this.setState({
              overlay:'none',
              changeOpacity:0
            });  
          }
          else{
            window.setTimeout(() => {
              this.setState({
                overlay:'none',
                changeOpacity:0
              });      
            }, td);
            
          }
          this.setState({
            zendeskDataLength:data.Items.length
          });
          if(data.Items.length===0)
          {
            this.setState({
              zenUser: '',
              apikey: '',
              zendeskUrl:''
            });
          }
          else
          {
            var getSecretData={
              "action": "getsecret",
              "secretName": data.Items[0].zendeskuserSecret
            }
            axios.post(jsonConfig.secretManagerApi,getSecretData,{
              headers: {
                'X-API-KEY': jsonConfig.ApiKey,
              }
            }).then((response) => {
              console.log("ZENDESK DATA",response);
              if(response.data.body===undefined)
              {
                this.setState({
                  zenUser: '',
                  apikey: '',
                  zendeskUrl:''
                });
              }
              else{
                this.setState({
                  zenUser: response.data.body.username,
                  apikey: response.data.body.apiKey,
                  zendeskUrl:data.Items[0].baseUrl
                });
                //this.findandAddClass(this.state.availableCrm,"Zendesk","Crm");
              }
              /**/
            }, (error) => {
              console.log(error);
            });
            
          }
          
        }
    });
    
  }
  zenUserHandler = event => {
    this.setState({
      zenUser: event.target.value
    });
  }
  apiKeyHandler = event => {
    this.setState({
      apikey: event.target.value
    });
  }
  zendeskurlHandler = event => {
    this.setState({
      zendeskUrl: event.target.value
    });
  }
  
sendZendeskCreds() {
    var zendeskSettingsTable="zendeskSettings";
    var docClient = new AWS.DynamoDB.DocumentClient();
    if(this.state.zenUser==='' || this.state.apikey==='')
    {
        NotificationManager.error('Please fill up all the field','Error');
    }
    else{
      if( this.state.zendeskDataLength>0)
      {
        var updtZendeskparams = {
          TableName:zendeskSettingsTable,
          Key:{
            "organizationId": this.state.defaultOrgId
          },
          "UpdateExpression": "set zendeskuserSecret =:val1 , baseUrl =:val2,organizationRole =:val3",
          "ExpressionAttributeValues": {
            ":val1":"zendeskSecret_"+this.trimFunction(this.state.defaultOrgId),
            ":val2": this.state.zendeskUrl,
            ":val3": this.state.defaultRoleName,
            
          }
        };
        docClient.update(updtZendeskparams, (err, data) => {
          if (err) {
            console.error("Unable to update ZENDESK. Error JSON:", JSON.stringify(err, null, 2));
            NotificationManager.error('Zendesk credentials updation failed','Error');
          } else {
            console.log("Zendesk details updated");
            //------------UPDATE SECRET MANAGER-----------//

            var updtsecretParams2={
              "action": "updatesecret",
              "secretName": "zendeskSecret_"+this.trimFunction(this.state.defaultOrgId),
              "secretString": {
                  "username": this.state.zenUser,
                  "apiUrl":this.state.zendeskUrl,
                  "apiKey":this.state.apikey
                }
            }
            axios.post(jsonConfig.secretManagerApi,updtsecretParams2,{
              headers: {
                'X-API-KEY': jsonConfig.ApiKey,
              }
            }).then((response) => {
              NotificationManager.success('Zendesk credentials successfuly updated','Success');
            }, (error) => {
              console.log(error);
              NotificationManager.error('Zendesk credentials updation failed','Error');
            });
          }
        });
      }
      else{
        var params2 = {
          TableName:zendeskSettingsTable,
          Item:{
            organizationId: this.state.defaultOrgId,
            zendeskuserSecret:"zendeskSecret_"+this.trimFunction(this.state.defaultOrgId),
            baseUrl:this.state.zendeskUrl,
            organizationRole: this.state.defaultRoleName,
            
          }
        };
        docClient.put(params2, (err, data) => {
          if (err) {
            console.error("Unable to add item zendesk table. Error JSON:", JSON.stringify(err, null, 2));
            NotificationManager.error('Zendesk credentials updation failed','Error');
          } else {
            console.log("Zendesk details inserted");
            var updtsecretParams={
              "action": "createsecret",
              "secretName": "zendeskSecret_"+this.trimFunction(this.state.defaultOrgId),
              "secretString": {
                  "username": this.state.zenUser,
                  "apiUrl":this.state.zendeskUrl,
                  "apiKey":this.state.apikey
                }
            }
            axios.post(jsonConfig.secretManagerApi,updtsecretParams,{
              headers: {
                'X-API-KEY': jsonConfig.ApiKey,
              }
            }).then((response) => {
              NotificationManager.success('Zendesk credentials successfuly updated','Success');
            }, (error) => {
              console.log(error);
              NotificationManager.error('Zendesk credentials updation failed','Error');
            });

            var findZendesk=this.state.crmSetupArray;
            var Legalindex = findZendesk.map(function(o) { return o.name; }).indexOf("Zendesk");               
            findZendesk[Legalindex]["setup"]="Complete";/**/
            this.updateSetup(this.state.channelSetupArr,this.state.aiSetupArr,findZendesk)
          }
        });
      }

    }
  }
//-------------------------------//
//----------SALES FORCE---------//
async getSalesforceData(orgNameParam)
{
  var d = new Date();
  var n = d.getTime();
    
    var a=window.setTimeout(() => {
      this.setState({
        overlay:'block',
        changeOpacity:1
      });      
    }, 2000);
  var salesforceTable = "salesForce";
  var docClient = new AWS.DynamoDB.DocumentClient();
    var getParams = {
      TableName : salesforceTable,
        FilterExpression : "organizationId = :orgName",
        ExpressionAttributeValues: {
            ":orgName": orgNameParam
        }
    };
    docClient.scan(getParams,(err, data)=> {
      if (err) {
        console.log("Error", err);
      } else {
        var d1 = new Date();
        var n1 = d1.getTime();
        var td=n1-n;
        if(td<2000)
        {
          clearTimeout(a);
          this.setState({
            overlay:'none',
            changeOpacity:0
          });  
        }
        else{
          window.setTimeout(() => {
            this.setState({
              overlay:'none',
              changeOpacity:0
            });      
          }, td);
          
        }
        if(data.Items.length>0)
        {
            this.setState({
              sfAccessToken:data.Items[0].accessTokenUrl,
              sfBaseUrl:data.Items[0].baseUrl,
              sfCreateDisabled:true,
              sfUpdateDisabled:false
            })
            if(data.Items[0].subjectSAweightage===undefined || data.Items[0].subjectSAweightage===null)
            {
                this.setState({
                  subweightage:'',
                  descWeightage:''
                })
            }
            else{
              this.setState({
                subweightage:data.Items[0].subjectSAweightage,
                descWeightage:data.Items[0].descriptionSAweightage
              })
            }
            var getSecretData={
              "action": "getsecret",
              "secretName": data.Items[0].salesforceuserSecret
            }
            axios.post(jsonConfig.secretManagerApi,getSecretData,{
              headers: {
                'X-API-KEY': jsonConfig.ApiKey,
              }
            }).then((response) => {
              //this.findandAddClass(this.state.availableCrm,"Sales Force","Crm")
              this.setState({
                sfUsername: response.data.body.username,
                sfPassword: response.data.body.password,
              });
            }, (error) => {
              console.log(error);
            });
        }
        else{
          this.setState({
            sfUsername: '',
            sfPassword: '',
            sfAccessToken:'',
            sfBaseUrl:'',
            sfCreateDisabled:false,
            sfUpdateDisabled:true
          })

        }
      }
    })
}
sfUsernameHandler = event => {
  this.setState({
    sfUsername: event.target.value
  });
}
sfPasswordHandler = event => {
  this.setState({
    sfPassword: event.target.value
  });
}
sfAccessTokenHandler = event => {
  this.setState({
    sfAccessToken: event.target.value
  });
}
sfBaseHandler = event => {
  this.setState({
    sfBaseUrl: event.target.value
  });
}
sendSalesforceCreds()
{
  var docClient = new AWS.DynamoDB.DocumentClient();
  var salesforceTable = "salesForce";
  if(this.state.sfUsername==='' || this.state.sfPassword==='' || this.state.sfAccessToken==='' || this.state.sfBaseUrl==='')
  {
    NotificationManager.error('Please fill up all the fields','Error');
  }
  else{
  var sfInsparams = {
    TableName:salesforceTable,
    Item:{
      organizationId: this.state.defaultOrgId,
      accessTokenUrl: this.state.sfAccessToken,
      baseUrl: this.state.sfBaseUrl,
      salesforceuserSecret:"salesforceSecret_"+this.state.defaultOrgId
    }
  };
  docClient.put(sfInsparams, (err, data) => {
    if (err) {
      //console.error("Unable to add item salforce table. Error JSON:", JSON.stringify(err, null, 2));
      NotificationManager.error('Sales Force credentials creation failed','Error');
    } else {
      
      var createsecretParams={
        "action": "createsecret",
        "secretName": "salesforceSecret_"+this.state.defaultOrgId,
        "secretString": {
            "username": this.state.sfUsername,
            "password": this.state.sfPassword,
          }
      }
      axios.post(jsonConfig.secretManagerApi,createsecretParams,{
        headers: {
          'X-API-KEY': jsonConfig.ApiKey,
        }
      }).then((response) => {
        NotificationManager.success('Sales Force credentials successfully created','Success');

        var findSf=this.state.crmSetupArray;
        var Legalindex = findSf.map(function(o) { return o.name; }).indexOf("Sales Force");               
        findSf[Legalindex]["setup"]="Complete";/**/
        this.updateSetup(this.state.channelSetupArr,this.state.aiSetupArr,findSf)

        this.getSalesforceData(this.state.defaultOrgId);
      }, (error) => {
        console.log(error);
      });
    }
  });
  }
}
updtSalesforceCreds()
{
  var docClient = new AWS.DynamoDB.DocumentClient();
  var salesforceTable = "salesForce";
  if(this.state.sfUsername==='' || this.state.sfPassword==='')
  {
    NotificationManager.error('Please enter username & password','Error');
  }
  else{
  var updatesaleforceparams = {
    TableName:salesforceTable,
    Key:{
      "organizationId": this.state.defaultOrgId
    },
    "UpdateExpression": "set accessTokenUrl = :val1,baseUrl = :val2",
    "ExpressionAttributeValues": {
      ":val1": this.state.sfAccessToken,
      ":val2": this.state.sfBaseUrl
    }
  };

  
  docClient.update(updatesaleforceparams, (err, data) => {
    if (err) {
      //console.error("Unable to update pilot table. Error JSON:", JSON.stringify(err, null, 2));
      NotificationManager.error('Unable to update sales force credentials','Error');
    } else {
      var updtsecretParams={
        "action": "updatesecret",
        "secretName": "salesforceSecret_"+this.state.defaultOrgid,
        "secretString": {
            "username": this.state.sfUsername,
            "password": this.state.sfPassword,
          }
      }
      axios.post(jsonConfig.secretManagerApi,updtsecretParams,{
        headers: {
          'X-API-KEY': jsonConfig.ApiKey,
        }
      }).then((response) => {
        NotificationManager.success('Sales force credentials successfully updated','Success');
        this.getSalesforceData(this.state.defaultOrgId);
      }, (error) => {
        console.log(error);
      });
    }
  });
  }
}
subweightageHandler=event=>{
  this.setState({subweightage:event.target.value})
}
descWeightageHandler=event=>{
  this.setState({descWeightage:event.target.value})
}
updtSentimentWeightage=()=>{
  if(this.state.subweightage==='' || this.state.descWeightage==='')
  {
    NotificationManager.error('Please fill up the fields','Error');
  }
  else{
      if(isNaN(this.state.subweightage)===false && isNaN(this.state.descWeightage)===false)
      {
          var temAdd=(parseInt(this.state.subweightage)+parseInt(this.state.descWeightage));
          
          if(temAdd!==100)
          {
            NotificationManager.error('Combined percentage must be equal to 100','Error');
          }
          else{
            var docClient = new AWS.DynamoDB.DocumentClient();
            var salesforceTable = "salesForce";
            var sentimentParams = {
              TableName:salesforceTable,
              Key:{
                "organizationId": this.state.defaultOrgId
              },
              "UpdateExpression": "set subjectSAweightage = :val1,descriptionSAweightage = :val2",
              "ExpressionAttributeValues": {
                ":val1": this.state.subweightage,
                ":val2": this.state.descWeightage
              }
            };
            docClient.update(sentimentParams, (err, data) => {
              if (err) {
                //console.error("Unable to update pilot table. Error JSON:", JSON.stringify(err, null, 2));
                NotificationManager.error('Unable to update sales force credentials','Error');
              } else {
                  NotificationManager.success('Sales force credentials successfully updated','Success');
                  this.getSalesforceData(this.state.defaultOrgId);
              }
            });
          }
      }
      else{
          NotificationManager.error('Please enter integer value','Error');
      }
  }
}
//---------ORACLE-----------//
async getOracleSettings(orgIdParam)
{
  var docClient = new AWS.DynamoDB.DocumentClient();
  var oracleTable = "oracleSrviceCloudSettings";

  var getParams = {
    TableName : oracleTable,
      FilterExpression : "organizationId = :orgName",
      ExpressionAttributeValues: {
          ":orgName": orgIdParam
      }
  };
  docClient.scan(getParams,(err, data)=> {
    if (err) {
      console.log("Error", err);
    } else {
      
      if(data.Items.length>0)
      {
          this.setState({
            orUrl:data.Items[0].oracleUrl,
            orCreateDisabled:true,
            orUpdateDisabled:false,
          })
          
          var getSecretData={
            "action": "getsecret",
            "secretName": data.Items[0].oracleuserSecret
          }
          axios.post(jsonConfig.secretManagerApi,getSecretData,{
            headers: {
              'X-API-KEY': jsonConfig.ApiKey,
            }
          }).then((response) => {
            //this.findandAddClass(this.state.availableCrm,"Sales Force","Crm")
            this.setState({
              orUsername: response.data.body.username,
              orPassword: response.data.body.password,
            });
          }, (error) => {
            console.log(error);
          });
      }
      else{
        this.setState({
          sfUsername: '',
          sfPassword: '',
          orUrl:'',
          orCreateDisabled:false,
          orUpdateDisabled:true,
        })

      }
    }
  })
}
orUsernameHandler=event=>{
  this.setState({orUsername:event.target.value})
}
orPasswordHandler=event=>{
  this.setState({orPassword:event.target.value})
}
orUrlHandler=event=>{
  this.setState({orUrl:event.target.value})
}
sendOracleCreds=()=>
{
  var docClient = new AWS.DynamoDB.DocumentClient();
  var oracleTable = "oracleSrviceCloudSettings";
  if(this.state.orUsername==='' || this.state.orPassword==='' || this.state.orUrl==='')
  {
    NotificationManager.error('Please fill up all the fields','Error');
  }
  else{
    var sfInsparams = {
      TableName:oracleTable,
      Item:{
        organizationId: this.state.defaultOrgId,
        oracleUrl: this.state.orUrl,
        oracleuserSecret:"oracleuserSecret_"+this.state.defaultOrgId
      }
    };
    docClient.put(sfInsparams, (err, data) => {
      if (err) {
        //console.error("Unable to add item salforce table. Error JSON:", JSON.stringify(err, null, 2));
        NotificationManager.error('Sales Force credentials creation failed','Error');
      } else {
        
        var createsecretParams={
          "action": "createsecret",
          "secretName": "oracleuserSecret_"+this.state.defaultOrgId,
          "secretString": {
              "username": this.state.orUsername,
              "password": this.state.orPassword,
            }
        }
        axios.post(jsonConfig.secretManagerApi,createsecretParams,{
          headers: {
            'X-API-KEY': jsonConfig.ApiKey,
          }
        }).then((response) => {
          NotificationManager.success('Oracle credentials successfully created','Success');
  
          var findOr=this.state.crmSetupArray;
          var Legalindex = findOr.map(function(o) { return o.name; }).indexOf("Oracle");               
          findOr[Legalindex]["setup"]="Complete";/**/
          this.updateSetup(this.state.channelSetupArr,this.state.aiSetupArr,findOr)
  
          this.getOracleSettings(this.state.defaultOrgId);
        }, (error) => {
          console.log(error);
        });
      }
    });
  }
}
updtOracleCreds=()=>{
  var docClient = new AWS.DynamoDB.DocumentClient();
  var oracleTable = "oracleSrviceCloudSettings";
  if(this.state.orUsername==='' || this.state.orPassword==='' || this.state.orUrl==='')
  {
    NotificationManager.error('Please fill up all the fields','Error');
  }
  else{
    var updateoracleparams = {
      TableName:oracleTable,
      Key:{
        "organizationId": this.state.defaultOrgId
      },
      "UpdateExpression": "set oracleUrl = :val1",
      "ExpressionAttributeValues": {
        ":val1": this.state.orUrl,
      }
    };
    docClient.update(updateoracleparams, (err, data) => {
      if (err) {
        console.error("Unable to update pilot table. Error JSON:", JSON.stringify(err, null, 2));
        NotificationManager.error('Unable to update oracle credentials','Error');
      } else {
        var updtsecretParams={
          "action": "updatesecret",
          "secretName": "oracleuserSecret_"+this.state.defaultOrgId,
          "secretString": {
              "username": this.state.orUsername,
              "password": this.state.orPassword,
            }
        }
        axios.post(jsonConfig.secretManagerApi,updtsecretParams,{
          headers: {
            'X-API-KEY': jsonConfig.ApiKey,
          }
        }).then((response) => {
          console.log(response);
          NotificationManager.success('Oracle credentials successfully updated','Success');
          this.getOracleSettings(this.state.defaultOrgId);
        }, (error) => {
          console.log(error);
        });
      }
    });
  }
}
//-------SMS--------------//
getSmsSettings()
{
    var settingsTable = "SMSSettings";
    var docClient = new AWS.DynamoDB.DocumentClient();
    var getParams = {
    TableName : settingsTable,
        FilterExpression : "organizationId = :orgName",
        ExpressionAttributeValues: {
            ":orgName": this.state.defaultOrgId
        }
    };
    docClient.scan(getParams,(err, data)=> {
    if (err) {
        console.log("Error", err);
    } else {
        if(data.Items.length>0)
        {
         
          this.setState({
            getAccSubid:data.Items[0].twilioAccountSID,
            getPhoneNumber:data.Items[0].twilioFrom,
            smsProvisionButtonStatus:true,
            smsProvisionStatus:false,
            alphaNumericSenderId:data.Items[0].alphaNumericSenderId,
            alphaNumericCountry:data.Items[0].alphaNumericCountry ? data.Items[0].alphaNumericCountry: this.state.userCountry,
            alphaFieldDisabled:data.Items[0].alphaNumericSenderId ? false:true,
            alphaNumericEnabled:data.Items[0].alphaNumericSenderId ? true:false
            
          })
          if(data.Items[0].phoneNumberJson===undefined)
          {
            this.setState({phoneNumberJson:[]})
          }
          else{
              this.setState({phoneNumberJson:JSON.parse(data.Items[0].phoneNumberJson)})
          }
          if(data.Items[0].autoResponse===undefined || data.Items[0].autoResponse==='' || data.Items[0].autoResponse===null || data.Items[0].autoResponse==='')
          {
            this.setState({responseSelection:'XX'})
          }
          else{
            this.setState({responseSelection:data.Items[0].autoResponse})
          }
          if(data.Items[0].autoResponseMsg===undefined || data.Items[0].autoResponseMsg==='' || data.Items[0].autoResponseMsg===null || data.Items[0].autoResponseMsg==='')
          {
            this.setState({responseMsg:''})
          }
          else{
            this.setState({responseMsg:data.Items[0].autoResponseMsg})
          }
          if(data.Items[0].antiSpamWaitTime===undefined || data.Items[0].antiSpamWaitTime==='' || data.Items[0].antiSpamWaitTime===null || data.Items[0].antiSpamWaitTime==='')
          {
            this.setState({antiSpamTimer:1})
          }
          else{
            this.setState({antiSpamTimer:data.Items[0].antiSpamWaitTime})
          }
        }
        else{
          this.setState({
            getAccSubid:'123',
            getPhoneNumber:'',
            smsProvisionButtonStatus:false,
            responseSelection:'XX',
            responseMsg:'',
            
          })
        }
    }
  })


}
gettwiloSubAccount()
{
    /*var twilioTable = "twilioSubAccount";
    var docClient = new AWS.DynamoDB.DocumentClient();
    var getParams = {
    TableName : twilioTable,
        FilterExpression : "twilioStatus = :val1",
        ExpressionAttributeValues: {
            ":val1": "Allocated"
        }
    };
    docClient.scan(getParams,(err, data)=> {
    if (err) {
        console.log("Error", err);
    } else {
      console.log("DATAITEMS",data.Items);
      if(data.Items.length>0)
      {
          this.setState({
            twilioAccSid:data.Items[0].accountSid,
            twilioAccName:data.Items[0].subAccountName,
            phoneNumber:data.Items[0].phoneNumber,
            twilioAuthToken:data.Items[0].authToken,
            twilioMsgServiceId:data.Items[0].messageServiceId,
            twilioSubAccName:data.Items[0].subAccountName,
          })
      }
      else{
        this.setState({
          twilioAccSid:'',
          twilioAccName:'',
          phoneNumber:'',
          twilioAuthToken:'',
          twilioMsgServiceId:'',
          twilioSubAccName:'',
        })
      }
    }
  })*/
  var twilioTable = "twilioSubAccount";
    var docClient = new AWS.DynamoDB.DocumentClient();
    var getParams = {
    TableName : twilioTable,
        FilterExpression : "organizationId = :val1",
        ExpressionAttributeValues: {
            ":val1": this.state.defaultOrgId
        }
    };
    docClient.scan(getParams,(err, data)=> {
    if (err) {
        console.log("Error", err);
    } else {
      console.log("DATAITEMS",data.Items);
      if(data.Items.length>0)
      {
          this.setState({
            twilioAccSid:data.Items[0].accountSid,
            twilioAccName:data.Items[0].subAccountName,
            phoneNumber:data.Items[0].phoneNumber,
            twilioAuthToken:data.Items[0].authToken,
            twilioMsgServiceId:data.Items[0].messageServiceId,
            twilioSubAccName:data.Items[0].subAccountName,
            listOfPhone:JSON.parse(data.Items[0].countryCodeJson),
          })
      }
      else{
        this.setState({
          twilioAccSid:'',
          twilioAccName:'',
          phoneNumber:'',
          twilioAuthToken:'',
          twilioMsgServiceId:'',
          twilioSubAccName:'',
          listOfPhone:[]
        })
      }
    }
  })
}


saveTwilioSubAccount(subAccountParams){
  // this function saves subaccount details in dynamodb

  /**/var twilioSubAccountTable = "twilioSubAccount";
  var docClient = new AWS.DynamoDB.DocumentClient();

  let countryCode={

  }
  
  countryCode[subAccountParams["countryCode"]] = subAccountParams['twilioFrom']

  var insertSubAccountParams = {
    TableName:twilioSubAccountTable,
    Item:{
        
        accountSid: subAccountParams['twilioAccountSID'],
        authToken:subAccountParams['twilioAuthToken'],
        countryCodeJson:JSON.stringify( [countryCode]),
        messageServiceId:subAccountParams['twilioMessageServiceSID'],
        organizationId:subAccountParams['organizationId'],
        organizationName:subAccountParams['twilioSubAccountName'],
        phoneNumber:subAccountParams['twilioFrom'],
        subAccountName:subAccountParams['twilioSubAccountName'],
        twilioStatus:"Allocated",
        
    }
    };


    
    docClient.put(insertSubAccountParams, (err, data) => {
    if (err) {
        console.error("Unable to add item Client chat table. Error JSON:", JSON.stringify(err, null, 2));
    } else {
        console.log("twilio subaccount created successfully")
      }
    });
  

}


deleteTwilioSubAccount(){
// delete twilio subaccount details from dynamodb

var docClient = new AWS.DynamoDB.DocumentClient();
var twilioSubAccountTable = "twilioSubAccount";
  var deleteSmsParams = {
  TableName:twilioSubAccountTable,
  Key:{
      "accountSid":this.state.getAccSubid,
    }
  };

  console.log(deleteSmsParams,"deleteSmsParams")
  docClient.delete(deleteSmsParams, (err, data) => {
  if (err) {
      console.error("Unable to add item Client chat table. Error JSON:", JSON.stringify(err, null, 2));
      // NotificationManager.error('Twilio account deprovisioned failed','Error');
  } else {
    console.log("deleted twilio subaccount details from dynamodb")
  }


})

}


provisionTwilio(){
  
  this.setState({smsProvisionStatus:true})
  let provisionData = {
    "environment": jsonConfig.environment,
    "action": "provisionSMS",
    "organizationId": this.state.defaultOrgId
    }
  axios.post(jsonConfig.smsProvisionApi, provisionData,{
    headers: {
      'X-API-KEY': jsonConfig.ApiKey,
    }
  })
  .then((response) => {
    console.log(response,"Response");
    if(response.data.statusCode===200)
    {
      /**/var smsSettingsTable = "SMSSettings";
      var docClient = new AWS.DynamoDB.DocumentClient();

      var insertSmsParams = {
        TableName:smsSettingsTable,
        Item:{
            organizationId:this.state.defaultOrgId,
            twilioAccountSID: response.data.body.account_sid,
            twilioFrom: response.data.body.phone_number,
            twilioAuthToken:response.data.body.subAccountAuthToken,
            twilioMessageServiceSID:response.data.body.service_sid,
            twilioSubAccountName:this.state.defaultOrgName,
            twilioPhoneNumberSID:response.data.body.sid,
            countryCode:response.data.body.country_code,
            smsUrl:response.data.body.url,
            twilioAddressSID:response.data.body.addressSID
            
        }
        };
        
        docClient.put(insertSmsParams, (err, data) => {
        if (err) {
            console.error("Unable to add item Client chat table. Error JSON:", JSON.stringify(err, null, 2));
            NotificationManager.error('Sms provisioned failed','Error');
        } else {


            this.saveTwilioSubAccount(insertSmsParams["Item"])


            NotificationManager.success('Sms successfully provisioned','Success');

            var findCC=this.state.channelSetupArr;
            var Legalindex = findCC.map(function(o) { return o.name; }).indexOf("Sms");               
            findCC[Legalindex]["setup"]="Complete";
            this.updateSetup(findCC,this.state.aiSetupArr,this.state.crmSetupArray);

            this.getSmsSettings();
            this.setState({smsProvisionButtonStatus:true})
          }
        });
    }
    else{
      NotificationManager.error('Sms provisioned failed','Error');
      this.setState({
        smsProvisionStatus:false,
        smsProvisionButtonStatus:false
      })
    }
    
  }, (error) => {
    console.log(error);
  });
  
  
}
deallocateTwilio()
{
  let provisionData = {
    "action": "deprovisionSMS",
    "environment": jsonConfig.environment,
    "organizationId": this.state.defaultOrgId,
    }
  axios.post(jsonConfig.smsProvisionApi, provisionData,{
    headers: {
      'X-API-KEY': jsonConfig.ApiKey,
    }
  })
  .then((response) => {
    console.log(response.data);
    if(response.data.statusCode===200 || response.data.statusCode===204)
    {
        this.setState({smsProvisionStatus:false})
        var docClient = new AWS.DynamoDB.DocumentClient();
        var smsSettingsTable = "SMSSettings";
          var deleteSmsParams = {
          TableName:smsSettingsTable,
          Key:{
              "organizationId":this.state.defaultOrgId,
            }
          };
          docClient.delete(deleteSmsParams, (err, data) => {
          if (err) {
              console.error("Unable to add item Client chat table. Error JSON:", JSON.stringify(err, null, 2));
              NotificationManager.error('Twilio account deprovisioned failed','Error');
          } else {

              this.deleteTwilioSubAccount()


              NotificationManager.success('Twilio account deprovisioned successfull','Success');
              var findCC=this.state.channelSetupArr;
              var Legalindex = findCC.map(function(o) { return o.name; }).indexOf("Sms");               
              findCC[Legalindex]["setup"]="inComplete";/**/
              this.updateSetup(findCC,this.state.aiSetupArr,this.state.crmSetupArray);
              this.getSmsSettings(); 
            }
          });
      
    }
    else{
      NotificationManager.error('Sms account deprovisioned failed','Error');
      this.setState({
        smsProvisionStatus:false,
        smsProvisionButtonStatus:false
      })
    }
    
  }, (error) => {
    console.log(error);
  });
}
responseHandler=event=>
{
  console.log(event.target.value);
  if(event.target.value==='NO')
  {
    this.setState({autoResponseStatus:true})
  }
  else{
    this.setState({autoResponseStatus:false})
  }
  this.setState({responseSelection:event.target.value})
}
responseMsgHandler=event=>
{
  this.setState({responseMsg:event.target.value})
}
antiSpamTimerHandler=event=>
{
  this.setState({antiSpamTimer:event.target.value})
}
updateResponse=()=>
{
  if(this.state.responseSelection===undefined || this.state.responseSelection===null || this.state.responseSelection==='')
  {
    NotificationManager.error('Please select auto response','Error');
  }
  else{
    if(this.state.antiSpamTimer===1 || this.state.antiSpamTimer==='' || this.state.antiSpamTimer===null || this.state.antiSpamTimer===undefined)
    {
      var updateSpamTimer=1
    }
    else{
      updateSpamTimer=this.state.antiSpamTimer
    }
    
    var smsTable = "SMSSettings";
    var docClient = new AWS.DynamoDB.DocumentClient();

    var params = {
      TableName:smsTable,
      Key:{
        "organizationId": this.state.defaultOrgId
      },
      "UpdateExpression": "set autoResponse = :val1,autoResponseMsg = :val2,antiSpamWaitTime = :val3,alphaNumericCountry = :val5",
      "ExpressionAttributeValues": {
        ":val1":this.state.responseSelection,
        ":val2":this.state.responseMsg,
        ":val3":parseInt(updateSpamTimer),
        // ":val4":this.state.alphaNumericSenderId,
        ":val5":this.state.alphaNumericCountry

      }
    };
    docClient.update(params, (err, data) => {
      if (err) {
        console.error("Unable to update sms table. Error JSON:", JSON.stringify(err, null, 2));
      } else {
        console.log("Update sms table");
        NotificationManager.success('Auto response successfully set','Success');
        // this.getSmsSettings();
      }
    });
  }


//////////////////////////////

if (countryListJson[this.state.alphaNumericCountry] !="No"){

  let status = "pending"

  if (countryListJson[this.state.alphaNumericCountry] == "Yes"){
    status = "allowed"
  }

  console.log("alphaNumericEnabled",this.state.alphaNumericEnabled)



  let action = "setAlphaNumeric"

  if (this.state.alphaNumericEnabled){

    if (status == "pending") {    // if new country requires manual reg, remove old one then add new one
      action = "removeAndSetAlphaNumeric"
    }

  }
  else {
    action = "removeAlphaNumeric" // if alpha is not enabled, remove old one
  }




  if ((this.state.alphaNumericSenderId[0] >0 && this.state.alphaNumericSenderId[0]<9) || this.state.alphaNumericSenderId[0]==" "){
    NotificationManager.error("Alpha Sender is not valid")
    return
  }




  let sampleData = {
    "environment": jsonConfig.environment,
    "action": action,
    "alphaNumericSenderId":this.state.alphaNumericSenderId,
    "organizationId": this.state.defaultOrgId,
    "organizationName":this.state.defaultOrgName,
    "userEmail":this.state.defaultEmail,
    "userName":this.state.defaultUserName,
    "alphaNumericStatus":status,
    "userCountry" : this.state.alphaNumericCountry
    }
  axios.post(jsonConfig.smsProvisionApi, sampleData,{
    headers: {
      'X-API-KEY': jsonConfig.ApiKey,
    }
  })
  .then((response) => {

// save the sid of the alpha numeric id, we need this sid to delete alpahnumeric from messaging service

try{


  console.log(response.data.body,"API Response");  

  let alphaNumericSID
  try{


  if (response.data.body.sid){
        alphaNumericSID = response.data.body.sid
  }
  else {
        if (sampleData['action'] == 'removeAlphaNumeric'){
          alphaNumericSID  = "None"
      }
    
  }

  }
  catch(err){


      if (sampleData['action'] == 'removeAlphaNumeric'){
        alphaNumericSID  = "None"
      }
    

  }
    


  if (alphaNumericSID || status == "pending"){ // update dynamodb, if alphanumeric is set succesfully or remove alphanumeric or if manual reg needed for alpha


    if(status == "pending"){
      alphaNumericSID = ""
    }

    var smsTable = "SMSSettings";
    var docClient = new AWS.DynamoDB.DocumentClient();
  
    var params = {
      TableName:smsTable,
      Key:{
        "organizationId": this.state.defaultOrgId
      },
      "UpdateExpression": "set alphaNumericSID = :val1,alphaNumericSenderId = :val2",
      "ExpressionAttributeValues": {
        ":val1":alphaNumericSID,
        ":val2":sampleData['alphaNumericSenderId']
      }
    };
    docClient.update(params, (err, data) => {
      if (err) {
        console.error("Unable to update sms table. Error JSON:", JSON.stringify(err, null, 2));
        
  
  
      } else {
        console.log("Update sms table2");

        if (alphaNumericSID=="None"){
              NotificationManager.success("AlphaNumeric removed successfully")
        }
        if (alphaNumericSID && alphaNumericSID !="None" && status=="allowed"){
              NotificationManager.success("AlphaNumeric added successfully")
        }
        // this.getSmsSettings();
      }
    });


  }

  else {

    if (response.data.body.code == 21709){
      NotificationManager.error("Alpha Sender is not valid")
    }
    if (response.data.body.code==21714) {
      NotificationManager.warning('Only one alphanumeric id allowed for a messaging service')
    }
    
  }
  



}
catch(err){
  console.log(err)
  // NotificationManager.warning('Only one alphanumeric id allowed for a messaging service');
}



  }, (error) => {
    console.log(error);
  });


}



///////////////////////////
  
}
/*updateSmsSettings()
{
if(this.state.accountSid==='' || this.state.authToken==='' || this.state.twilioFrom==='')
{
  NotificationManager.error('Please fill up all the fields','Error');
}
else{
  var smsTable = "SMSSettings";
  var docClient = new AWS.DynamoDB.DocumentClient();
  var insertSmsParams = {
  TableName:smsTable,
  Item:{
      organizationId:this.state.defaultOrgName,
      twilioAccountSID: this.state.accountSid,
      twilioAuthToken: this.state.authToken,
      twilioFrom: this.state.twilioFrom,
  }
  };
  docClient.put(insertSmsParams, (err, data) => {
  if (err) {
      //console.error("Unable to add item Client chat table. Error JSON:", JSON.stringify(err, null, 2));
      NotificationManager.error('SMS settings updation','Error');
  } else {
      NotificationManager.success('SMS settings successfully updated','Success');
      this.getSmsSettings();
  }
  });
}

} */
getPhoneList=()=>{
    var twillioTable = "twilioSubAccount";
    var docClient = new AWS.DynamoDB.DocumentClient();
      var params = {
        TableName : twillioTable,
        FilterExpression : "organizationId = :val1",
        ExpressionAttributeValues: {
            ":val1": this.state.defaultOrgId
        }
    };
    docClient.scan(params, (err, data)=> {
        if (err) {
            console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
        } else {
          if(data.Items.length>0)
          {
            this.setState({
              getPhoneNumberList:JSON.parse(data.Items[0].countryCodeJson),
            })
          }
          else{
            this.setState({
              getPhoneNumberList:[]
            })
          }
          
        }
    });
}
getSmsPosition=()=>
{
  var findSms=this.state.activeInteractions;
  var Legalindex = findSms.map(function(o) { return o.name; }).indexOf("Sms");
  if(Legalindex===0)
  {
    findSms[Legalindex]["typeOfSms"]="Outgoing";
    this.getPhoneList();
  }
  else{
    findSms[Legalindex]["typeOfSms"]="Incoming";
  }
  this.setState({
    activeInteractions:findSms,
    smsPosition:Legalindex
  })
}
updateSmsTypeinArray=()=>
{
      var findSms=this.state.activeInteractions;
      var Legalindex = findSms.map(function(o) { return o.name; }).indexOf("Sms");
      //findSms[Legalindex]["phoneForSms"]=[];
      //findSms[Legalindex]["phoneForSms"].push(this.state.phoneForSms);
      
        if(findSms[Legalindex]["phoneForSms"]===undefined)
        {
          findSms[Legalindex]["phoneForSms"]=[];
        }
        
        findSms[Legalindex]["phoneForSms"].push(this.state.phoneForSms);
        
      if(this.state.boardId===undefined || this.state.boardId===null || this.state.boardId==='')
      {
        var boardid=Math.floor((Math.random()*1000000000000000000)+1);
        this.setState({incomingBoardId:boardid});
      }
      else{
        boardid=this.state.boardId;
        this.setState({incomingBoardId:''});
      }
            
      var docClient = new AWS.DynamoDB.DocumentClient();
      var entryPointTable='entryPoint'
      var oracleParams = {
        TableName:entryPointTable,
        Item:{
          entryPointIdentifier: 'Twilio-'+this.state.phoneForSms,
          EntryPointIdentifierService:'SMS',
          interactionId: boardid.toString(),
          incomingNumber:this.state.phoneForSms,
          EntryPointIdentifierValue:this.state.phoneForSms
        }
        
      };
      docClient.put(oracleParams, (err, data) => {
        if (err) {
          console.error("Unable to add item oracle table. Error JSON:", JSON.stringify(err, null, 2));
          //NotificationManager.error('Failed to add page','Error');
        } else {
          console.log("Added oracle with organization item:", JSON.stringify(data, null, 2));
          //NotificationManager.success('Page successfully added','Success');
        }
      });
          
    console.log("CHECK PHONE",findSms);
    NotificationManager.success('SMS settings successfully updated','Success');
    this.setState({activeInteractions:findSms})
        
        
        
      

}
SmsNumberChange=event=>{
  this.setState({
    phoneForSms:event.target.value,
  })
}
getAddedPhoneNumber()
{
  var entryPoint = "entryPoint";
  var docClient = new AWS.DynamoDB.DocumentClient();
  var getIntFIlterparams = {
    TableName : entryPoint,
    FilterExpression : "interactionId = :val1 AND EntryPointIdentifierService = :val2",
    ExpressionAttributeValues: {
        ":val1": this.state.boardId,
        ":val2": 'SMS',
    }
  };

  

docClient.scan(getIntFIlterparams, (err, data)=> {
  if (err) {
      console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
  } else {
    console.log("GET PHONE NUMBER",data.Items);
    if(data.Items.length>0)
    {
      this.setState({addedPhoneNumber:data.Items})
    }
    else{
      this.setState({addedPhoneNumber:[]})
    }
    
  }
  });
}
//-----------------------------//
//------VIDEO ROOM-------------//
getVideoroomSettings()
{
  axios.get(jsonConfig.rdsApi+"videoRoomSettings/"+this.state.defaultOrgId,{
    headers: {
      'X-API-KEY': jsonConfig.ApiKey,
      'Content-Type': 'application/json'
    }
    })
    .then((response) => {
      console.log("RESP",response);
      this.setState({
        vdoSurround:response.data.data[0].videoRoomColor,
        privacyText:response.data.data[0].privacyAgreementWindow,
        getVideoRoomUrl:response.data.data[0].url,
        videoroomButtonstatus:false

      })
      if(response.data.data[0].privacyAgreementWindow!=='')
      {
        this.setState({changeVdoroomPrivacy:true})
      }
      if(response.data.data[0].saveVideo===1)
      {
        this.setState({changeSaveVideo:true})
      }
      /*"organizationId": this.state.defaultOrgId,
      "videoRoomColor": this.state.,
      "saveVideo": this.state.changeSaveVideo,
      "": tempPrivacy,
      "url": "",
      "createdBy": this.state.defaultCognitoId,
      "updatedBy": this.state.defaultCognitoId*/
       
    }, (error) => {
    
    console.log(error);
    });
  
}
changeVdoroomTimerHandler=event=>
{
  this.setState({changeVdoroomTimer:event.target.checked})
  //console.log(event.target.checked);
}
changeVdoroomPrivacyHandler=event=>
{
  this.setState({changeVdoroomPrivacy:event.target.checked})
}
changeSaveVdoHandler=event=>
{
  this.setState({changeSaveVideo:event.target.checked})
}
vdoSurroundHandler=event=>
{
  this.setState({vdoSurround:event.target.value})
}
videoroomHandler=event=>
{
  this.setState({videoroomName:event.target.value})
}
privacyTextHandler=event=>
{
  this.setState({privacyText:event.target.value})
}
videoroomTimerHandler=event=>
{
  this.setState({videoroomTimer:event.target.value})
}
addVideoroomSettings=()=>
{
  var tempPrivacy='';
  var tempTimer=1;
  if(this.state.changeVdoroomTimer===false)
  {
    tempTimer=1;
  }
  else{
    tempTimer=this.state.videoroomTimer
  }
  if(this.state.changeVdoroomPrivacy===false)
  {
    tempPrivacy='';
  }
  else{
    tempPrivacy=this.state.privacyText
  }
      var dataCreate={
      "organizationId": this.state.defaultOrgId,
      "videoRoomColor": this.state.vdoSurround,
      "saveVideo": this.state.changeSaveVideo,
      "privacyAgreementWindow": tempPrivacy,
      "url": "",
      "createdBy": this.state.defaultCognitoId,
      "updatedBy": this.state.defaultCognitoId
    }

    axios.post(jsonConfig.rdsApi+"videoRoomSettings", dataCreate,{
    headers: {
      'X-API-KEY': jsonConfig.ApiKey,
      'Content-Type': 'application/json'
    }
    })
    .then((response) => {
        NotificationManager.success('Video room settings updated successfully','Success');
        this.getVideoroomSettings();
    }, (error) => {
    NotificationManager.error('Video room settings updation failed','Error');
    console.log(error);
    });
}
updateVideoroomSettings=()=>
{
  var tempPrivacy='';
  var tempTimer=1;
  if(this.state.changeVdoroomTimer===false)
  {
    tempTimer=1;
  }
  else{
    tempTimer=this.state.videoroomTimer
  }
  if(this.state.changeVdoroomPrivacy===false)
  {
    tempPrivacy='';
  }
  else{
    tempPrivacy=this.state.privacyText
  }
      var dataCreate={
      "videoRoomColor": this.state.vdoSurround,
      "saveVideo": this.state.changeSaveVideo,
      "privacyAgreementWindow": tempPrivacy,
      "url": "",
      "updatedBy": this.state.defaultCognitoId
    }

    axios.put(jsonConfig.rdsApi+"videoRoomSettings/"+this.state.defaultOrgId, dataCreate,{
    headers: {
      'X-API-KEY': jsonConfig.ApiKey,
      'Content-Type': 'application/json'
    }
    })
    .then((response) => {
        NotificationManager.success('Organization successfully updated','Success');
        this.getVideoroomSettings();
    }, (error) => {
    NotificationManager.error('Organization updation failed','Error');
    console.log(error);
    });
}
createRoom=()=>
{
  console.log("GET VDO ROOM URL",this.state.getVideoRoomUrl);
  if(this.state.videoroomName==='' || this.state.getVideoRoomUrl==='')
  {
    NotificationManager.error('Please enter room name or update video room settings','Error');
  }
  else{
    var v1=this.state.getVideoRoomUrl+"/room/"+this.state.videoroomName
  var roomParam={
    "roomName": this.state.videoroomName,
    "url": this.state.getVideoRoomUrl
  }
  axios.post(jsonConfig.createroomApi,roomParam,{
    headers: {
      'X-API-KEY': jsonConfig.ApiKey,
      'Content-Type': 'application/json'
    }
  }).then((response) => {
      console.log("GET ROOM NAME",response.data.url);
      this.setState({videoroomUrl:v1})
      NotificationManager.success('Videoroom successfully created','Success');
    
  }, (error) => {
    console.log(error);
  });
}
}
//----------------------------//
async removeInteraction() {
	var temp = this.state.activeInteractions;
  var lastIndex = temp.length - 1;
  var lastKeyIndex=lastIndex-1;
      temp.splice(lastIndex, 1);
      if(lastKeyIndex!==-1)
      {
        temp[lastKeyIndex].next="";
      }
      
      console.log(temp);
      this.setState({
        activeInteractions: temp
      });
}
boardNameHandler = event => {
  this.setState({
    boardName: event.target.value
  });
}

saveBoard()
  {


    
    var findOracle=this.state.activeInteractions;
    var Legalindex = findOracle.map(function(o) { return o.name; }).indexOf("Oracle");

    var findSms=this.state.activeInteractions;
    var indexSms = findSms.map(function(o) { return o.name; }).indexOf("Sms");

    
    if(this.state.incomingBoardId==='' || this.state.incomingBoardId===null || this.state.incomingBoardId===undefined)
    {
      var boardid=Math.floor((Math.random()*1000000000000000000)+1);
    }
    else{
      boardid=this.state.incomingBoardId;
    }
    if(boardNameTemp==='' || boardNameTemp===null)
    {
      NotificationManager.warning('Please enter board name','Warning');
    }
    else{
        if(indexSms==-1)
        {
            var cognitoid = this.state.defaultCognitoId;
            var saveBoardInteraction = this.state.activeInteractions;
            var boardNameTemp=this.state.boardName;
            var docClient = new AWS.DynamoDB.DocumentClient();
            var interactionBoardTable = "interactions";
            var entryPointTable='entryPoint'
            var saveParams = {
              TableName:interactionBoardTable,
              Item:{
                interactionId:boardid.toString(),
                cognitoid: cognitoid,
                interactionName:boardNameTemp,
                boardStatus:'Active',
                json: btoa(JSON.stringify(saveBoardInteraction)),
                createdAt:new Date().toString(),
                updatedAt:new Date().toString(),
                organizationName:this.state.defaultOrgName,
                organizationId:this.state.defaultOrgId,
                organizationRole:this.state.defaultRoleName,
                createdBy:this.state.defaultUserName,
                updatedBy:this.state.defaultUserName
              }
            };
            docClient.put(saveParams, (err, data) => {
              if (err) {
                console.error("Unable to add board. Error JSON:", JSON.stringify(err, null, 2));
              } else {
                if(Legalindex==0)
                {
                  var oracleParams = {
                    TableName:entryPointTable,
                    Item:{
                      entryPointIdentifier: 'Oracle-'+this.state.defaultOrgId,
                      EntryPointIdentifierService:'Oracle',
                      interactionId: boardid.toString(),
                    }
                    
                  };
                  docClient.put(oracleParams, (err, data) => {
                    if (err) {
                      console.error("Unable to add item oracle table. Error JSON:", JSON.stringify(err, null, 2));
                      
                    } else {
                      console.log("Added oracle with organization item:", JSON.stringify(data, null, 2));
                      
                    }
                  });
                }
                NotificationManager.success('Interaction added successfully','Success');
                this.getBoardData(this.state.defaultOrgId);
                
                this.setState({
                  saveButtonStatus:true,
                  /*activeInteractions:[],
                  boardId:'',
                  boardName:'',
                  activeIndex:-1,*/
                  saveUpdtMsg:'saved'
                })
              }
            });
        }
        else if(indexSms>0){
            findSms[indexSms]["typeOfSms"]="Incoming";

            
            
            var cognitoid = this.state.defaultCognitoId;
            var saveBoardInteraction = findSms;

            saveBoardInteraction[indexSms]["prefix"]=this.state.messagePrefix
            saveBoardInteraction[indexSms]["suffix"]=this.state.messageSuffix
            saveBoardInteraction[indexSms]["communicationType"]=this.state.communicationType

            
            var boardNameTemp=this.state.boardName;
            var docClient = new AWS.DynamoDB.DocumentClient();
            var interactionBoardTable = "interactions";
            var entryPointTable='entryPoint'
            var saveParams = {
              TableName:interactionBoardTable,
              Item:{
                interactionId:boardid.toString(),
                cognitoid: cognitoid,
                interactionName:boardNameTemp,
                boardStatus:'Active',
                json: btoa(JSON.stringify(saveBoardInteraction)),
                createdAt:new Date().toString(),
                updatedAt:new Date().toString(),
                organizationName:this.state.defaultOrgName,
                organizationId:this.state.defaultOrgId,
                organizationRole:this.state.defaultRoleName,
                createdBy:this.state.defaultUserName,
                updatedBy:this.state.defaultUserName
              }
            };
            docClient.put(saveParams, (err, data) => {
              if (err) {
                console.error("Unable to add board. Error JSON:", JSON.stringify(err, null, 2));
              } else {
                if(Legalindex==0)
                {
                  var oracleParams = {
                    TableName:entryPointTable,
                    Item:{
                      entryPointIdentifier: 'Oracle-'+this.state.defaultOrgId,
                      EntryPointIdentifierService:'Oracle',
                      interactionId: boardid.toString(),
                    }
                    
                  };
                  docClient.put(oracleParams, (err, data) => {
                    if (err) {
                      console.error("Unable to add item oracle table. Error JSON:", JSON.stringify(err, null, 2));
                      
                    } else {
                      console.log("Added oracle with organization item:", JSON.stringify(data, null, 2));
                      
                    }
                  });
                }
                NotificationManager.success('Interaction added successfully','Success');
                this.getBoardData(this.state.defaultOrgId);
                
                this.setState({
                  saveButtonStatus:true,
                  saveUpdtMsg:'saved',
                  activeInteractions:findSms
                })
              }
            });
          
        }
        else{
          if(findSms[indexSms]["typeOfSms"]==="Outgoing")
          {
            var cognitoid = this.state.defaultCognitoId;
            var saveBoardInteraction = findSms;

            saveBoardInteraction[indexSms]["prefix"]=""
            saveBoardInteraction[indexSms]["suffix"]=""
            saveBoardInteraction[indexSms]["communicationType"]="Inbound_Only"

            var boardNameTemp=this.state.boardName;
            var docClient = new AWS.DynamoDB.DocumentClient();
            var interactionBoardTable = "interactions";
            var entryPointTable='entryPoint'
            var saveParams = {
              TableName:interactionBoardTable,
              Item:{
                interactionId:boardid.toString(),
                cognitoid: cognitoid,
                interactionName:boardNameTemp,
                boardStatus:'Active',
                json: btoa(JSON.stringify(saveBoardInteraction)),
                createdAt:new Date().toString(),
                updatedAt:new Date().toString(),
                organizationName:this.state.defaultOrgName,
                organizationId:this.state.defaultOrgId,
                organizationRole:this.state.defaultRoleName,
                createdBy:this.state.defaultUserName,
                updatedBy:this.state.defaultUserName
              }
            };
            docClient.put(saveParams, (err, data) => {
              if (err) {
                console.error("Unable to add board. Error JSON:", JSON.stringify(err, null, 2));
              } else {
                
                NotificationManager.success('Interaction added successfully','Success');
                this.getBoardData(this.state.defaultOrgId);
                
                this.setState({
                  saveButtonStatus:true,
                  saveUpdtMsg:'saved',
                  
                })
              }
            });
          }
          else{
            NotificationManager.error('Please update sms settings first','Error');
          }
          
        }
        
    }
}
updateBoard()
  {
    this.getFaq(this.state.boardId);
    if(this.state.createQuestionJson==='' || this.state.createQuestionJson===null || this.state.createQuestionJson===undefined)
      {
        var getFaqList= {
          "FAQ": []
        };
      }
    else{
      getFaqList=JSON.parse(this.state.createQuestionJson);
    }
    getFaqList["input"]=[];
    getFaqList["output"]=[];
    var findAll=this.state.activeInteractions;
    var SearchQueryBotIndex = findAll.map(function(o) { return o.name; }).indexOf("Lambda Faq");
    var SearchSalesforceIndex=findAll.map(function(o) { return o.name; }).indexOf("Sales Force");
    var SearchZendeskIndex=findAll.map(function(o) { return o.name; }).indexOf("Zendesk");
    var SearchChatIndex=findAll.map(function(o) { return o.name; }).indexOf("Chat");
    var SearchOracleIndex=findAll.map(function(o) { return o.name; }).indexOf("Oracle");

    if(SearchQueryBotIndex>-1 && SearchZendeskIndex>-1)
    {
      console.log("ZN");
      for(var j=0;j<this.state.zendeskInput.length;j++)
      {
        getFaqList["input"].push(this.state.zendeskInput[j]);
        getFaqList["output"].push(this.state.zendeskOutput[j]);
      }
      
    }
    if(SearchQueryBotIndex>-1 && SearchSalesforceIndex>-1)
    {
      console.log("SF");
      for(var j=0;j<this.state.salesforceInput.length;j++)
      {
        getFaqList["input"].push(this.state.salesforceInput[j]);
        getFaqList["output"].push(this.state.salesforceOutput[j]);
      }
      
    }
    if(SearchQueryBotIndex>-1 && SearchChatIndex>-1)
    {
      console.log("CH");
      for(var j=0;j<this.state.blueleapchatInput.length;j++)
      {
        getFaqList["input"].push(this.state.blueleapchatInput[j]);
        getFaqList["output"].push(this.state.blueleapchatOutput[j]);
      }
      
    }
    if(SearchQueryBotIndex>-1 && SearchOracleIndex>-1)
    {
      console.log("OR");
      for(var j=0;j<this.state.oracleInput.length;j++)
      {
        getFaqList["input"].push(this.state.oracleInput[j]);
        getFaqList["output"].push(this.state.oracleOutput[j]);
      }
      
    }
    console.log("getFaqList",getFaqList);
    /**/var tempCreateNewArr=getFaqList;
    var docClient = new AWS.DynamoDB.DocumentClient();
    var lambdafaqTable = "FAQLambda";
    var updatePilotparams = {
      TableName:lambdafaqTable,
      Key:{
        "interactionId": this.state.boardId.toString()
      },
      "UpdateExpression": "set json = :faqJson",
      "ExpressionAttributeValues": {
        ":faqJson": btoa(JSON.stringify(tempCreateNewArr))
      }
    };
    
    
    docClient.update(updatePilotparams, (err, data) => {
      if (err) {
        console.error("Unable to update faq table. Error JSON:", JSON.stringify(err, null, 2));
      } else {
        this.getFaq(this.state.boardId);
      }
    });
    //console.log("BOARD DATA",this.state.activeInteractions);
    var findOracle=this.state.activeInteractions;
    var Legalindex = findOracle.map(function(o) { return o.name; }).indexOf("Oracle");


    var findSms=this.state.activeInteractions;
    var indexSms = findSms.map(function(o) { return o.name; }).indexOf("Sms");

    
    if(this.state.boardName==='')
    {
      NotificationManager.error('Please enter board name','Error');
    }
    else{
    if(indexSms==-1)
    {
      var updateBoardInteraction = this.state.activeInteractions;
      var docClient = new AWS.DynamoDB.DocumentClient();
      var interactionTable='interactions';
      var entryPointTable='entryPoint';
      var params = {
        TableName:interactionTable,
        Key:{
          "interactionId": this.state.boardId
        },
        "UpdateExpression": "set interactionName = :val1,json = :boardData,updatedAt = :updatedate,updatedBy = :updatedby",
        "ExpressionAttributeValues": {
          ":val1":this.state.boardName,
          ":boardData":btoa(JSON.stringify(updateBoardInteraction)),
          ":updatedate":new Date().toString(),
          ":updatedby":this.state.defaultUserName
        }
      };
      docClient.update(params, (err, data) => {
        if (err) {
            console.error("Unable to update board. Error JSON:", JSON.stringify(err, null, 2));
            NotificationManager.error('Interaction updation failed','Error');
        } else {
          var timediff=new Date();
          this.dateDifference(timediff);
          var updtdt=new Date().toLocaleTimeString([], {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit'});
          this.setState({
            updateDate:updtdt,
            updatedByUser:this.state.defaultUserName,
            saveUpdtMsg:'updated'
          });
          if(Legalindex==0)
          {
            var oracleParams = {
              TableName:entryPointTable,
              Item:{
                entryPointIdentifier: 'Oracle-'+this.state.defaultOrgId,
                EntryPointIdentifierService:'Oracle',
                interactionId: this.state.boardId,
              }
              
            };
            docClient.put(oracleParams, (err, data) => {
              if (err) {
                console.error("Unable to add item oracle table. Error JSON:", JSON.stringify(err, null, 2));
                //NotificationManager.error('Failed to add page','Error');
              } else {
                console.log("Added oracle with organization item:", JSON.stringify(data, null, 2));
                //NotificationManager.success('Page successfully added','Success');
              }
            });
          }
          this.getResources(this.state.defaultOrgId);
          NotificationManager.success('Interaction updated successfully','Success');
          this.getBoardData(this.state.defaultOrgId);
        }
        
      });
    }
    else if(indexSms>0)
    {
      findSms[indexSms]["typeOfSms"]="Incoming";
      var updateBoardInteraction = findSms;

      updateBoardInteraction[indexSms]["prefix"]=this.state.messagePrefix
      updateBoardInteraction[indexSms]["suffix"]=this.state.messageSuffix
      updateBoardInteraction[indexSms]["communicationType"]=this.state.communicationType

      var docClient = new AWS.DynamoDB.DocumentClient();
      var interactionTable='interactions';
      var entryPointTable='entryPoint';
      var params = {
        TableName:interactionTable,
        Key:{
          "interactionId": this.state.boardId
        },
        "UpdateExpression": "set interactionName = :val1,json = :boardData,updatedAt = :updatedate,updatedBy = :updatedby",
        "ExpressionAttributeValues": {
          ":val1":this.state.boardName,
          ":boardData":btoa(JSON.stringify(updateBoardInteraction)),
          ":updatedate":new Date().toString(),
          ":updatedby":this.state.defaultUserName
        }
      };
      docClient.update(params, (err, data) => {
        if (err) {
            console.error("Unable to update board. Error JSON:", JSON.stringify(err, null, 2));
            NotificationManager.error('Interaction updation failed','Error');
        } else {
          var timediff=new Date();
          this.dateDifference(timediff);
          var updtdt=new Date().toLocaleTimeString([], {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit'});
          this.setState({
            updateDate:updtdt,
            updatedByUser:this.state.defaultUserName,
            saveUpdtMsg:'updated',
            activeInteractions:findSms
          });
          if(Legalindex==0)
          {
            var oracleParams = {
              TableName:entryPointTable,
              Item:{
                entryPointIdentifier: 'Oracle-'+this.state.defaultOrgId,
                EntryPointIdentifierService:'Oracle',
                interactionId: this.state.boardId,
              }
              
            };
            docClient.put(oracleParams, (err, data) => {
              if (err) {
                console.error("Unable to add item oracle table. Error JSON:", JSON.stringify(err, null, 2));
                //NotificationManager.error('Failed to add page','Error');
              } else {
                console.log("Added oracle with organization item:", JSON.stringify(data, null, 2));
                //NotificationManager.success('Page successfully added','Success');
              }
            });
          }
          this.getResources(this.state.defaultOrgId);
          NotificationManager.success('Interaction updated successfully','Success');
          this.getBoardData(this.state.defaultOrgId);
        }
        
      });
    }
    else{
      if(findSms[indexSms]["typeOfSms"]==="Outgoing")
      {
      var updateBoardInteraction = this.state.activeInteractions;

      updateBoardInteraction[indexSms]["prefix"]=""
      updateBoardInteraction[indexSms]["suffix"]=""
      updateBoardInteraction[indexSms]["communicationType"]="Inbound_Only"

      var docClient = new AWS.DynamoDB.DocumentClient();
      var interactionTable='interactions';
      var entryPointTable='entryPoint';
      var params = {
        TableName:interactionTable,
        Key:{
          "interactionId": this.state.boardId
        },
        "UpdateExpression": "set interactionName = :val1,json = :boardData,updatedAt = :updatedate,updatedBy = :updatedby",
        "ExpressionAttributeValues": {
          ":val1":this.state.boardName,
          ":boardData":btoa(JSON.stringify(updateBoardInteraction)),
          ":updatedate":new Date().toString(),
          ":updatedby":this.state.defaultUserName
        }
      };
      docClient.update(params, (err, data) => {
        if (err) {
            console.error("Unable to update board. Error JSON:", JSON.stringify(err, null, 2));
            NotificationManager.error('Interaction updation failed','Error');
        } else {
          var timediff=new Date();
          this.dateDifference(timediff);
          var updtdt=new Date().toLocaleTimeString([], {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit'});
          this.setState({
            updateDate:updtdt,
            updatedByUser:this.state.defaultUserName,
            saveUpdtMsg:'updated'
          });
          
          this.getResources(this.state.defaultOrgId);
          NotificationManager.success('Interaction updated successfully','Success');
          this.getBoardData(this.state.defaultOrgId);
        }
        
        });
      }
      else{
        NotificationManager.error('Please update sms settings first','Error');
      }
      
    }
    
  }
  
}
  changeBoardStat(ChangeStatus)
  {
    var docClient = new AWS.DynamoDB.DocumentClient();
    var interactionTable='interactions';
    var params = {
      TableName:interactionTable,
      Key:{
        "interactionId": this.state.boardId
      },
      "UpdateExpression": "set boardStatus = :val1,updatedAt = :val2",
      "ExpressionAttributeValues": {
        ":val1":ChangeStatus,
        ":val2":new Date().toString()
      }
    };
    docClient.update(params, (err, data) => {
      if (err) {
          console.error("Unable to update board. Error JSON:", JSON.stringify(err, null, 2));
		      NotificationManager.error('Interaction updation failed','Error');
      } else {
		    var updtdt=new Date().toLocaleTimeString([], {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit'});
		    this.setState({
          updateDate:updtdt,
          status:ChangeStatus
        })
        NotificationManager.success('Interaction updated successfully','Success');
        this.getBoardData(this.state.defaultOrgId);
      }
      
    });
  }
  deleteBoard()
  {
    var r = window.confirm("Are you sure want to delete this board?");
    if (r == true) {
      var interactionTable = "interactions";
    var entrypointTable = "entryPoint";
    var faqTable = "FAQLambda";
    var docClient = new AWS.DynamoDB.DocumentClient();
    var delParamsInt = {
      TableName:interactionTable,
      Key:{
          "interactionId": this.state.boardId,
      },
    };
    docClient.delete(delParamsInt,(err, data)=> {
      if (err) {
          console.error("Unable to delete item. Error JSON:", JSON.stringify(err, null, 2));
          NotificationManager.error('Unable to delete interaction','Error');
      } else {
          console.log("Delete int succeeded:", JSON.stringify(data, null, 2));
          //----DELETE FAQ TABLE---//
          var delParamsFaq = {
            TableName:faqTable,
            Key:{
                "interactionId": this.state.boardId,
            },
          };
          docClient.delete(delParamsFaq,(err, data)=> {
            if (err) {
                console.error("Unable to delete int from faq. Error JSON:", JSON.stringify(err, null, 2));
            } else {
                console.log("Delete int from faqsucceeded:", JSON.stringify(data, null, 2));
            }
          })
          //----------DELETE ENTRY POINT TABLE-----//
          var getEntryparams = {
            TableName : entrypointTable,
            FilterExpression : "interactionId = :val1",
            ExpressionAttributeValues: {
                ":val1": this.state.boardId
            }
          };
          docClient.scan(getEntryparams, (err, data)=> {
            if (err) {
                console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
            } else {
              if(data.Items.length>0)
              {
                  for(var i=0;i<data.Items.length;i++)
                  {
                    var delParamsEnt = {
                      TableName:entrypointTable,
                      Key:{
                          "entryPointIdentifier": data.Items[i].entryPointIdentifier,
                      },
                    };
                    docClient.delete(delParamsEnt,(err, data)=> {
                      if (err) {
                          console.error("Unable to delete int from entrypoint item. Error JSON:", JSON.stringify(err, null, 2));
                      } else {
                          console.log("Delete int from ent succeeded:", JSON.stringify(data, null, 2));
                      }
                    });
                  }
              }
              
            }
          });
          NotificationManager.success('Interaction successfully deleted','Success');
          /*this.setState({
            activeInteractions:[],
            boardId:'',
            boardName:'',
            activeIndex:-1,
            createdByUser:'',
            updatedByUser:'',
            createDate:'',
            updateDate:'',
            boardNameStatus:false,
            activeList:-1,
            activeBackground:'',
            createButtonStatus:true,
            saveButtonStatus:false

          })*/
          //this.getBoardDataAfterSave(this.state.defaultOrgName);
          this.getBoardData(this.state.defaultOrgId);
      }
    });
    } else {
      console.log("Press Cancel");
    }
    
    /**/
    
  }
  filterBoard=event=>{
    var interactionTable = "interactions";
    var docClient = new AWS.DynamoDB.DocumentClient();
    if(event.target.value==='all')
    {
      var getIntFIlterparams = {
        TableName : interactionTable,
        FilterExpression : "organizationId = :val1",
        ExpressionAttributeValues: {
            ":val1": this.state.defaultOrgId,
        }
      };
    }
    else{
      getIntFIlterparams = {
        TableName : interactionTable,
        FilterExpression : "organizationId = :val1 AND boardStatus = :val2",
        ExpressionAttributeValues: {
            ":val1": this.state.defaultOrgId,
            ":val2": event.target.value,
        }
      };
    }
      

    docClient.scan(getIntFIlterparams, (err, data)=> {
      if (err) {
          console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
      } else {
        this.setState({
          boardList:data.Items,
          activeList:-1,
        })
      }
    });

  }
  getGlobalSettings()
    {
        var globalSettingsTable="globalSettings"
        var docClient = new AWS.DynamoDB.DocumentClient();
        var getParams = {
            TableName : globalSettingsTable,
            FilterExpression : "environment = :val",
            ExpressionAttributeValues: {
                ":val": jsonConfig.environment
            }
          };
          docClient.scan(getParams, (err, data)=> {
            if (err) {
                console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
            } else {
                if(data.Items.length>0)
                {
                    this.setState({settingsVal:data.Items[0].checkmarkEnforcement})
                }
                else{
                    this.setState({settingsVal:false})
                }
            }
          });
    }
  dateDifference(updtDate)
  {
    var date1=new Date();
    var date2=new Date(updtDate);

    var diffTime = Math.abs(date2 - date1);
    var output='1 min(s)';
    if(diffTime>=(1000 * 60 * 60 * 24))
    {
      output=Math.ceil(diffTime / (1000 * 60 * 60 * 24)).toString()+' day(s)';
    }
    else if(diffTime>=(1000 * 60 * 60))
    {
      output=Math.ceil(diffTime / (1000 * 60 * 60)).toString()+' hour(s)';
    }
    else if(diffTime>=(1000 * 60))
    {
      output=Math.ceil(diffTime / (1000 * 60)).toString()+' min(s)';
    }
    this.setState({timeDifference:output})
  }
//------FOR CHANGE RIGHT PANEL--------//
openNewBoard()
{
  this.setState({
    modalDisplay:'flex',
    boardName:'',
    activeList:-1,
    createDate:'',
    updateDate:'',
    createdByUser:'',
    updatedByUser:'',
    boardNameStatus:'',
    createButtonStatus:true,
    boardNameReadonly:true,
    activeInteractions:[],
    
  })
}
createNewBoard()
{
  this.setState({ 
    activeIndex: -1,
    boardNameStatus:false,
    boardId:'',
    pannelVal:'',
    activeBackground:'workflow_illustration_v5',
    activeList:-1,
    //boardName:'',
		status:'',
		createDate:'',
    updateDate:'',
    activeInteractions:[],
    createButtonStatus:true,
    saveButtonStatus:false,
    updateButtonStatus:true,
    createdByUser:'',
    updatedByUser:'',
    modalIsOpen: true,
    modalDisplay: 'none',
   });
}
trimFunction(x)
{
  return x.replace(/\s+/g, '');
}
updateSetup(channelsArr,aiServiceArr,crmArr)
{
  var setupTable = "setup";
  var docClient = new AWS.DynamoDB.DocumentClient();

  var updtSetupParams = {
    TableName:setupTable,
    Key:{
      "organizationId": this.state.defaultOrgId,
    },
    "UpdateExpression": "set channelsJson = :val1,aiServicesJson = :val2,crmJson = :val3",
    "ExpressionAttributeValues": {
      ":val1": JSON.stringify(channelsArr),
      ":val2":JSON.stringify(aiServiceArr),
      ":val3":JSON.stringify(crmArr),
    },
  };
    docClient.update(updtSetupParams,(err, data)=> {
      if (err) {
        console.error("Unable to update item. Error JSON:", JSON.stringify(err, null, 2));
      } else {
        console.log("UpdateItem succeeded:", JSON.stringify(data, null, 2));
        this.getSetUp(this.state.defaultOrgId)
      }
    });


}
findandAddClass(intArr,findName,type)
{ 
    var findInteraction=intArr;
    var Legalindex = findInteraction.map(function(o) { return o.name; }).indexOf(findName);
    findInteraction[Legalindex]["addClass"]="progressFull";
    if(type==='Crm')
    {
      this.setState({availableCrm:findInteraction})
    }
    
}
getEventCheck(event,arrDet,name,checkmark)
{
  clearTimeout(this.clickedTimeout);
    this.clickedTimeout = null;
    arrDet.key=this.state.defaultOrgId;
  
  this.setState({
    pannelVal:'',
    activeBackground:'workflow_illustration_v5',
    activeIndex:-1
  })
  var activeInteractionSize = this.state.activeInteractions.length;
  if(activeInteractionSize < 4) {
    if(this.state.settingsVal===true)
    {
      if(arrDet.addClass==='progressFull')
      {
        this.state.activeInteractions.push(arrDet);
      }
      else{
          NotificationManager.warning('Setup the component before adding it to the interaction board','Warning');
      }
      
    }
    else{
      if(this.state.boardName==='')
      {
          NotificationManager.warning('Please add the board name first','Warning');
      }
      else{
        this.state.activeInteractions.push(arrDet);
      }
      
    }
    this.addNext(name);
  }
  else{
    NotificationManager.warning('Currently more than 4 integrations are not supported!','Warning');
  }
}
async addNext(name) {
  var index = this.state.activeInteractions.length - 2;
  if(index >= 0) {
    var temp = this.state.activeInteractions;
    temp[index].next = name;
    
    this.setState({
      activeInteractions: temp
    });
  }
}

getPannelVal(event,iconVal,key,key2,key3)
{

  if (!this.clickedTimeout) {
    this.clickedTimeout = setTimeout(() => {
      this.clickedTimeout = null;
      // do your stuff here

      this.setState({ 
        activeIcon: key,
        ServiceIcon: key2,
        CrmIcon:key3,
        availableChannelsClick:true,
        activeIndex:-1,
        activeBackground:'workflow_illustration_v5',
      });

      if(iconVal==="Messenger")
      {
        this.getMessengerPages(this.state.defaultOrgId)
        this.setState({pannelVal:'Messenger'})
        this.getEntryPointMessengerData();
      }
      else if(iconVal==="Whatsapp")
      {
        this.setState({
          pannelVal:'Whatsapp',
          inactiveIcon:img_elWhatsapp
        })
      }
      else if(iconVal==="Wechat")
      {
        this.setState({
          pannelVal:'Wechat',
          inactiveIcon:img_elWechat
        })
      }
      else if(iconVal==="kik")
      {
        this.setState({
          pannelVal:'kik',
          inactiveIcon:img_elKik2
        })
      }
      else if(iconVal==="line")
      {
        this.setState({
          pannelVal:'line',
          inactiveIcon:img_elLine
        })
      }
      else if(iconVal==="Snapchat")
      {
        this.setState({
          pannelVal:'Snapchat',
          inactiveIcon:img_elSnapchat
      })
      }
      else if(iconVal==="Instagram")
      {
        this.setState({
          pannelVal:'Instagram',
          inactiveIcon:img_elInstagram
      })
      }
      else if(iconVal==="Twitter")
      {
        this.setState({
          pannelVal:'Twitter',
          inactiveIcon:img_elTwitter
      })
      }
      else if(iconVal==="Viber")
      {
        this.setState({
          pannelVal:'Viber',
          inactiveIcon:img_elViber
      })
      }
      else if(iconVal==="Telegram")
      {
        this.setState({
          pannelVal:'Telegram',
          inactiveIcon:img_elTelegram
      })
      }
      else if(iconVal==="Facebook")
      {
        this.setState({
          pannelVal:'Facebook',
          inactiveIcon:img_elF_icon
      })
      }
      else if(iconVal==="Sms")
      {
        this.gettwiloSubAccount();
        this.getSmsSettings();
        this.setState({
          pannelVal:'Sms',
      })
      
      }
      else if(iconVal==="Hubspot")
      {
        this.setState({
          pannelVal:'Hubspot',
      })
      }
      else if(iconVal==="QQ")
      {
        this.setState({
          pannelVal:'QQ',
          inactiveIcon:img_elqq
      })
      }
      else if(iconVal==="Pinterest")
      {
        this.setState({
          pannelVal:'Pinterest',
          inactiveIcon:img_elPinterest
      })
      }
      else if(iconVal==="Linkedin")
      {
        this.setState({
          pannelVal:'Linkedin',
          inactiveIcon:img_elLinkedin
      })
      }
      else if(iconVal==="Youtube")
      {
        this.setState({
          pannelVal:'Youtube',
          inactiveIcon:img_elYoutube
      })
      }
      else if(iconVal==="Reddit")
      {
        this.setState({
          pannelVal:'Reddit',
          inactiveIcon:img_elShape
      })
      }
      else if(iconVal==="Live Chat")
      {
        this.getLiveChatData(this.state.defaultOrgId);
        this.getLiveChatSettings(this.state.defaultOrgId);
        this.setState({pannelVal:'Live Chat'})
      }
      else if(iconVal==="Client Chat")
      {
        this.getClientChatData(this.state.defaultOrgId);
        this.getClientChatSettings(this.state.defaultOrgId);
        this.setState({pannelVal:'Client Chat'})
      }
      else if(iconVal==="Chat")
      {
        this.getBlueleapchat(this.state.defaultOrgId);
        this.tensecondsLoader();
        this.setState({pannelVal:'Chat'})
      }
      else if(iconVal==="Sentiment Bot")
      {
        this.saBotTimeout();
        this.setState({pannelVal:'Sentiment Bot'})
      }
      else if(iconVal==="Elastic Search")
      {
        this.getelasticSearchData(this.state.defaultOrgId,'');
        this.serachElasticSearch(this.state.defaultOrgId);
        this.elasticsearchTimeout();
        this.setState({pannelVal:'Elastic Search'})
      }
      else if(iconVal==="Lambda Faq")
      {
        this.setState({pannelVal:'Lambda Faq'})
      }
      else if(iconVal==="Sentiment Analysis")
      {
        this.setState({
          pannelVal:'Sentiment Analysis',
          inactiveIcon:img_elsa2
        })
      }
      else if(iconVal==="Text to Speech")
      {
        this.setState({
          pannelVal:'Text to Speech',
          inactiveIcon:img_elTts
      })
      }
      else if(iconVal==="Speech to Text")
      {
        this.setState({
          pannelVal:'Speech to Text',
          inactiveIcon:img_elStt
      })
      }
      else if(iconVal==="Visual Recognition")
      {
        this.setState({
          pannelVal:'Visual Recognition',
          inactiveIcon:img_elVr
      })
      }
      else if(iconVal==="Visual Bot")
      {
        this.setState({
          pannelVal:'Visual Bot',
          inactiveIcon:img_elVrBot
      })
      }
      else if(iconVal==="Language Processing")
      {
        this.setState({
          pannelVal:'Language Processing',
          inactiveIcon:img_elLp
      })
      }
      else if(iconVal==="Language Translator")
      {
        this.setState({
          pannelVal:'Language Translator',
          inactiveIcon:img_elLt
      })
      }
      else if(iconVal==="Personality Insights")
      {
        this.setState({
          pannelVal:'Personality Insights',
          inactiveIcon:img_elPi
      })
      }
      else if(iconVal==="Personality Bot")
      {
        this.setState({
          pannelVal:'Personality Bot',
          inactiveIcon:img_elPiBot
      })
      }
      else if(iconVal==="Language Classifier")
      {
        this.setState({
          pannelVal:'Language Classifier',
          inactiveIcon:img_elLc
      })
      }
      else if(iconVal==="Video Room")
      {
        this.getVideoroomSettings();
        this.setState({
          pannelVal:'Video Room',
      })
      }
      else if(iconVal==='Zendesk')
      {
        this.getZendeskData(this.state.defaultOrgId);
        this.setState({pannelVal:'Zendesk'})
      }
      else if(iconVal==='Sales Force')
      {
        this.getSalesforceData(this.state.defaultOrgId);
        this.setState({pannelVal:'Sales Force'})
      }
      else if(iconVal==="Microsoft Dynamics")
      {
        this.setState({
          pannelVal:'Microsoft Dynamics',
          inactiveIcon:img_elMd
      })
      }
      else if(iconVal==="Oracle")
      {
        this.getOracleSettings(this.state.defaultOrgId);
        this.setState({pannelVal:'Oracle'})
      }
      else if(iconVal==="SAP")
      {
        this.setState({
          pannelVal:'SAP',
          inactiveIcon:img_elsap
      })
      }
      else if(iconVal==="Siebel")
      {
        this.setState({
          pannelVal:'Siebel',
          inactiveIcon:img_elsap
      })
      }
      else if(iconVal==="ZOHO")
      {
        this.setState({
          pannelVal:'ZOHO',
          inactiveIcon:img_elzoho
      })
      }
      else if(iconVal==="ACT!")
      {
        this.setState({
          pannelVal:'ACT!',
          inactiveIcon:img_elact
      })
      }
      else{
        this.setState({pannelVal:''})
      }
    }, this.doubleClickTimeout);
  }
  
	
}
activeMake(event,divid,iconVal)
{
    this.setState({ 
      activeIndex: divid,
      eventFireStatus:false,
      availableChannelsClick:false,
      activeIcon:-1,
      ServiceIcon:-1,
      CrmIcon:-1,
      activeBackground:'',
    });
  if(iconVal==="Messenger")
	{
    this.getMessengerPages(this.state.defaultOrgId)
    this.setState({pannelVal:'Messenger'});
    this.getEntryPointMessengerData();
	}
	else if(iconVal==="Whatsapp")
      {
        this.setState({
          pannelVal:'Whatsapp',
          inactiveIcon:img_elWhatsapp
        })
      }
      else if(iconVal==="Wechat")
      {
        this.setState({
          pannelVal:'Wechat',
          inactiveIcon:img_elWechat
        })
      }
      else if(iconVal==="kik")
      {
        this.setState({
          pannelVal:'kik',
          inactiveIcon:img_elKik2
        })
      }
      else if(iconVal==="line")
      {
        this.setState({
          pannelVal:'line',
          inactiveIcon:img_elLine
        })
      }
      else if(iconVal==="Snapchat")
      {
        this.setState({
          pannelVal:'Snapchat',
          inactiveIcon:img_elSnapchat
      })
      }
      else if(iconVal==="Instagram")
      {
        this.setState({
          pannelVal:'Instagram',
          inactiveIcon:img_elInstagram
      })
      }
      else if(iconVal==="Twitter")
      {
        this.setState({
          pannelVal:'Twitter',
          inactiveIcon:img_elTwitter
      })
      }
      else if(iconVal==="Viber")
      {
        this.setState({
          pannelVal:'Viber',
          inactiveIcon:img_elViber
      })
      }
      else if(iconVal==="Telegram")
      {
        this.setState({
          pannelVal:'Telegram',
          inactiveIcon:img_elTelegram
      })
      }
      else if(iconVal==="Facebook")
      {
        this.setState({
          pannelVal:'Facebook',
          inactiveIcon:img_elF_icon
      })
      }
      else if(iconVal==="Sms")
      {
        this.getAddedPhoneNumber();
        this.getSmsPosition();
        this.getInteractionbyId(this.state.boardId) 
        this.setState({
          pannelVal:'Sms',
      })
      }
      else if(iconVal==="Hubspot")
      {
        this.setState({
          pannelVal:'Hubspot',
      })
      }
      else if(iconVal==="QQ")
      {
        this.setState({
          pannelVal:'QQ',
          inactiveIcon:img_elqq
      })
      }
      else if(iconVal==="Pinterest")
      {
        this.setState({
          pannelVal:'Pinterest',
          inactiveIcon:img_elPinterest
      })
      }
      else if(iconVal==="Linkedin")
      {
        this.setState({
          pannelVal:'Linkedin',
          inactiveIcon:img_elLinkedin
      })
      }
      else if(iconVal==="Youtube")
      {
        this.setState({
          pannelVal:'Youtube',
          inactiveIcon:img_elYoutube
      })
      }
      else if(iconVal==="Reddit")
      {
        this.setState({
          pannelVal:'Reddit',
          inactiveIcon:img_elShape
      })
      }
	else if(iconVal==="Client Chat")
	{
    this.getEntryPointClientChatData();
    this.getClientChatData(this.state.defaultOrgId);
    this.getClientChatSettings(this.state.defaultOrgId);
		this.setState({pannelVal:'Client Chat'})
  }
  else if(iconVal==="Live Chat")
  {
      this.getEntryPointLiveChatData();
      this.getLiveChatData(this.state.defaultOrgId);
      this.getLiveChatSettings(this.state.defaultOrgId);
      this.setState({pannelVal:'Live Chat'})
  }
	else if(iconVal==="Chat")
	{
    this.getBlueleapchat(this.state.defaultOrgId);
		this.setState({pannelVal:'Chat'})
	}
	else if(iconVal==="Sentiment Bot")
	{
		this.setState({pannelVal:'Sentiment Bot'})
	}
	else if(iconVal==="Elastic Search")
	{
    this.getelasticSearchData(this.state.defaultOrgId,'');
    this.serachElasticSearch(this.state.defaultOrgId);
		this.setState({pannelVal:'Elastic Search'})
  }
  else if(iconVal==="Lambda Faq")
	{
    console.log(this.state.boardId);
    this.getFaq(this.state.boardId);
    if(this.state.enableLiveChatStatus==='enable')
      {
        this.setState({
          pharseEnable:'inline-block',
          dropDownVal:1
        });
      }
      else{
        this.setState({pharseEnable:'none'});
      }
      if(this.state.boardId==='')
      {
        this.setState({pannelVal:''})
      }
      else{
        this.setState({pannelVal:'Lambda Faq'})
      }
		
  }
  else if(iconVal==="Sentiment Analysis")
  {
        this.setState({
          pannelVal:'Sentiment Analysis',
          inactiveIcon:img_elSa
        })
  }
  else if(iconVal==="Text to Speech")
      {
        this.setState({
          pannelVal:'Text to Speech',
          inactiveIcon:img_elTts
      })
      }
      else if(iconVal==="Speech to Text")
      {
        this.setState({
          pannelVal:'Text to Speech',
          inactiveIcon:img_elStt
      })
      }
      else if(iconVal==="Visual Recognition")
      {
        this.setState({
          pannelVal:'Visual Recognition',
          inactiveIcon:img_elVr
      })
      }
      else if(iconVal==="Visual Bot")
      {
        this.setState({
          pannelVal:'Visual Bot',
          inactiveIcon:img_elVrBot
      })
      }
      else if(iconVal==="Language Processing")
      {
        this.setState({
          pannelVal:'Language Processing',
          inactiveIcon:img_elLp
      })
      }
      else if(iconVal==="Language Translator")
      {
        this.setState({
          pannelVal:'Language Translator',
          inactiveIcon:img_elLt
      })
      }
      else if(iconVal==="Personality Insights")
      {
        this.setState({
          pannelVal:'Personality Insights',
          inactiveIcon:img_elPi
      })
      }
      else if(iconVal==="Personality Bot")
      {
        this.setState({
          pannelVal:'Personality Bot',
          inactiveIcon:img_elPiBot
      })
      }
      else if(iconVal==="Language Classifier")
      {
        this.setState({
          pannelVal:'Language Classifier',
          inactiveIcon:img_elLc
      })
      }
      else if(iconVal==="Video Room")
      {
        this.setState({
          pannelVal:'Video Room',
          inactiveIcon:img_elRoom
      })
      }
	else if(iconVal==='Zendesk')
	{
    this.getZendeskData(this.state.defaultOrgId);
		this.setState({pannelVal:'Zendesk'})
  }
  else if(iconVal==='Sales Force')
	{
    this.getSalesforceData(this.state.defaultOrgId);
		this.setState({pannelVal:'Sales Force'})
  }
  else if(iconVal==="Microsoft Dynamics")
      {
        this.setState({
          pannelVal:'Microsoft Dynamics',
          inactiveIcon:img_elMd
      })
      }
  else if(iconVal==="Oracle")
      {
        this.getOracleSettings(this.state.defaultOrgId);
        this.setState({pannelVal:'Oracle'})
      }
      else if(iconVal==="SAP")
      {
        this.setState({
          pannelVal:'SAP',
          inactiveIcon:img_elsap
      })
      }
      else if(iconVal==="Siebel")
      {
        this.setState({
          pannelVal:'Siebel',
          inactiveIcon:img_elsap
      })
      }
      else if(iconVal==="ZOHO")
      {
        this.setState({
          pannelVal:'ZOHO',
          inactiveIcon:img_elzoho
      })
      }
      else if(iconVal==="ACT!")
      {
        this.setState({
          pannelVal:'ACT!',
          inactiveIcon:img_elact
      })
      }
	else{
		this.setState({pannelVal:''})
	}
    
    
}
changePanel()
{
	if(this.state.pannelVal==="Messenger")
	{
		let fbContent;
		if(this.state.fbName!=="")
		{
			fbContent=(
				<p className="labelSubText">Welcome {this.state.fbName}</p>
			)
		}
		else{
			fbContent=(
				<FacebookLogin
				appId={jsonConfig.facebookLoginAppId}
				autoLoad={false}
				fields="name,email,picture"
				scope="public_profile,manage_pages,pages_show_list,pages_messaging"
				callback={this.responseFacebook}
        cssClass="loginBtn loginBtn--facebook"
        onClick={(e) => this.checkBeforeFblogin(e)}
				/>
			)
		}
		return(
			<div id="Panel_4___Connection_Progress">
				<div id="Connection_Progress" className="lh16">
					<span>Messenger</span>
				</div>
        <div className="ml20 mt10">
					<span className="labelSubText">Subscribed Facebook Pages</span>
				</div>
        <div className="descriptor_outer mt16">
				<div id="Descriptor">
					{this.state.messengerPages.map((value, key) => (
            <div key={key} style={{display:'flex',flexWrap:'wrap',justifyContent:'space-between'}}>
						  <div className="messengerPage">{value.pagename}</div>
              <div>
                <button className="small_button_disable_round pull-right mr20" onClick={(e) => this.deletePageEntry(value.pageId,key)}>
                    <i className="fa fa-times"></i>
                </button>
              </div>
            </div>
					))}
				</div>
        </div>
        <div className="mt12 lh12">
					{this.state.pageLoadedforMessenger===true?(
						<div className="provisionWaitbox">
            <div className="row">
              <div className="col-md-3">
                <img src={img_prLoader} style={{margin:'10px',height:'30px',width:'30px'}} alt="img_prLoader"/>
              </div>
              <div className="col-md-9 mt10">
                <p className="labelText colorChange">Please wait ... </p>
                <p className="labelText colorChange mt10">Logging in to facebook</p>
              </div>
            </div>
          </div>
					):(
						''
					)}
			  	</div>
				<div id="createBoard">
					{fbContent}
				</div>
        <div className="dividerFullWidth"></div>
        <div className="descriptor_outer mt16">
				<div id="Descriptor">
					
            <div>
              <p className="noInteractionHeader">Heads Up !</p>
              <p className="noInteractionText">
              Subscribed Facebook pages will show the 
              list of facebook pages subscribed with our app.
              If no pages are setup, get started by clicking 
              login with facebook and subscribe your page with our application
              </p>
            </div>
          
				</div>
        </div>
			</div>
		  );
	}
	else if(this.state.pannelVal==="Client Chat")
	{
		return(
			<div id="Panel_4___Connection_Progress">
        <div className="scrollForInteraction">
				<div id="Connection_Progress" className="lh16">
					<span>Client Chat</span>
				</div>
        <div className="descriptor_outer mt16">
				<div id="Descriptor">
          
					{this.state.websiteList.map((value, key) => (
						<div key={key} style={{display:'flex',flexWrap:'wrap',justifyContent:'space-between'}}>
                <div className="messengerPage">{value.website}</div>
                <div>
                  <button className="small_button_disable_round" onClick={(e) => this.deleteWebEntry(value.clientChatId)}>
                    <i className="fa fa-times"></i>
                  </button>
                </div>
            </div>
					))}
          
				</div>
        </div>
        
				<div id="InspectorClientChat" className="mt12">
					<div className="mt12 lh12">
						<span className="labelText lh10">Add Website</span>
						<input 
						type="text"
						className="txtType widthForpanel3text mt10"
						value={this.state.websiteName}
						onChange={this.websiteHandler}
						/>
					</div>
          <div className="mt12">
            <button className="button" onClick={(e) => this.sendwebsite(e)}>Submit</button>
          </div>
          <div className="dividerFullWidthOthers"></div>
					<div className="mt12 lh12">
          <div className="upload-btn-wrapper">
              <button className="upload_button">Upload a file</button>
              <input type="file"
                onChange={(e) => this.getIconfile(e)}
                className="txtFileType mt10"
                style={{width:'273px'}}
              />
            </div>
            <img id="chatImg" 
              className="img-responsive" 
              src={this.state.chatIcon} 
              alt="ChatIcon"
              style={{marginTop:'-21px'}}
              />
            
					</div>
					<div className="mt12 lh12">
						<span className="labelText lh10">Client Chat Text</span>
						<input type="text" 
						className="txtType widthForpanel3text mt10" 
						value={this.state.chatText}
            onChange={this.chattextHandler}
						/>
						
					</div>
          
        <div className="mt12 lh12">
					<span className="labelText lh10">Bot Name</span>
          <input type="text" 
          value={this.state.varBotName} 
          className="txtType widthForpanel3text mt10" 
          onChange={this.varBotNameHandler} />
				</div>
        <div className="mt12 lh12">
					<span className="labelText lh10">Greeting</span>
          <textarea 
          value={this.state.clientChatGreeting} 
          className="txtType widthForpanel3text mt10" 
          onChange={this.clientChatGreetingHandler}
          rows="2"
          cols="2"
          >
            
          </textarea>
				</div>
				<div className="mt12 lh12">
					<span className="labelText lh10">Generic Error Message</span>
          <input type="text" 
          value={this.state.varGenericErrorMessage} 
          className="txtType widthForpanel3text mt10" 
          onChange={this.varGenericErrorMessageHandler} />
				</div>
        <div className="mt12">
          <span className="labelText lh10">Clientchat Timeout</span>
            <select className="txtType arrowAlignForPanel3 widthForpanel3text mt10" onChange={this.clientChatTimeoutHandler} value={this.state.clientChatTimeout}>
              <option value="1">1 Minute</option>
              <option value="2">2 Minutes</option>
              <option value="5">5 Minutes</option>
              <option value="10">10 Minutes</option>
              <option value="Never">Never</option>
            </select>
        </div>
        <div className="mt12 lh12">
					<span className="labelText lh10">Timeout Message</span>
          <input type="text" 
          value={this.state.clientChatTimeoutMessage} 
          className="txtType widthForpanel3text mt10" 
          onChange={this.clientChatTimeoutMessageHandler} />
				</div>
        <div className="mt12 lh12">
					<span className="labelText lh10">Select Color</span>
          <select className="txtType arrowAlignForPanel3 widthForpanel3text mt10" onChange={this.clientChatColorHandler} value={this.state.clientChatColor}>
              <option value="#008CFF">Blue</option>
              <option value="#4caf50">Green</option>
              <option value="#000000">Monochrome</option>
          </select>
				</div>
        <div className="mt12 lh12">
            <span className="labelText lh10">Message after the client chat is closed</span>
            <textarea 
            rows="2"
            cols="2"
            className="txtType widthForpanel3text mt10"
            value={this.state.endClientChat}
            onChange={this.endClientChatHandler}
            >
            </textarea>
        </div>
          <div className="mt12">
            <button className="button" onClick={(e) => this.saveChatSettings(e)}>Submit</button>
          </div>
				</div>
        </div>
			</div>
		  );
  }
  else if(this.state.pannelVal==="Live Chat")
	{
		return(
			<div id="Panel_4___Connection_Progress">
        <div className="scrollForInteraction">
				<div id="Connection_Progress" className="lh16">
					<span>Live Chat</span>
				</div>
        <div className="descriptor_outer mt16">
				<div id="Descriptor">
					{this.state.websiteLiveList.map((value, key) => (
            <div key={key} style={{display:'flex',flexWrap:'wrap',justifyContent:'space-between'}}>
						<div className="messengerPage">{value.website}</div>
            <div>
              <button className="small_button_disable_round pull-right mr20" onClick={(e) => this.deleteLiveWebEntry(value.liveChatId)}>
                  <i className="fa fa-times"></i>
              </button>
            </div>
            </div>
					))}
				</div>
        </div>
				<div id="InspectorClientChat" className="mt12">
					<div className="mt12 lh12">
						<span className="labelText lh10">Add Website</span>
						<input 
						type="text"
						className="txtType widthForpanel3text mt10"
						value={this.state.websiteLiveName}
						onChange={this.websiteLiveHandler}
						/>
					</div>
          <div className="mt12">
            <button className="button" onClick={(e) => this.sendLivewebsite(e)}>Submit</button>
          </div>
          <div className="dividerFullWidthOthers"></div>
					<div className="mt12 lh12">
          <div className="upload-btn-wrapper">
              <button className="upload_button">Upload a file</button>
              <input type="file"
                onChange={(e) => this.getLiveIconfile(e)}
                className="txtFileType mt10"
                style={{width:'273px'}}
              />
            </div>
            <img id="chatImg" 
              className="img-responsive" 
              src={this.state.livechatIcon} 
              alt="ChatIcon"
              style={{marginTop:'-21px'}}
              />
            
					</div>
					<div className="mt12 lh12">
						<span className="labelText lh10">Live Chat Text</span>
						<input type="text" 
						className="txtType widthForpanel3text mt10" 
						value={this.state.livechatText}
            onChange={this.livechattextHandler}
						/>
						
					</div>
          
        <div className="mt12 lh12">
					<span className="labelText lh10">Bot Name</span>
          <input type="text" 
          value={this.state.livevarBotName} 
          className="txtType widthForpanel3text mt10" 
          onChange={this.livevarBotNameHandler} />
				</div>
        <div className="mt12 lh12">
					<span className="labelText lh10">Greeting</span>
          <textarea 
          value={this.state.liveChatGreeting} 
          className="txtType widthForpanel3text mt10" 
          onChange={this.liveChatGreetingHandler}
          rows="2"
          cols="2"
          >
            
          </textarea>
				</div>
				<div className="mt12 lh12">
					<span className="labelText lh10">Generic Error Message</span>
          <input type="text" 
          value={this.state.livevarGenericErrorMessage} 
          className="txtType widthForpanel3text mt10" 
          onChange={this.livevarGenericErrorMessageHandler} />
				</div>
        <div className="mt12">
          <span className="labelText lh10">Livechat Timeout</span>
            <select className="txtType arrowAlignForPanel3 widthForpanel3text mt10" onChange={this.liveChatTimeoutHandler} value={this.state.liveChatTimeout}>
              <option value="1">1 Minute</option>
              <option value="2">2 Minutes</option>
              <option value="5">5 Minutes</option>
              <option value="10">10 Minutes</option>
              <option value="Never">Never</option>
            </select>
        </div>
        <div className="mt12 lh12">
					<span className="labelText lh10">Timeout Message</span>
          <input type="text" 
          value={this.state.liveChatTimeoutMessage} 
          className="txtType widthForpanel3text mt10" 
          onChange={this.liveChatTimeoutMessageHandler} />
				</div>
        <div className="mt12 lh12">
					<span className="labelText lh10">Select Color</span>
          <select className="txtType arrowAlignForPanel3 widthForpanel3text mt10" onChange={this.liveChatColorHandler} value={this.state.liveChatColor}>
              <option value="#008CFF">Blue</option>
              <option value="#4caf50">Green</option>
              <option value="#000000">Monochrome</option>
          </select>
				</div>
        <div className="mt12 lh12">
              <span className="labelText lh10">Message after the live chat is closed</span>
              <textarea
              rows="2"
              cols="2"
              className="txtType widthForpanel3text mt10"
              value={this.state.endLiveChat}
              onChange={this.endLiveChatHandler}
              ></textarea>
          </div>
          <div className="mt12">
            <button className="button" onClick={(e) => this.saveLiveChatSettings(e)}>Submit</button>
          </div>
				</div>
        </div>
			</div>
		  );
	}
	else if(this.state.pannelVal==="Chat")
	{
	return(
		<div id="Panel_4___Connection_Progress">
			<div id="Connection_Progress" className="lh16">
				<span>Chat System</span>
        <span className="ml10 type_a pull-right" style={{marginRight:'1px',marginTop:'-3px'}} onClick={(e) => this.chatsystemRefresh(e)}>
            <img src={img_Refresh} data-tip="Refresh" alt="refresh"/>
            <ReactTooltip place="bottom" type="dark" effect="solid"/>
        </span>
			</div>
      <div className="scrollForInteraction">
			<div id="InspectorChat">
				
        <div className="mt12">	
				{this.state.isDisabled===false?(
          <button className="button ml0" onClick={(e) => this.sendcompName(e)}>
          Provision
          </button>
          ):(
            ''
          )}
				</div>
        

        <div className="margin-align mt12 lh12">
					<span className="labelText spanText lh10">Provisioned Resources : 
          {this.state.blueleapChatLoginUrl!==''?(
            <button  className="button_delete type_a ml10 pull-right" style={{marginRight:'15px'}} onClick={(e) => this.deallocateResource(e)}>
              DEALLOCATE
            </button>
            ):(
              ''
          )}
          </span>
          <span className="labelText spanText colorChange">{this.state.provisionedResourceName}</span>
        </div>
        
        
				<div className="margin-align lh12 mt12">
					<span className="labelText spanText lh10">Login Url : </span>
          <span className="labelText spanText colorChange mt10 lh10 type_a hyperlink" onClick={()=> window.open(this.state.blueleapChatLoginUrl, "_blank")}>{this.state.blueleapChatLoginUrl}</span>
				</div>
        

        <div className="mt12 lh12">
					{this.state.blueleapChatLoginUrlStatus===false?(
						<div className="provisionWaitbox">
            <div className="row">
              <div className="col-md-3">
                <img src={img_prLoader} style={{margin:'10px',height:'30px'}} alt="img_prLoader"/>
              </div>
              <div className="col-md-9 mt10">
                <p className="labelText colorChange">Please Wait...</p>
                <p className="labelText colorChange mt10">Provisioning is in progress</p>
              </div>
            </div>
          </div>
					):(
						''
					)}
			  	</div>

        <div className="dividerFullWidthOthers"></div>
        <div className={this.state.activeAdminDetails}>
				<div className="mt16">
					<span className="labelSubText lh16">Admin Details</span>
					{this.state.userId===''?(
            <span style={{float:'right',marginRight:'15px'}}>
                <button data-tip="Save" className="button_icon_create" onClick={(e) => this.updateCredentials(e)}>&nbsp;</button>
                <ReactTooltip place="bottom" type="dark" effect="solid"/>  
            </span>
          ):(
            <span style={{float:'right',marginRight:'15px',display:this.state.hideAllButtonforAdmin}}>
              <button data-tip="Edit" className="button_icon_edit" style={{display:this.state.hideEditbuttonforBlAdmin}} onClick={(e) => this.editAdminField(e)}>&nbsp;</button>
              <ReactTooltip place="bottom" type="dark" effect="solid"/> 
              <button data-tip="Update" className="button_icon_create" style={{display:this.state.hideUpdatebuttonforBlAdmin}} onClick={(e) => this.updateAdminUser(e)}>&nbsp;</button>
              <ReactTooltip place="bottom" type="dark" effect="solid"/> 
              <button data-tip="Test" className="button_icon_test" onClick={(e) => this.getAdminuserId(e)}>&nbsp;</button>
              <ReactTooltip place="bottom" type="dark" effect="solid"/> 
            </span>
          )}
				</div>
				<div className="mt16 lh0">
					<span className="labelText lh10">Username</span>
					<input 
					type="text"
					className="txtType widthForpanel3text mt10"
					value={this.state.userName}
					onChange={this.usernameChange}
          readOnly={this.state.updateAdmintxtstat}
          style={{border:this.state.changeTextboxborder}}
					/>
          </div>
        <div className="mt12 lh0">
					<span className="labelText mt12 lh10">Password</span>
					<input 
					type="password"
					className="txtType widthForpanel3text mt10"
					value={this.state.userPassword}
          onChange={this.passwordChange}
          readOnly={this.state.updateAdmintxtstat}
          style={{border:this.state.changeTextboxborder}}
					/>
				</div>
        </div>
        <div className="dividerFullWidthOthers"></div>
        <div className={this.state.activeIntegrationDetails}>
				<div className="mt16">
					<span className="labelSubText">Integration User details</span>
					{this.state.integrationUserId===''?(
            <span style={{float:'right',marginRight:'15px'}}>            
                <button data-tip="Save" className="button_icon_create" onClick={(e) => this.sendIntegrationCreds(e)}>&nbsp;</button>
                <ReactTooltip place="bottom" type="dark" effect="solid"/>           
            </span>
          ):(
            <span style={{float:'right',marginRight:'15px',display:this.state.hideAllButtonforIntUser}}>
              <button data-tip="Edit" className="button_icon_edit" style={{display:this.state.hideEditbuttonBlUser}} onClick={(e) => this.editIntegration(e)}>&nbsp;</button>
              <ReactTooltip place="bottom" type="dark" effect="solid"/>  
              <button data-tip="Update" className="button_icon_create" style={{display:this.state.hideUpdatebuttonBlUser}} onClick={(e) => this.updateSettings(e)}>&nbsp;</button>
              <ReactTooltip place="bottom" type="dark" effect="solid"/>  
              <button data-tip="Test" className="button_icon_test" onClick={(e) => this.testIntegratedProfile(e)}>&nbsp;</button>
              <ReactTooltip place="bottom" type="dark" effect="solid"/>  
            </span>
          )}
          
				</div>
				<div className="mt16 lh16">
					<span className="labelText lh10">Integration Username</span>
					<input 
					type="text"
					className="txtType widthForpanel3text mt10"
					value={this.state.integrationUser}
          onChange={this.intUserChange}
          readOnly={this.state.intStatus}
          style={{border:this.state.changeTextboxborder}}
					/>
          </div>
          <div className="mt12 lh12">
            <span className="labelText lh10">Integration Email</span>
            <input 
            type="text"
            className="txtType widthForpanel3text mt10"
            value={this.state.integrationEmail}
            onChange={this.intEmailChange}
            readOnly={this.state.intEmailStatus}
            style={{border:this.state.changeTextboxborder}}
            />
          </div>
          <div className="mt12 lh12">
            <span className="labelText lh10">Integration Password</span>
            <input 
            type="password"
            className="txtType widthForpanel3text mt10"
            value={this.state.integrationPassword}
            onChange={this.intPasswordChange}
            readOnly={this.state.intStatus}
            style={{border:this.state.changeTextboxborder}}
            />
          </div>
          </div> 
        <div className="dividerFullWidthOthers"></div>
        <div className={"mb10 " +this.state.activeWaitMsg}>
        {this.state.blueleapChatLoginUrl!==''?(
          this.state.transferMsgStatus===false?(
            <span style={{float:'right',marginRight:'15px'}}>            
                <button data-tip="Save" className="button_icon_create" style={{display:this.state.hideCreateButtonWait}} onClick={(e) => this.addMsgService(e)}>&nbsp;</button>
                <ReactTooltip place="bottom" type="dark" effect="solid"/>           
            </span>
          ):(
            <span style={{float:'right',marginRight:'15px',display:'inline-block'}}>
              <button data-tip="Edit" className="button_icon_edit" style={{display:this.state.hideEditbuttonBlMsg}} onClick={(e) => this.editTransferWait(e)}>&nbsp;</button>
              <ReactTooltip place="bottom" type="dark" effect="solid"/>  
            </span>
          )
              ):(
                  ''
              )}
        <div className="mt12 lh12">
					<span className="labelText lh10">Transfer wait message</span>
					<input 
					type="text"
					className="txtType widthForpanel3text mt10"
					value={this.state.transferMsg}
          onChange={this.transferHandler}
          readOnly={this.state.transferMsgStatus}
          style={{border:this.state.changeTextboxborder}}
					/>
        </div>
        <div className="mt12 lh12">
					<span className="labelText lh10">No agents available message</span>
					<input 
					type="text"
					className="txtType widthForpanel3text mt10"
					value={this.state.noAgentsMsg}
          onChange={this.noagentsHandler}
          readOnly={this.state.transferMsgStatus}
          style={{border:this.state.changeTextboxborder}}
					/>
					</div>
          
        </div>
          
			</div>
      </div>
		</div>
	)
  }
  else if(this.state.pannelVal==='Sms')
	{
		return(
			<div id="Panel_4___Connection_Progress">
			<div id="Connection_Progress" className="lh16">
				<span>SMS</span>
			</div>
			<div id="InspectorChat" className="mt16">
        
        <div className="mt16 lh16">
					<span className="labelText">Sub Account Id :
            {this.state.smsProvisionButtonStatus===true?(
              <button className="button_delete type_a ml10" onClick={(e) => this.deallocateTwilio(e)}>
                DEALLOCATE
              </button>
            ):(
              ''
            )}
          </span>
          <span className="labelText colorChange">{this.state.getAccSubid}</span>
				</div>
        <div className="mt16 lh16">
					<span className="labelText">Phone Number : </span>
          <div className="descriptor_outer mt16 ml0">
            <div id="Descriptor">
              {this.state.phoneNumberJson.map((value, key) => (
                <div key={key} style={{display:'flex',flexWrap:'wrap',justifyContent:'space-between'}}>
                  <div className="messengerPage">{Object.values(value)[0]}</div>
                </div>
              ))}
            </div>
        </div>
				</div>

        






        {this.state.smsProvisionButtonStatus===false?(
				<div className="lh10 mt12">
            <button className="button" onClick={(e) => this.provisionTwilio(e)}>PROVISION</button>
				</div>
        ):(
          ''
        )}
            {this.state.smsProvisionStatus===true?(
            <div className="provisionWaitbox">
              <div className="row">
                <div className="col-md-3">
                  <img src={img_prLoader} style={{margin:'10px',height:'30px'}} alt="img_prLoader"/>
                </div>
                <div className="col-md-9 mt10">
                  <p className="labelText colorChange">Please Wait...</p>
                  <p className="labelText colorChange mt10">Provisioning is in progress</p>
                </div>
              </div>
            </div>
            ):(
                ''
            )}
           {this.state.smsProvisionButtonStatus===true?(
             <div className="mt16 lh16">
                <div className="labelText lh10">Auto Response</div>
                <input 
                type="radio"
                className="mt12"
                name="autoresp"
                onChange={this.responseHandler}
                value="YES"
                checked={this.state.responseSelection==='YES'?true:false}
                /> Yes &nbsp;

                <input 
                type="radio"
                className="mt12"
                name="autoresp"
                onChange={this.responseHandler}
                value="NO"
                checked={this.state.responseSelection==='NO'?true:false}
                /> No
                <div className="mt12">
                  <span className="labelText lh10">Auto Response Message</span>
                  <input
                    type="text"
                    value={this.state.responseMsg}
                    onChange={this.responseMsgHandler}
                    className="txtType widthForpanel3text mt10"
                    readOnly={this.state.autoResponseStatus}
                  />
                </div>
                <div className="dividerFullWidthOthers"></div>
                <div className="mt16">
                  <span className="labelSubText">Anti spam feature</span>
                </div>
                <div className="mt12">
                  <span className="labelText lh10">Select the minimum time to wait before sending a next message</span>
                  <select className="txtType arrowAlignForPanel3 widthForpanel3text mt10" onChange={this.antiSpamTimerHandler} value={this.state.antiSpamTimer}>
                    <option value="1">1 Hour</option>
                    <option value="2">2 Hour</option>
                    <option value="4">4 Hour</option>
                    <option value="8">8 Hour</option>
                    <option value="12">12 Hour</option>
                    <option value="24">24 Hour</option>
                  </select>
                </div>


                <div className="dividerFullWidthOthers"></div>
                <div className="mt16">
                  <span className="labelSubText">Alphanumeric feature</span>
                </div>
                <div className="mt16 lh16">
                  <span className="labelText">Country :
                  </span>

                  <select className="txtType arrowAlignForPanel3 widthForpanel3text mt10" onChange={this.setCountry} value={this.state.alphaNumericCountry}>
                            <option value="0">Select Country</option>
                            {Object.keys(countryListJson).sort().map((country) => (
                            <option value={country}>{country}</option>
                              ))}

                          </select>

				        </div>




                <div className="mt12 lh16 mb10 full-width">
                  <span className="labelText float-left" style={{lineHeight:'12px'}}>Enable alphanumeric:
                  </span>
                  <span className="labelText colorChange float-left"><input className='ms-10' type="checkbox" onChange={this.isAlphaNumeric} checked={this.state.alphaNumericEnabled}/></span>
                </div>

                <div className="mt12 lh16">
                  <span className="labelText lh10">Alphanumeric :
                  </span>
                  <input type='text' className="txtType widthForpanel3text mt10" onChange={this.sampleTextInput} value={this.state.alphaNumericSenderId} disabled={this.state.alphaFieldDisabled}/>
                </div>


                <div className="lh10 mt12">
                    <button className="button" onClick={(e) => this.updateResponse(e)}>SUBMIT</button>
                </div>
            </div>
           ):(
             ''
           )}
			</div>
		</div>
		)
  }
  else if(this.state.pannelVal==='Hubspot')
	{
		return(
			<div id="Panel_4___Connection_Progress">
			<div id="Connection_Progress" className="lh16">
				<span>Hubspot</span>
			</div>
			<div id="InspectorChat" className="mt16">
        
        
      <div className="mt12">
          <span className="labelText lh10">Api Key</span>
          <input
            type="text"
            className="txtType widthForpanel3text mt10"
            
          />
      </div>
			</div>
		</div>
		)
  }
  else if(this.state.pannelVal==="Whatsapp" || this.state.pannelVal==="Snapchat"
  || this.state.pannelVal==="Wechat" || this.state.pannelVal==="kik" || this.state.pannelVal==="line"
  || this.state.pannelVal==="Instagram" || this.state.pannelVal==="Twitter" || this.state.pannelVal==="Viber"
  || this.state.pannelVal==="Telegram" || this.state.pannelVal==="Facebook"
  || this.state.pannelVal==="QQ" || this.state.pannelVal==="Pinterest" || this.state.pannelVal==="Linkedin"
  || this.state.pannelVal==="Youtube" || this.state.pannelVal==="Reddit"
  || this.state.pannelVal==="Sentiment Analysis"
  || this.state.pannelVal==="Text to Speech" || this.state.pannelVal==="Speech to Text"
  || this.state.pannelVal==="Visual Bot" || this.state.pannelVal==="Personality Bot"
  || this.state.pannelVal==="Visual Recognition" || this.state.pannelVal==="Language Processing"
  || this.state.pannelVal==="Language Translator" || this.state.pannelVal==="Personality Insights"
  || this.state.pannelVal==="Language Classifier"
  || this.state.pannelVal==="Microsoft Dynamics"
  || this.state.pannelVal==="SAP" || this.state.pannelVal==="Siebel" || this.state.pannelVal==="ZOHO"
  || this.state.pannelVal==="ACT!"
  )
	{
		return(
			<div id="Panel_4___Connection_Progress">
        <div id="Connection_Progress" className="lh16">
          {this.state.pannelVal}
        </div>
        <div className="descriptor_outer heightForInactive mt16">
        <div id="Descriptor">
					<p className="noInteractionText">This panel shows how to make a feature work. When setup correctly it will
display a checkmark in the top right corner of the feature. In a Business Process Workflow on an Interaction
Board this panel shows all the operational information.
          </p>
          <p className="noInteractionText">
            Add to the Inteaction Board by double clicking
          </p>
          <p className="noInteractionText">
            NOTE : This component yet not active
          </p>
          
				</div>
        </div>
				<div id="Inspector">
					<div id="Studio_Flow_SID" className="comingSoonBox">
              <div className="row" style={{margin:'0px'}}>
                  <div className="col-md-3" style={{padding:'0px'}}>
                      <img src={this.state.inactiveIcon} alt="iconName" style={{height:'52px'}}/>
                  </div>
                  <div className="col-md-9" style={{padding:'0px'}}>
                    <p className="noInteractionHeader_ribbon">{this.state.pannelVal}</p>
                    <p className="margin-align noInteractionText_ribbon" style={{marginTop:'14px'}}>
                      <span className="comingsoon_text">COMING SOON</span>
                    </p>
                  </div>
              </div>
					</div>
				</div>
			</div>
		  );
	}
	else if(this.state.pannelVal==="Sentiment Bot")
	{
    
		return(
			<div id="Panel_4___Connection_Progress">
			<div id="Connection_Progress" className="lh16">
				<span>Sentiment Bot</span>
			</div>
			<div id="InspectorChat" className="mt16">
        <div className="margin-align">
            <span className="labelText spanText">Domain Name : </span>
            <span className="labelText spanText colorChange">
              {this.state.defaultOrgName}
              {this.state.deallocateBotButton===true?(
              <button className="button_delete pull-right type_a mr20" onClick={(e) => this.deallocateBot(e)}>
                DEALLOCATE
              </button>
              ):(
                ''
              )}
            </span>
          
        </div>
      <div>
          <div className="mt12">
            <div className="mt16 lh16">
              <span className="labelSubText">Zendesk options</span>
            </div>
          </div>
          
              {this.state.sentimentBotZendesk.map((value, key) => (
                <div className="mt12 lh12" key={key} style={{height:'25px'}}>
                    <span className="labelText">{value.name}</span>
                    <label className="switch mr26 pull-right">
                      <input type="checkbox" checked={value.status} onChange={(e) => this.changeStatusForSABot(e,'zendesk',key)}/>
                      <span className="slider round"></span>
                    </label>
                </div>
              ))}   
          
          
        </div>
        <div>
          <div className="mt12">
            <div className="mt16 lh16">
              <span className="labelSubText">Salesforce options</span>
            </div>
          </div>
          
              {this.state.sentimentBotSalesforce.map((value, key) => (
                <div className="mt12 lh12" key={key} style={{height:'25px'}}>
                    <span className="labelText">{value.name}</span>
                    <label className="switch mr26 pull-right">
                      <input type="checkbox" checked={value.status} onChange={(e) => this.changeStatusForSABot(e,'salesforce',key)}/>
                      <span className="slider round"></span>
                    </label>
                </div>
              ))}
        </div>
        {this.state.saProvisionButtonStatus===false?(
          <button className="button mt16" onClick={(e) => this.createSABot(e)}>
                PROVISION
          </button>
        ):(
          <button className="button mt16" onClick={(e) => this.updateSABot(e)} disabled={this.state.SaBotProvisioningStatus}>
                UPDATE
          </button>
        )}
        <div className="mt12 lh12">
					{this.state.SaBotProvisioningStatus===true?(
						<div className="provisionWaitbox">
            <div className="row">
              <div className="col-md-3">
                <img src={img_prLoader} style={{margin:'10px',height:'30px'}} alt="img_prLoader"/>
              </div>
              <div className="col-md-9 mt10">
                <p className="labelText colorChange">Please Wait...</p>
                <p className="labelText colorChange mt10">Provisioning is in progress</p>
              </div>
            </div>
          </div>
					):(
						''
					)}
			  	</div>
			</div>
		</div>
		)
	}
	else if(this.state.pannelVal==="Elastic Search")
	{
		return(
			<div id="Panel_4___Connection_Progress">
			<div id="Connection_Progress" className="lh16">
				<span>Dialogue Search</span>
        <span className="ml10 type_a pull-right" style={{marginRight:'1px',marginTop:'-3px'}} onClick={(e) => this.manualRefresh(e)}>
            <img src={img_Refresh} data-tip="Refresh" alt="refresh"/>
            <ReactTooltip place="bottom" type="dark" effect="solid"/>
        </span>
			</div>
      <div className="scrollForInteraction">
			<div id="InspectorChat" className="mt16 lh16">
				
        <div className="margin-align mt12">
					<span className="labelText spanText lh12">Provisioned Resources : 
          {this.state.checkingSearch===false && this.state.csvUploadUrl!==null?(
            <button className="button_delete type_a ml10" onClick={(e) => this.deallocateElasticSearch(e)}>
              DEALLOCATE
            </button>
					):(
						''
					)}
          </span>
          <span className="labelText spanText colorChange lh12">{this.state.resourceName}</span>
          
				</div>
        <div className="mt12">
        <span className="labelText lh10">Volume Size</span>
            {this.state.csvUploadUrl===null?(
            <select className="txtType arrowAlignForPanel3 widthForpanel3text mt10" onChange={this.volumeSizeHandler} value={this.state.volumeSize}>
              <option value="XX">Please Select</option>
              <option value="t3.small.elasticsearch">Small</option>
              <option value="t3.medium.elasticsearch">Medium</option>
              <option value="m5.large.elasticsearch">Large</option>
            </select>
            ):(
            <select className="txtType arrowAlignForPanel3 widthForpanel3text mt10" onChange={this.volumeSizeHandler} value={this.state.volumeSize} disabled={true}>
              <option value="XX">Please Select</option>
              <option value="t3.small.elasticsearch">Small</option>
              <option value="t3.medium.elasticsearch">Medium</option>
              <option value="m5.large.elasticsearch">Large</option>
            </select>
            )}
        </div>

        <div className="mt12">
					
            {this.state.csvUploadUrl===null && this.state.csvUploadStatus===true?(
            <button className="button mt12"
            onClick={(e) => this.sendElasticCreds(e)}
            >
              Provision
            </button>
            ):(
              ''
            )}
            
				</div>
        <div className="mt12 lh12">
					{this.state.csvUploadStatus===false?(
						<div className="provisionWaitbox">
            <div className="row">
              <div className="col-md-3">
                <img src={img_prLoader} style={{margin:'10px',height:'30px'}} alt="img_prLoader"/>
              </div>
              <div className="col-md-9 mt10">
                <p className="labelText colorChange">Please Wait...</p>
                <p className="labelText colorChange mt10">Provisioning is in progress</p>
              </div>
            </div>
          </div>
					):(
						''
					)}
			  	</div>
          <div className="dividerFullWidthOthers"></div>
          <div className="mt12">
            <span className="labelText lh10">Match Type</span>
            <select className="txtType arrowAlignForPanel3 widthForpanel3text mt10" onChange={this.typeMatchHandler} value={this.state.typeMatch}>
                <option value="ExactMatch">Exact match</option>
                <option value="BestMatch">Best match</option>
            </select>
          </div>
				<div className="mt12">
					<span className="labelText lh10">Messege if data is not present</span>
					<input
						type="text"
						value={this.state.errorMsg}
						onChange={this.errorMsgHandler}
						className="txtType widthForpanel3text mt10"
					/>
          {this.state.csvUploadUrl!=null?(
					<button className="button mt12" onClick={(e) => this.setDefaultMsg(e)}>
							Submit
					</button>
          ):(
            <button className="button mt12" disabled>Submit</button>
          )}
				</div>
        
        <div className="dividerFullWidthOthers"></div>
        <div className="margin-align mt12">
          <span className="labelText spanText lh16">Last uploaded csv file : </span>
          <span className="labelText spanText colorChange lh16">{this.state.getLastCsvFileName}</span>
        </div>
        <div className="mt12">
          <span className="labelText lh16">Number of rows : </span>
          <span className="labelText colorChange lh16">{this.state.getLastCsvRows}</span>
        </div>
        {this.state.getLastCsvFileName==='' || this.state.getLastCsvFileName===null || this.state.getLastCsvFileName===undefined?(
          ''
        ):(
          <div>
            <button className="button mt12 w-132" onClick={this.getCsvRows}>View Data</button>
            <button className="button mt12 w-132" onClick={this.openConfirmForDeleteCsv}>Delete Data</button>
          </div>
        )}
				<div className="mt12">
        {this.state.csvUploadUrl!=null?(
          <div className="upload-btn-wrapper">
            <CSVReader
              ref={buttonRef}
              onFileLoad={this.handleOnFileLoad}
              
              onError={this.handleOnError}
              noClick
              noDrag
              onRemoveFile={this.handleOnRemoveFile}
            >
              {({ file }) => (
                <aside
                  style={{
                    /*display: 'flex',*/
                    flexDirection: 'row',
                    marginBottom: 10
                  }}
                >
                  <button
                    type='button'
                    onClick={this.handleOpenDialog}
                    className="upload_button"
                  >
                    UPLOAD CSV
                  </button>
                  <div id="getCsvName" className="labelText spanText colorChange mt10 ml10">
                    {file && file.name}
                  </div>
                </aside>
              )}
            </CSVReader>
          </div>
        ):(
          <button className="upload_button" disabled>Upload Csv</button>
        )}
				  {this.state.numberOfRows>0 ?(
              <p className="labelText mt10 lh10">{this.state.numberOfRows} rows waiting for insert</p>
                ):(
                  ''
              )}
              <div className="mt10">
					      <input 
                  type="checkbox"
                  onChange={this.csvPatternChange}
                  checked={this.state.checkedPattern}
                  className="lh10"
                  /> <span className="labelText">My csv doesnot have header</span>
                </div>
					        <p className="labelText mt10 lh10">Please Select Unique ID</p>
                  {this.state.checkedPattern===false?(
                    <select className="txtType widthForpanel3text arrowAlignForPanel3 mt10" onChange={this.uniqueIdHandler} value={this.state.uniqueId}>
                            <option value="0">Please Select</option>
                            {this.state.selectUniqueId.map((value,key) => (
                              <option key={key} value={key+1}>{value}</option>
                            ))}
                      </select>
                  ):(
                    <select className="txtType widthForpanel3text arrowAlignForPanel3 mt10" onChange={this.uniqueIdHandler} value={this.state.uniqueId}>
                            <option value="0">Please Select</option>
                            {this.state.selectUniqueId.map((value,key) => (
                              <option key={key} value={key+1}>Column {key+1}</option>
                            ))}
                      </select>
                  )}
                  <p className="labelText mt10 lh10">Please select the data column to search</p>
                  {this.state.checkedPattern===false?(
                    <select className="txtType widthForpanel3text arrowAlignForPanel3 mt10" onChange={this.shippingIdHandler} value={this.state.shippingId}>
                            <option value="0">Please Select</option>
                            {this.state.selectUniqueId.map((value,key) => (
                              <option key={key} value={key+1}>{value}</option>
                            ))}
                      </select>
                  ):(
                    <select className="txtType widthForpanel3text arrowAlignForPanel3 mt10" onChange={this.shippingIdHandler} value={this.state.shippingId}>
                            <option value="0">Please Select</option>
                            {this.state.selectUniqueId.map((value,key) => (
                              <option key={key} value={key+1}>Column {key+1}</option>
                            ))}
                      </select>
                  )}
					      {this.state.numberOfRows>0 && this.state.csvUploadUrl!=null && this.state.uniqueId!==0 && this.state.shippingId!==0?(
                        <div>
                          <button className="button mt12" onClick={(e) => this.uploadCsv(e)}>
                              Load Data
                          </button>
                        </div>
                      ):(
                        <div>
                          <div className="button disabled mt12" data-tip="Please select csv first or create url first"> Load Data </div>
                          <ReactTooltip place="bottom" type="dark" effect="solid"/>
                        </div>

                      )}
					          <p className="labelText mt12 lh12">{this.state.insertMsg}</p>
				</div>
				
				
			</div>
      </div>
		</div>
		)
  }
  else if(this.state.pannelVal==='Lambda Faq')
  {
    return(
			<div id="Panel_4___Connection_Progress">
        <div id="Connection_Progress" className="lh16">
          Query Bot
        </div>
        <div className="descriptor_outer mt16">
        <div id="Descriptor">
					<p className="noInteractionText">Double click on the Query Bot and add it to the interaction board to edit the settings.
          </p>
				</div>
        </div>
				
			</div>
		  );
  }
	else if(this.state.pannelVal==='Zendesk')
	{
		return(
			<div id="Panel_4___Connection_Progress">
			<div id="Connection_Progress" className="lh16">
				<span>Zendesk</span>
			</div>
			<div id="InspectorChat" className="mt16">
				<div className="lh10">
					<span className="labelText ">Enter UserName</span>
					<input
					type="text"
					className="txtType widthForpanel3text mt10"
					value={this.state.zenUser}
					onChange={this.zenUserHandler}
					/>
					
				</div>
				<div className="lh10 mt12">
					<span className="labelText">Enter Api Key</span>
					<input
					type="text"
					className="txtType widthForpanel3text mt10"
					value={this.state.apikey}
					onChange={this.apiKeyHandler}
					/>
        </div>
        <div className="lh10 mt12">
					<span className="labelText">Enter Zendesk Url</span>
					<input
					type="text"
					className="txtType widthForpanel3text mt10"
					value={this.state.zendeskUrl}
					onChange={this.zendeskurlHandler}
					/>
        </div>
        <div className="mt10">
				<button className="button mt12"
				onClick={(e) => this.sendZendeskCreds(e)}
				>
                Submit
              </button>
				</div>
			</div>
		</div>
		)
  }

  else if(this.state.pannelVal==='Sales Force')
	{
		return(
			<div id="Panel_4___Connection_Progress">
			<div id="Connection_Progress" className="lh16">
				<span>Sales Force</span>
			</div>
			<div id="InspectorChat" className="mt16">
				<div className="lh10">
					<span className="labelText">User Name</span>
					<input
					type="text"
					className="txtType widthForpanel3text margin-align"
					value={this.state.sfUsername}
					onChange={this.sfUsernameHandler}
					/>
					
				</div>
        <div className="lh10 mt12">
					<span className="labelText">Password</span>
					<input
					type="password"
					className="txtType widthForpanel3text margin-align"
					value={this.state.sfPassword}
					onChange={this.sfPasswordHandler}
					/>
					
				</div>
        <div className="lh10 mt12">
					<span className="labelText">Access token url</span>
					<input
					type="text"
					className="txtType widthForpanel3text margin-align"
					value={this.state.sfAccessToken}
					onChange={this.sfAccessTokenHandler}
					/>
					
				</div>
				<div className="lh10 mt12">
					<span className="labelText">Base url</span>
					<input
					type="text"
					className="txtType widthForpanel3text margin-align"
					value={this.state.sfBaseUrl}
					onChange={this.sfBaseHandler}
					/>
          
        <button className="button mt12"
        disabled={this.state.sfCreateDisabled}
				onClick={(e) => this.sendSalesforceCreds(e)}
				>
                Create
              </button>
        <button className="button mt12"
        disabled={this.state.sfUpdateDisabled}
				onClick={(e) => this.updtSalesforceCreds(e)}
				>
                Update
              </button>
				</div>
        <div className="dividerFullWidthOthers"></div>
          <div className="mt16">
            <span className="labelSubText">Sentiment Analysis</span>
          </div>
            <div className="mt10 lh10">
              <span className="labelText">Subject Weightage (%)</span>
              <input
              type="text"
              className="txtType widthForpanel3text margin-align"
              maxLength="3"
              value={this.state.subweightage}
              onChange={this.subweightageHandler}
              />
				  </div>
          <div className="mt10 lh10">
              <span className="labelText">Description Weightage (%)</span>
              <input
              type="text"
              className="txtType widthForpanel3text margin-align"
              maxLength="3"
              value={this.state.descWeightage}
              onChange={this.descWeightageHandler}
              />
				  </div>
          <button 
          className="button mt12"
				  onClick={(e) => this.updtSentimentWeightage(e)}
				  >
                Update
          </button>
			</div>
		</div>
		)
  }
  else if(this.state.pannelVal==='Oracle')
	{
		return(
			<div id="Panel_4___Connection_Progress">
			<div id="Connection_Progress" className="lh16">
				<span>Oracle</span>
			</div>
			<div id="InspectorChat" className="mt16">
				<div className="lh10">
					<span className="labelText">User Name</span>
					<input
					type="text"
					className="txtType widthForpanel3text margin-align"
					value={this.state.orUsername}
					onChange={this.orUsernameHandler}
					/>
					
				</div>
        <div className="lh10 mt12">
					<span className="labelText">Password</span>
					<input
					type="password"
					className="txtType widthForpanel3text margin-align"
					value={this.state.orPassword}
					onChange={this.orPasswordHandler}
					/>
					
				</div>
        <div className="lh10 mt12">
					<span className="labelText">Url</span>
					<input
					type="text"
					className="txtType widthForpanel3text margin-align"
					value={this.state.orUrl}
					onChange={this.orUrlHandler}
					/>
					
				</div>
				<div className="lh10 mt12">
        <button className="button mt12"
        disabled={this.state.orCreateDisabled}
				onClick={(e) => this.sendOracleCreds(e)}
				>
                Create
              </button>
        <button className="button mt12"
        disabled={this.state.orUpdateDisabled}
				onClick={(e) => this.updtOracleCreds(e)}
				>
                Update
              </button>
				</div>
        <div className="lh10 mt16">
					<div className="labelText">Organization Id</div>
					<div className="labelText spanText colorChange mt10">{this.state.defaultOrgId}</div>
				</div>
        <div className="lh10 mt16">
					<div className="labelText">Oracle Connector Api Key</div>
    <div className="labelText spanText colorChange mt10" style={{lineHeight:'16px',wordBreak:'break-word'}}>{jsonConfig.oracleConnectorApi}</div>
				</div>
        <div className="lh10 mt16">
					<div className="labelText">Oracle Connector Url</div>
    <div className="labelText spanText colorChange mt10" style={{lineHeight:'16px',wordBreak:'break-word'}}>{jsonConfig.OracleConnectorUrl}</div>
				</div>
			</div>
		</div>
		)
  }
  else if(this.state.pannelVal==="Video Room")
  {
    return(
			<div id="Panel_4___Connection_Progress">
        <div id="Connection_Progress" className="lh16">
          <span>Video Room</span>
        </div>
        <div id="InspectorChat" className="mt16">
         
          
          <div className="mt16 lh10">
            
            <span className="labelText">Select Color</span>
            <select className="txtType arrowAlignForPanel3 widthForpanel3text mt10" onChange={this.vdoSurroundHandler} value={this.state.vdoSurround}>
                  <option value="Blue">Blue</option>
                  <option value="Green">Green</option>
              </select>
          </div>
          
          <div className="mt12">
            <span className="labelText">Save Video</span>
            <label className="switch mr26 pull-right">
              <input type="checkbox" checked={this.state.changeSaveVideo} onChange={this.changeSaveVdoHandler}/>
              <span className="slider round"></span>
            </label>
          </div>
          
          <div className="mt12">
            <span className="labelText">Privacy agreement window</span>
            <label className="switch mr26 pull-right">
              <input type="checkbox" checked={this.state.changeVdoroomPrivacy} onChange={this.changeVdoroomPrivacyHandler}/>
              <span className="slider round"></span>
            </label>
          </div>
            {this.state.changeVdoroomPrivacy===true?(

            <div className="mt12">
              <span className="labelText">Privacy Text</span>
              <textarea
                type="text"
                className="widthForpanel3text margin-align"
                value={this.state.privacyText}
                onChange={this.privacyTextHandler}
              >
              </textarea>
              

            </div>
            ):(
              ''
            )}
          
          <div className="mt12">
            <span className="labelText">Timer</span>
            <label className="switch mr26 pull-right">
              <input type="checkbox" checked={this.state.changeVdoroomTimer} onChange={this.changeVdoroomTimerHandler}/>
              <span className="slider round"></span>
            </label>
          </div>
          {this.state.changeVdoroomTimer===true?(
          <div className="mt12 lh10">
            <span className="labelText">Timer For</span>
              <select className="txtType arrowAlignForPanel3 widthForpanel3text mt10" onChange={this.videoroomTimerHandler} value={this.state.videoroomTimer}>
                  <option value="1">1 Min</option>
                  <option value="2">2 Min</option>
                  <option value="5">5 Min</option>
                  <option value="10">10 Min</option>
                  <option value="30">30 Min</option>
                  <option value="60">1 Hour</option>
              </select>
          </div>
          ):(
            ''
          )}
         {this.state.videoroomButtonstatus===true?(
          <button className="button mt12" onClick={this.addVideoroomSettings}>Update</button>
         ):(
          <button className="button mt12" onClick={this.updateVideoroomSettings}>Update</button>
         )}
          <div className="dividerFullWidthOthers"></div>
          <div className="mt12 lh12">
						<span className="labelText lh10">Video room name</span>
						<input type="text" 
						className="txtType widthForpanel3text mt10" 
						value={this.state.videoroomName}
            onChange={this.videoroomHandler}
						/>
						
          </div>
          <button className="button mt12 w-200" onClick={this.createRoom}>
              Create Video Room
          </button>
          <div className="margin-align lh12 mt12">
              <span className="labelText spanText lh10">Video room Url : </span>
              <span 
                className="labelText spanText colorChange mt10 type_a hyperlink" 
                onClick={()=> window.open(this.state.videoroomUrl, "_blank")}
                style={{lineHeight:'16px',wordBreak:'break-word'}}
                >{this.state.videoroomUrl}</span>
          </div>
        </div>
      </div>
    )
  }
	else{
		return(
			<div id="Panel_4___Connection_Progress">
				<div id="Connection_Progress" className="lh16">
					<span>Board Selection</span>
				</div>
        <div className="scrollForInteraction">
        <div className="filterStatus">
            <span className="labelText">Filter Status</span>
            <select 
            className="txtType arrowAlignForPanel3 widthForpanel3text margin-align" 
            onChange={this.filterBoard} 
            disabled={this.state.disabledFilterStatus}
            >
                <option value="all">All</option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
            </select>
          
        </div>
				<div className="descriptor_outer mt16">
				<div id="Descriptor">
					{this.state.boardList.length>0?(
					this.state.boardList.map((value, key) => (
						<p key={key} 
						className={this.state.activeList === key ? 'Boardtitle activeList': 'Boardtitle'} 
						onClick={(e) => this.getActiveInteraction(value.interactionId,value.json,value.interactionName,value.boardStatus,value.createdAt,value.updatedAt,value.createdBy,value.updatedBy,key)}>
							{value.interactionName}
						</p>
					))
          ):(
            <div>
              <p className="noInteractionHeader">Heads Up !</p>
              <p className="noInteractionText">
              No interaction boards are created yet.
              Get started by setting up Channels, 
              AI Services and CRMs and double click on the icons to add 
              them to the board and create the first interaction.
              </p>
            </div>
          )}
				</div>
        </div>
        
        <div className="dividerFullWidth"></div>
        <div id="Inspector">
					<div id="Whatsapp_1">
              {this.state.boardNameStatus===false?(
                <span>New Board Details</span>
              ):(
                <span>Interaction Details</span>
              )}
					</div>
          <div id="Studio_Flow_SID">
            <div>
              <span>Board Name</span>
              <textarea 
              type="text"
              className="widthForpanel3text margin-align"
              value={this.state.boardName}
              onChange={this.boardNameHandler}
              rows="2"
              cols="2"
              readOnly={this.state.boardNameReadonly}
              />
            </div>
            <div className="margin-align">
              <span className="spanText">Status : </span>
              {this.state.boardNameStatus===false?(
                <span></span>
              ):(
                  this.state.status==='Active'?(
                    <button className="small_button_enable" onClick={(e) => this.changeBoardStat('Inactive')}><i className="fa fa-check"></i>&nbsp;&nbsp;Active</button>
                  ):(
                    <button className="small_button_disable" onClick={(e) => this.changeBoardStat('Active')}><i className="fa fa-times"></i>&nbsp;&nbsp;Inactive</button>
                  )
              )}
					  </div>
            <div className="margin-align">
              <span className="spanText">Created On : </span>
              <span className="colorChange spanText">{this.state.createDate}</span>
            </div>
            <div className="margin-align">
						    <span className="spanText">Updated On : </span>
                <span className="colorChange spanText">{this.state.updateDate}</span>
            </div>
            <div className="margin-align">
              <span className="spanText">Created By : </span>
              <span className="colorChange spanText">{this.state.createdByUser}</span>
            </div>
            <div className="margin-align">
              <span className="spanText">Updated By : </span>
              <span className="colorChange spanText">{this.state.updatedByUser}</span>
            </div>
            <div className="mT24 mb10">
              <button className="button_delete type_a" 
              disabled={this.state.createButtonStatus} 
              onClick={(e) => this.deleteBoard()}
              style={{marginLeft:'1px',marginTop:'0px'}}
              >
                DELETE BOARD
              </button>
            </div>
					</div>
				</div>
        </div>
			</div>
		  );
	}
	
}
changePanelIconClick()
{

  if(this.state.pannelVal==="Messenger")
	{
		return(
			<div id="Panel_4___Connection_Progress">
				<div id="Connection_Progress" className="lh16">
					<span>Messenger</span>
				</div>
        
				<div id="InspectorChat" className="mt16">
          <div className="lh16">
              <span className="labelText lh10">Subscribed Pages</span>
              <select className="txtType arrowAlignForPanel3 widthForpanel3text mt10" onChange={this.pageIdSelect}>
                  <option value="0">Please Select</option>
                  {this.state.messengerPages.map((value, key) => (
                    <option key={key} value={value.pageId}>{value.pagename}</option>
                  ))}
              </select>
            </div>
            <div className="mt12">
              <button 
              className="button"
              onClick={(e) => this.makeEntrypointForMessengerPages(e)}
              >
              Submit
              </button>
            </div>
            <div className="mt16">
              <div id="Whatsapp_1">
                  <span>Added Pages</span>
              </div>
            {this.state.entryPointMessenger.map((value, key) => (
              <div key={key} className="mt10" style={{display:'flex',flexWrap:'flex',justifyContent:'space-between'}}>
                <div className="labelText colorChange">{value.EntryPointIdentifierValue}</div>
                <button className="small_button_disable_round mr20" onClick={(e) => this.deleteMessengerPageFromAddedpage(value.pageId,key,value.interactionId)}>
                  <i className="fa fa-times"></i>
                </button>
              </div>
            ))}
            </div>
				</div>
        
			</div>
		  );
	}
	else if(this.state.pannelVal==="Client Chat")
	{  
		return(
			<div id="Panel_4___Connection_Progress">
				<div id="Connection_Progress" className="lh16">
					<span>Client Chat</span>
				</div>
			<div id="InspectorChat" className="mt16">
				
				<div className="lh16">
				<span className="labelText lh10">Website List</span>
				<select className="txtType arrowAlignForPanel3 widthForpanel3text mt10" onChange={this.websiteIdSelect}>
            <option value="0">Select Website</option>
            {this.state.websiteList.map((value, key) => (
                <option value={value.clientChatId} key={key}>{value.website}</option>
            ))}
        </select>
				
				</div>
        <div className="mt12">
          <button className="button"
          onClick={(e) => this.makeEntrypoint(e)}
          >
          Submit
          </button>
        </div>
				<div className="mt12" style={{position:'relative'}}>
					{this.state.entryPointWebsite.map((value,key) => (
					<div key={key} className="mt12 dropdown_accordian">
					<input type="checkbox" className="acc_chkbox" id={'cc'+key} style={{'opacity': 0}}/>
            <div className="roundShapedDeleteWeb type_a" data-tip="Delete website" onClick={(e) => this.deleteWebsite(value.entryPointIdentifier,key)}>
              <i className="fa fa-times editIconFa"></i>
              <ReactTooltip place="bottom" type="dark" effect="solid"/>
            </div>
					<label className="dropdown_accordian-label widthForpanel3text" htmlFor={'cc'+key}>{value.EntryPointIdentifierValue}</label>
          <div className="dropdown_accordian-content widthForpanel3text">&lt;script id='ze-snippet' src='{jsonConfig.clientChatJs}?clientchatid={value.entryPointIdentifier}&organizationid={this.state.defaultOrgId}'&gt;&lt;/script&gt;</div>
					</div>
					))}
				</div>
				<div className="mt12 margin-align lh12">
					<span className="labelText spanText lh10">Bot Name : </span>
          <span className="labelText spanText mt10 colorChange">{this.state.varBotName}</span>
				</div>
				<div className="mt12 margin-align lh12">
					<span className="labelText spanText lh10">Generic Error Message : </span>
          <span className="labelText spanText mt10 colorChange">{this.state.varGenericErrorMessage}</span>
				</div>
        <div className="mt12 margin-align lh12">
						<span className="labelText spanText lh10">Client Chat Text : </span>
            <span className="labelText spanText mt10 colorChange">{this.state.chatText}</span>
					</div>
        </div>
			</div>
		  );
  }
  else if(this.state.pannelVal==="Live Chat")
	{  
		return(
			<div id="Panel_4___Connection_Progress">
				<div id="Connection_Progress" className="lh16">
					<span>Live Chat</span>
				</div>
			<div id="InspectorChat" className="mt16">
				
				<div className="lh16">
				<span className="labelText lh10">Website List</span>
				<select className="txtType arrowAlignForPanel3 widthForpanel3text mt10" onChange={this.liveChatWebsiteIdSelect}>
            <option value="0">Select Website</option>
            {this.state.websiteLiveList.map((value, key) => (
                <option value={value.liveChatId} key={key}>{value.website}</option>
            ))}
        </select>
				
				</div>
        <div className="mt12">
          <button className="button"
          onClick={(e) => this.makeLiveChatEntrypoint(e)}
          >
          Submit
          </button>
        </div>
				<div className="mt12" style={{position:'relative'}}>
					{this.state.entryPointLiveChatWebsite.map((value,key) => (
					<div key={key} className="mt12 dropdown_accordian">
					<input type="checkbox" className="acc_chkbox" id={'cc'+key} style={{'opacity': 0}}/>
            <div className="roundShapedDeleteWeb type_a" data-tip="Delete website" onClick={(e) => this.deleteLiveWebsiteFromEntryPoint(value.entryPointIdentifier,key)}>
              <i className="fa fa-times editIconFa"></i>
              <ReactTooltip place="bottom" type="dark" effect="solid"/>
            </div>
					<label className="dropdown_accordian-label widthForpanel3text" htmlFor={'cc'+key}>{value.EntryPointIdentifierValue}</label>
          <div className="dropdown_accordian-content widthForpanel3text">&lt;script id='ze-snippet' src='{jsonConfig.liveChatJs}?livechatid={value.entryPointIdentifier}&organizationid={this.state.defaultOrgId}'&gt;&lt;/script&gt;</div>
					</div>
					))}
				</div>
				<div className="mt12 margin-align lh12">
					<span className="labelText spanText lh10">Bot Name : </span>
          <span className="labelText spanText mt10 colorChange">{this.state.livevarBotName}</span>
				</div>
				<div className="mt12 margin-align lh12">
					<span className="labelText spanText lh10">Generic Error Message : </span>
          <span className="labelText spanText mt10 colorChange">{this.state.livevarGenericErrorMessage}</span>
				</div>
        <div className="mt12 margin-align lh12">
						<span className="labelText spanText lh10">Client Chat Text : </span>
            <span className="labelText spanText mt10 colorChange">{this.state.livechatText}</span>
					</div>
        </div>
			</div>
		  );
	}
	else if(this.state.pannelVal==="Chat")
	{
	return(
		<div id="Panel_4___Connection_Progress">
			<div id="Connection_Progress" className="lh16">
				<span>Chat System</span>
			</div>
			<div id="InspectorChat" className="mt16">
				<div className="lh16">
					<span className="labelText">Organization Name : </span>
          <span className="labelText colorChange">{this.state.defaultOrgName}</span>
				</div>
				<div className="margin-align mt12 lh12">
					<span className="labelText spanText lh10">Login Url : </span>
          <span className="labelText spanText colorChange mt10 type_a" onClick={()=> window.open(this.state.blueleapChatLoginUrl, "_blank")}>{this.state.blueleapChatLoginUrl}</span>
				</div>
        <div className="dividerFullWidthOthers"></div>
				<div className="mt16">
					<span className="labelSubText">Admin Details</span>
				</div>
				<div className="margin-align mt16 lh16">
					<span className="labelText spanText lh10">Username : </span>
          <span className="labelText spanText colorChange mt10">{this.state.userName}</span>
				</div>
        <div className="dividerFullWidthOthers"></div>
				<div className="mt16 lh16">
					<span className="labelSubText">Integration User details</span>
				</div>
				<div className="margin-align mt16 lh16">
					<span className="labelText spanText lh10">Integration Username : </span>
          <span className="labelText spanText colorChange mt10">{this.state.integrationUser}</span>
        </div>
        <div className="margin-align mt12 lh12">
					<span className="labelText spanText lh10">Integration Email : </span>
          <span className="labelText spanText colorChange mt10">{this.state.integrationEmail}</span>
				</div>
				
			</div>
		</div>
	)
  }
  else if(this.state.pannelVal==="Sms")
	{
    
    
		return(
			<div id="Panel_4___Connection_Progress">
			<div id="Connection_Progress" className="lh16">
				<span>SMS</span>
			</div>
			<div id="InspectorChat" className="mt16">
        {this.state.smsPosition===0?(
          <div className="lh10 mt16">
            <div className="labelText">Type : </div>
            <div className="labelText spanText colorChange mt10">Outgoing</div>
          </div>
        ):(
          <div className="lh10 mt16">
            <div className="labelText">Type : </div>
            <div className="labelText spanText colorChange mt10">Incoming</div>
          </div>
        )}







                <div className="mt16 lh16">
                  <span className="labelText">Communication Type :
                  </span>
                  <span className="labelText colorChange">
                    <select className="txtType arrowAlignForPanel3 widthForpanel3text mt10" onChange={this.setCommunicationType} value={this.getCommunicationType()} disabled={this.communicationTypeDisabled()}>
                    <option value="Outbound_Only">Outbound Only</option>
                    <option value="Inbound_Only">Inbound Only</option>
                    <option value="Two_Way">Two Way</option>
                    </select>
                  </span>
                </div>

                <div className="mt16 lh16">
                  <span className="labelText">Message Prefix :
                  </span>
                  <input type='text' className="txtType widthForpanel3text mt10" onChange={this.setMessagePrefix} value={this.getMessagePrefix()} disabled={this.checkPrefixSuffixDisabled()}/>
                </div>

                <div className="mt16 lh16">
                  <span className="labelText">Message Suffix :
                  </span>
                 <input type='text' className="txtType widthForpanel3text mt10" onChange={this.setMessageSuffix} value={this.getMessageSuffix()} disabled={this.checkPrefixSuffixDisabled()}/>
                </div>


                <div className="dividerFullWidthOthers"></div>

          {(this.state.smsPosition===0) || (this.state.communicationType=="Two_Way" && this.state.smsPosition>0)?(
          <div>
              <div className="mt12">
                <select className="txtType arrowAlignForPanel3 widthForpanel3text mt10" onChange={this.SmsNumberChange}>
                        <option value="0">Select Phone Number</option>
                        {this.state.getPhoneNumberList.map((value,key) => (
                            <option value={Object.values(value)[0]} key={key}>{Object.values(value)[0]}</option>
                        ))}
                  </select>
              </div>
              <div className="mt12">
                  <button 
                    className="button"
                    onClick={(e) => this.updateSmsTypeinArray(e)}
                  >
                    Submit
                  </button>
              </div>
              <div className="mt16">
                <div id="Whatsapp_1">
                    <span>Added Phone Numbers</span>
                </div>
                {this.state.addedPhoneNumber.map((value, key) => (
                <div className="mt10" key={key} style={{display:'flex',flexWrap:'flex',justifyContent:'space-between'}}>
                  <div className="labelText colorChange">{value.incomingNumber}</div>
                      <button className="small_button_disable_round mr20">
                      <i className="fa fa-times"></i>
                    </button>
                </div>
                ))}
            </div>
          </div>
          ):(
          ''
          )}
          
          
			</div>
		</div>
		)
	}
	else if(this.state.pannelVal==="Whatsapp" || this.state.pannelVal==="Snapchat"
  || this.state.pannelVal==="Wechat" || this.state.pannelVal==="kik" || this.state.pannelVal==="line"
  || this.state.pannelVal==="Instagram" || this.state.pannelVal==="Twitter" || this.state.pannelVal==="Viber"
  || this.state.pannelVal==="Telegram" || this.state.pannelVal==="Facebook"
  || this.state.pannelVal==="QQ" || this.state.pannelVal==="Pinterest" || this.state.pannelVal==="Linkedin"
  || this.state.pannelVal==="Youtube" || this.state.pannelVal==="Reddit"
  || this.state.pannelVal==="Sentiment Analysis"
  || this.state.pannelVal==="Text to Speech" || this.state.pannelVal==="Speech to Text"
  || this.state.pannelVal==="Visual Bot" || this.state.pannelVal==="Personality Bot"
  || this.state.pannelVal==="Visual Recognition" || this.state.pannelVal==="Language Processing"
  || this.state.pannelVal==="Language Translator" || this.state.pannelVal==="Personality Insights"
  || this.state.pannelVal==="Language Classifier" || this.state.pannelVal==="Video Room"
  || this.state.pannelVal==="Microsoft Dynamics"
  || this.state.pannelVal==="SAP" || this.state.pannelVal==="Siebel" || this.state.pannelVal==="ZOHO"
  || this.state.pannelVal==="ACT!"
  )
	{
		return(
			<div id="Panel_4___Connection_Progress">
        <div id="Connection_Progress" className="lh16">
          {this.state.pannelVal}
        </div>
        <div className="descriptor_outer heightForInactive mt16">
        <div id="Descriptor">
					<p className="noInteractionText">This panel shows how to make a feature work. When setup correctly it will
display a checkmark in the top right corner of the feature. In a Business Process Workflow on an Interaction
Board this panel shows all the operational information.
          </p>
          <p className="noInteractionText">
            Add to the Inteaction Board by double clicking.
          </p>
          <p className="noInteractionText">
            NOTE : This component yet not active.
          </p>
          
				</div>
        </div>
				<div id="Inspector">
					<div id="Studio_Flow_SID" className="comingSoonBox">
              <div className="row">
                  <div className="col-md-3">
                      <img src={this.state.inactiveIcon} alt="iconName"/>
                  </div>
                  <div className="col-md-9">
                    <p className="noInteractionHeader">{this.state.pannelVal}</p>
                    <p className="noInteractionText margin-align noInteractionText_ribbon">
                     COMING SOON
                    </p>
                  </div>
              </div>
					</div>
				</div>
			</div>
		  );
	}
	else if(this.state.pannelVal==="Sentiment Bot")
	{
    
		return(
			<div id="Panel_4___Connection_Progress">
			<div id="Connection_Progress" className="lh16">
				<span>Sentiment Bot</span>
			</div>
			<div id="InspectorChat" className="mt16">
      <div className="margin-align lh0">
					<span className="labelText spanText">Domain Name : </span>
          <span className="labelText spanText colorChange">{this.state.defaultOrgName}</span>
      </div>
			</div>
		</div>
		)
	}
	else if(this.state.pannelVal==="Elastic Search")
	{
		return(
			<div id="Panel_4___Connection_Progress">
			<div id="Connection_Progress">
				<span>Dialogue Search</span>
			</div>
			<div id="InspectorChat" className="mt16">
				<div className="lh16">
					<span className="labelText lh10">Domain Name : </span>
          <span className="labelText colorChange mt10">{this.state.defaultOrgName}</span>
				</div>
				<div className="margin-align  mt12 lh12">
					<span className="labelText spanText lh10">Messege if data is not present : </span>
          <span className="labelText spanText colorChange mt10">{this.state.errorMsg}</span>
				</div>
				<div className="margin-align  mt12 lh12">
					<span className="labelText spanText lh10">Provisioned Resources : </span>
          <span className="labelText spanText colorChange mt10">{this.state.resourceName}</span>
				</div>
			</div>
		</div>
		)
  }
  else if(this.state.pannelVal==='Lambda Faq')
  {
    var findAll=this.state.activeInteractions;
    var SearchQueryBotIndex = findAll.map(function(o) { return o.name; }).indexOf("Lambda Faq");
    var SearchSalesforceIndex=findAll.map(function(o) { return o.name; }).indexOf("Sales Force");
    var SearchZendeskIndex=findAll.map(function(o) { return o.name; }).indexOf("Zendesk");
    var SearchChatIndex=findAll.map(function(o) { return o.name; }).indexOf("Chat");
    var SearchOracleIndex=findAll.map(function(o) { return o.name; }).indexOf("Oracle");
    
    let changeQuryBotChatsystem='';
    let changeQuryBotZendesk='';
    let changeQuryBotSalesforce='';
    let changeQuryBotOracle='';
    if(SearchQueryBotIndex!==-1 && SearchSalesforceIndex!==-1)
    {
      changeQuryBotSalesforce=(
        <div>
          <div className="dividerFullWidthOthers"></div>
          <div className="mt12 lh12">
            <span className="labelSubText">Salesforce Settings</span>
          </div>

          {this.state.salesforceInput.map((value,key) => (
            <div className="mt12 lh12" key={key}>
              <span className="labelText lh10">{value.label}</span>
              <label className="switch mr26 pull-right">
              <input type="checkbox" checked={value.status==='enabled'?true:false} onChange={(e) => this.changeStatusForLfaq(e,'salesforce',key)}/>
                <span className="slider round"></span>
              </label>
              <input
              type="text"
              className="txtType widthForpanel3text mt10"
              value={value.Text} 
              onChange={(e) => this.salesforceSettingsForLfaqHandler(e,key)}
              />
              </div>
            ))}
          <button className="button" onClick={(e) => this.updateSalesforceForLfaq(e)}>
							Update
					</button>
        </div>
        
      )
    }
    if(SearchQueryBotIndex!==-1 && SearchZendeskIndex!==-1)
    {
      changeQuryBotZendesk=(
        <div>
          <div className="dividerFullWidthOthers"></div>
          <div className="mt12 lh12">
            <span className="labelSubText">Zendesk Settings</span>
          </div>
          
            {this.state.zendeskInput.map((value,key) => (
              <div className="mt12 lh12" key={key}>
                <span className="labelText lh10">{value.label}</span>
                <label className="switch mr26 pull-right">
                    <input type="checkbox" checked={value.status==='enabled'?true:false} onChange={(e) => this.changeStatusForLfaq(e,'zendesk',key)}/>
                    <span className="slider round"></span>
                </label>
              <input
              type="text"
              className="txtType widthForpanel3text mt10"
              name={key}
              value={value.Text} 
              onChange={(e) => this.zendeskSettingsForLfaqHandler(e,key)}
              />
              </div>
            ))}
          <button className="button" onClick={(e) => this.updateZendeskForLfaq(e)}>
							Update
					</button>
				
        </div>
      )
    }
    if(SearchQueryBotIndex!==-1 && SearchChatIndex!==-1)
    {
      changeQuryBotChatsystem=(
        <div>
          <div className="dividerFullWidthOthers"></div>
          <div className="mt12 lh12">
            <span className="labelSubText">Chat Settings</span>
          </div>
          {this.state.blueleapchatInput.map((value,key) => (
          <div className="mt16 lh12" key={key}>
          <span className="labelText lh10">Enable Live Chat</span>
          <label className="switch mr26 pull-right">
              <input type="checkbox" checked={value.status==='enabled'?true:false} onChange={(e) => this.changeStatusForLfaq(e,'chat',key)}/>
              <span className="slider round"></span>
          </label>
          <div className="mt12 lh12">
          <span className="labelText lh10">Enter the phrase</span>
              <input 
              type="text"
              className="txtType widthForpanel3text mt10"
              value={value.Text}
              onChange={(e) => this.pharseHandler(e,'chat',key)}
              />
              
              </div>
              <div className="mt12">
                  <button className="button"
                  onClick={(e) => this.submitPharse(e,'chat')}
                  >
                  UPDATE
                  </button>
              </div>
          </div>
          ))}
        </div>
      )
    }
    if(SearchQueryBotIndex!==-1 && SearchOracleIndex!==-1)
    {
      changeQuryBotOracle=(
        <div>
          <div className="dividerFullWidthOthers"></div>
          <div className="mt12 lh12">
            <span className="labelSubText">Oracle Settings</span>
          </div>
          {this.state.oracleInput.map((value,key) => (
          <div className="mt16 lh12" key={key}>
          <span className="labelText lh10">Enable Live Chat</span>
          <label className="switch mr26 pull-right">
              <input type="checkbox" checked={value.status==='enabled'?true:false} onChange={(e) => this.changeStatusForLfaq(e,'oracle',key)}/>
              <span className="slider round"></span>
          </label>
          <div className="mt12 lh12">
          <span className="labelText lh10">Enter the phrase</span>
              <input 
              type="text"
              className="txtType widthForpanel3text mt10"
              value={value.Text}
              onChange={(e) => this.pharseHandler(e,'oracle',key)}
              />
              
              </div>
              <div className="mt12">
                  <button className="button"
                  onClick={(e) => this.submitPharse(e,'oracle')}
                  >
                  UPDATE
                  </button>
              </div>
          </div>
          ))}
        </div>
      )
    }
    /*else{
      changeQuryBotContent='';
    }*/
    
    
	return(
	  <div id="Panel_4___Connection_Progress">
		<div id="Connection_Progress" className="lh16">
		  <span>Query Bot</span>
		</div>
    <div className="scrollForInteraction">
		<div id="InspectorChat" className="mt16">
		  <div className="lh16">
			<span className="labelText lh10">Add / Edit Question</span>
			<input 
			type="text"
			className="txtType widthForpanel3text mt10"
			value={this.state.questions}
      onChange={this.questionsHandler}
			/>
		  </div>
		  <div className="mt12 lh12">
			<span className="labelText lh10">Add / Edit Answer</span>
		  <input 
			type="text"
			className="txtType widthForpanel3text mt10"
			value={this.state.answers}
      onChange={this.answersHandler}
			/>
      </div>
      <div className="mt12">
			{this.state.isEditable===false?(
			  <button className="button"
        onClick={(e) => this.createQuestions(e)}
        disabled={this.state.allFaqfieldReadonly}
			  >
				SUBMIT
			  </button>
			  ):(
				<button className="button"
				onClick={(e) => this.updateQuestion(e)}
			  >
				UPDATE
			  </button>
			  )}
		  </div>
      
		  {changeQuryBotChatsystem}
      
      {changeQuryBotZendesk}
      
      {changeQuryBotSalesforce}
      
      {changeQuryBotOracle}

		  <div className="mt12 lh12">
			<span className="labelSubText">FAQ List</span>
		  </div>
		  <div className="mt12 lh12" style={{position:'relative'}}>
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                {this.state.questionList.map((value,key) => (
                    <tr key={key}>
                        <td>
                          <div className="faq_fa_icon type_a" data-tip="Edit Question" onClick={(e) => this.editQuestion(value.question,value.answer,value.faqid,key)}>
                              <i className="fa fa-pencil-square-o"></i>
                              <ReactTooltip place="bottom" type="dark" effect="solid"/>
                          </div>
                        </td>
                        <td>
                          <div className="faq_fa_icon type_a" data-tip="Delete Faq" onClick={(e) => this.deleteFaq(value.question,value.answer,value.faqid,key)}>
                                <i className="fa fa-times"></i>
                                <ReactTooltip place="bottom" type="dark" effect="solid"/>
                          </div>
                        </td>
                        <td>
                          <div className="dropdown_accordian_faq">
                            <input type="checkbox" className="acc_chkbox" id={'chck'+key} style={{'opacity': 0}}/>
                            <label className="dropdown_accordian-label" htmlFor={'chck'+key}>{value.question}</label>
                            <div className="dropdown_accordian-content">{value.answer}</div>
                          </div>
                        </td>
                    </tr>
                  ))}
                </tbody>
            </table>
		  </div>
		  
		</div>
    </div>
	  </div>
	)
  }
	else if(this.state.pannelVal==='Zendesk')
	{
		return(
			<div id="Panel_4___Connection_Progress">
			<div id="Connection_Progress" className="lh16">
				<span>Zendesk</span>
			</div>
			<div id="InspectorChat" className="mt16">
				<div className="lh16 margin-align">
					<span className="labelText spanText lh10">UserName : </span>
          <span className="labelText spanText colorChange mt10">{this.state.zenUser}</span>
				</div>
				<div className="margin-align mt12 lh12">
					<span className="labelText spanText lh10">Api Key : </span>
          <span className="labelText spanText colorChange mt10 apiKeyStyle">{this.state.apikey}</span>
				</div>
        <div className="margin-align mt12 lh12">
					<span className="labelText spanText lh10">Zendesk Url : </span>
          <span className="labelText spanText colorChange mt10 apiKeyStyle">{this.state.zendeskUrl}</span>
				</div>
			</div>
		</div>
		)
  }
  else if(this.state.pannelVal==='Sales Force')
	{
		return(
			<div id="Panel_4___Connection_Progress">
			<div id="Connection_Progress" className="lh16">
				<span>Sales Force</span>
			</div>
			<div id="InspectorChat" className="mt16">
				<div className="margin-align lh16">
					<span className="labelText spanText lh10">User Name : </span>
          <span className="labelText spanText colorChange mt10">{this.state.sfUsername}</span>
				</div>

        <div className="margin-align mt12 lh12">
					<span className="labelText spanText lh10">Access token url : </span>
					<span className="labelText spanText colorChange mt10">{this.state.sfUsername}</span>
				</div>
				<div className="margin-align mt12 lh12">
					<span className="labelText spanText lh10">Base url : </span>
          <span className="labelText spanText colorChange mt10 apiKeyStyle">{this.state.sfBaseUrl}</span>
				</div>
        
			</div>
		</div>
		)
  }
  else if(this.state.pannelVal==='Oracle')
	{
		return(
			<div id="Panel_4___Connection_Progress">
			<div id="Connection_Progress" className="lh16">
				<span>Oracle</span>
			</div>
			<div id="InspectorChat" className="mt16">
        <div className="lh10 mt16">
					<div className="labelText">Organization Id</div>
					<div className="labelText spanText colorChange mt10">{this.state.defaultOrgId}</div>
				</div>
        <div className="lh10 mt16">
					<div className="labelText">Oracle Connector Api Key</div>
					<div className="labelText spanText colorChange mt10" style={{lineHeight:'16px',wordBreak:'break-word'}}>{jsonConfig.oracleConnectorApi}</div>
				</div>
        <div className="lh10 mt16">
					<div className="labelText">Oracle Connector Url</div>
					<div className="labelText spanText colorChange mt10" style={{lineHeight:'16px',wordBreak:'break-word'}}>{jsonConfig.OracleConnectorUrl}</div>
				</div>
			</div>
		</div>
		)
  }
	else{
		return(
			<div id="Panel_4___Connection_Progress">
				<div id="Connection_Progress" className="lh16">
					<span>Board Selection</span>
				</div>
        <div className="scrollForInteraction">
        <div className="filterStatus">
            <span className="labelText">Filter Status</span>
            <select 
            className="txtType arrowAlignForPanel3 widthForpanel3text margin-align" 
            onChange={this.filterBoard} 
            disabled={this.state.disabledFilterStatus}
            >
                <option value="all">All</option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
            </select>
          
        </div>
				<div className="descriptor_outer mt16">
				<div id="Descriptor">
					{this.state.boardList.length>0?(
					this.state.boardList.map((value, key) => (
						<p key={key} 
						className={this.state.activeList === key ? 'Boardtitle activeList': 'Boardtitle'} 
						onClick={(e) => this.getActiveInteraction(value.interactionId,value.json,value.interactionName,value.boardStatus,value.createdAt,value.updatedAt,value.createdBy,value.updatedBy,key)}>
							{value.interactionName}
						</p>
					))
          ):(
            <div>
              <p className="noInteractionHeader">Heads Up !</p>
              <p className="noInteractionText">
              No interaction boards are created yet.
              Get started by setting up Channels, 
              AI Services and CRMs and double click on the icons to add 
              them to the board and create the first interaction.
              </p>
            </div>
          )}
				</div>
        </div>
        
        <div className="dividerFullWidth"></div>
        <div id="Inspector">
					<div id="Whatsapp_1">
              {this.state.boardNameStatus===false?(
                <span>New Board Details</span>
              ):(
                <span>Interaction Details</span>
              )}
					</div>
          <div id="Studio_Flow_SID">
            <div>
              <span>Board Name</span>
              <textarea 
              type="text"
              className="widthForpanel3text margin-align"
              value={this.state.boardName}
              onChange={this.boardNameHandler}
              rows="2"
              cols="2"
              readOnly={this.state.boardNameReadonly}
              />
            </div>
            <div className="margin-align">
              <span className="spanText">Status : </span>
              {this.state.boardNameStatus===false?(
                <span></span>
              ):(
                  this.state.status==='Active'?(
                    <button className="small_button_enable" onClick={(e) => this.changeBoardStat('Inactive')}><i className="fa fa-check"></i>&nbsp;&nbsp;Active</button>
                  ):(
                    <button className="small_button_disable" onClick={(e) => this.changeBoardStat('Active')}><i className="fa fa-times"></i>&nbsp;&nbsp;Inactive</button>
                  )
              )}
					  </div>
            <div className="margin-align">
              <span className="spanText">Created On : </span>
              <span className="colorChange spanText">{this.state.createDate}</span>
            </div>
            <div className="margin-align">
						    <span className="spanText">Updated On : </span>
                <span className="colorChange spanText">{this.state.updateDate}</span>
            </div>
            <div className="margin-align">
              <span className="spanText">Created By : </span>
              <span className="colorChange spanText">{this.state.createdByUser}</span>
            </div>
            <div className="margin-align">
              <span className="spanText">Updated By : </span>
              <span className="colorChange spanText">{this.state.updatedByUser}</span>
            </div>
            <div className="mT24 mb10">
              <button className="button_delete type_a" 
              disabled={this.state.createButtonStatus} 
              onClick={(e) => this.deleteBoard()}
              style={{marginLeft:'1px',marginTop:'0px'}}
              >
                DELETE BOARD
              </button>
            </div>
					</div>
				</div>
        </div>
			</div>
		  );
	}
}

varBotNameHandler = event =>{
	this.setState({varBotName:event.target.value})
}

clientChatGreetingHandler = event =>{
	this.setState({clientChatGreeting:event.target.value})
}

varGenericErrorMessageHandler= event =>{
	this.setState({varGenericErrorMessage:event.target.value})
}

clientChatTimeoutHandler = event => {
  this.setState({
    clientChatTimeout: event.target.value
  });
}

clientChatTimeoutMessageHandler = event => {
  this.setState({
    clientChatTimeoutMessage: event.target.value
  });
}

clientChatColorHandler = event =>
{
  this.setState({
    clientChatColor: event.target.value
  });
}
render() 
{
return (
<div id="Interaction___Connection_Clicked_Once" className="commonWidthHeight">
<SideMenu/>
<div className="middlePanel">
	<div id="Header">
			<div id="Interaction_Board">
				<span>Interaction Board &#8482;</span>
			</div>
			
      {this.state.boardNameStatus===true?(
        <div className="labelText text-right plTnR10">All changes saved {this.state.timeDifference} ago</div>
      ):(
          <div>&nbsp;</div>
      )}

	</div>
	
	<div id="Workflow">
  
  {this.state.activeInteractions.length===0?(
      
			<div className="buttonUpdtSave">
          <img src={img_save_disable} className="img_button_updt type_a" alt="saveDisable"/>
          <img src={img_new} className="img_button_new type_a" onClick={(e) => this.openNewBoard()} alt="update"/>
          <img src={img_remove_disable} className="img_button_rmv type_a" alt="remove"/>    
			</div>
			):(
				<div className="buttonUpdtSave">
				
        {this.state.saveButtonStatus===false?(
        <div>
          <img src={img_save} className="img_button_updt type_a" onClick={(e) => this.saveBoard()} alt="save"/>
          <img src={img_new_disable} className="img_button_new_disable type_a" onClick={(e) => this.updateBoard()} alt="update"/>
        </div>
        ):(
          <div>
            <img src={img_update} className="img_button_updt type_a" onClick={(e) => this.updateBoard()} alt="update"/>
            <img src={img_new} className="img_button_new type_a" onClick={(e) => this.openNewBoard()} alt="update"/>
          </div>
        )}
        <img src={img_remove} className="img_button_rmv type_a" onClick={(e) => this.removeInteraction(e)} alt="remove"/>
			</div>
			)}
    
    <div id="workflow_illustration_v5" 
    className={this.state.activeBackground}
    >
    {this.state.boardName!==''?(
    <div className="resetPanelForBoard" onClick={(e) => this.resetPanel(e)}>
      <div className="interactionNameOvertheboard">{this.state.boardName}</div>
    </div>
      ):(
        ''
      )}
    {this.state.activeInteractions.length===0 ? (
    <div className="msgboardTextPosition">
        <img src={img_blank_msg} className="msgBoard" alt="msg"/>
    </div>
    ):(
      ''
    )}
		{this.state.activeInteractions.length===3 ? (
			this.state.activeInteractions.map((value, key) => (
				<div key={key} onClick={(e) => this.activeMake(e,key,value.name)}>
					<div className={`icon${key}`}>
						<div className={`arrow${key}`}/>
					</div>
					<div className={`item${key}`}>
            <div className={`iconName${key} `+value.extraClassforIconName}>
                <div className={`TextLeftBorder${key}`}></div>
                <div className={`TextRightBorder${key}`}></div>
                {value.displayName}
            </div>
            <div className={this.state.activeIndex === key ? 'activeState': 'hoverState'}></div> 
						<img className={'divServices '+value.extraClass} src={value.icon} alt={this.state.activeInteractions.length} />
					</div>
				</div>
			))
		):(
			this.state.activeInteractions.map((value, key) => (
				<div key={key} onClick={(e) => this.activeMake(e,key,value.name)}>
					<div className={`icon${key}_${key}`}>
						<div className={`arrow${key}_${key}`}/>
					</div>
					<div className={`item${key}_${key}`}>
            <div className={`iconName${key}_${key} `+value.extraClassforIconName}>
                <div className={`TextLeftBorder${key}_${key}`}></div>
                <div className={`TextRightBorder${key}_${key}`}></div>
                {value.displayName}
            </div>
            <div className={this.state.activeIndex === key ? 'activeState': 'hoverState'}></div>
						<img  className={'divServices '+value.extraClass} src={value.icon} alt={this.state.activeInteractions.length} />
					</div>
				</div>
			))
		)}
        <div id="myModal" className="modalBoard" style={{display:this.state.modalDisplay}}>
              <div id="boxShadow" className="modal_content_board">
                  <div className="type_a close_img" onClick={this.closeModal} style={{color:'#82C7FF'}}>
                      <img src ={img_elClose} alt="close" style={{height:'14px',width:'14px'}}/>
                  </div>
                  <div className="mt20" style={{overflow:'hidden'}}>
                      <div className="divHeaderTxt">
                          <p id="modalHeaderText">Interaction Board</p>
                          <div id="modalSubHeaderTxtContent">Create New Board</div>
                      </div>
                  </div>  
                  <div className="mt16 ml25">
                      <textarea 
                      type="text" 
                      className="txtType bordernWidthforPop" 
                      placeholder="New Board Name"
                      value={this.state.boardName}
                      onChange={this.boardNameHandler}
                      rows="2"
                      cols="2"
                      />
                  </div>
                  
                  <div className="mt16 ml25 mb15">
                      <button className="button" onClick={(e) => this.createNewBoard()}>CREATE</button>
                  </div>
              </div>
        </div>
		</div>
		
	</div>

  <div id="grid_inventory_2_0" className="grid_inventory_2_0">
    <div className="row gridHeaderTextAlign">
      <div id="Channels" className="col-md-4">
				<span>Channels</span>
			</div>
			
			<div id="AI_Services" className="col-md-4">
				<span>AI Services</span>
			</div>
			
			<div id="CRM" className="col-md-4">
				<span>CRM</span>
			</div>
    </div>
		<div id="Group_6_e">
			<div id="block_grid">
				<div className="row" style={{marginRight:'0px'}}>
					<div className="col-md-4 autoScroll" style={{height:'380px'}}>
						<div className="col-md-12 Padding5">
						<div className="row" >
						{this.state.availableIntegrations.map((value, key) => (
							<div key={key} 
							className={this.state.activeIcon === key ? 'col-md-6 text-center no-padding type_a selectIcon activeIcon '+value.addClass: 'col-md-6 text-center no-padding type_a selectIcon '+value.addClass}
							onClick={(e) => this.getPannelVal(e,value.name,key,'','')}
              onDoubleClick={(e) => this.getEventCheck(e,value,value.name,value.addClass)}
              >
								<img src={value.icon} alt={value.name} className="inventoryImg"/>
								<p className="nameAdjustment iconLineHeight">{value.displayName}</p>
							</div>
						))}
						</div>
						</div>
					</div>
					<div className="col-md-4 autoScroll" style={{height:'380px'}}>
						<div className="col-md-12 Padding5">
							<div className="row" >
							{this.state.availableServices.map((value, key) => (
							<div key={key} 
							className={this.state.ServiceIcon === key ? 'col-md-6 text-center no-padding type_a selectIcon activeIcon '+value.addClass: 'col-md-6 text-center no-padding type_a selectIcon '+value.addClass}
              onClick={(e) => this.getPannelVal(e,value.name,'',key,'')}
              onDoubleClick={(e) => this.getEventCheck(e,value,value.name,value.addClass)}
              >
								<img src={value.icon} alt={value.name} className="inventoryImg"/>
								<p className="nameAdjustment iconLineHeight">{value.displayName}</p>
							</div>
							))}
							</div>
						</div>
					</div>
					<div className="col-md-4 autoScroll" style={{height:'380px'}}>
						<div className="col-md-12 Padding5">
							<div className="row" >
							{this.state.availableCrm.map((value, key) => (
							<div key={key} 
							className={this.state.CrmIcon === key ? 'col-md-6 text-center no-padding type_a selectIcon activeIcon '+value.addClass: 'col-md-6 text-center no-padding type_a selectIcon '+value.addClass}
              onClick={(e) => this.getPannelVal(e,value.name,'','',key)}
              onDoubleClick={(e) => this.getEventCheck(e,value,value.name,value.addClass)}
              >
								<img src={value.icon} alt={value.name} className="inventoryImg"/>
								<p className="nameAdjustment iconLineHeight">{value.displayName}</p>
							</div>
							))}
							</div>
						</div>
					</div>
				</div>
			</div>
				
		</div>
	</div>
</div>
	<div id="Panel_4___Board" className="Panel_4___Board">
    <div id="pannel3Modal" className="pannel3Modal" style={{display:this.state.panel3Display}}>
        <div id="boxShadow" className="pannel3ButtonTray">
              <div className="mt12 mb12 text-center" style={{position: 'relative'}}>
                  <button 
                  className="button"
                  style={{margin:'0px',display:this.state.openAdminUpdateButton}}
                  onClick={(e) => this.updateAdminUser(e)}>UPDATE</button>

                  <button 
                  className="button"
                  style={{margin:'0px',display:this.state.openIntUserUpdateButton}}
                  onClick={(e) => this.updateSettings(e)}>UPDATE</button>

                  <button 
                  className="button"
                  style={{margin:'0px',display:this.state.openWaitMsg}}
                  onClick={(e) => this.addMsgService(e)}>UPDATE</button>

                  <img 
                    src ={img_elClose} 
                    alt="close"
                    className="type_a"
                    style={{height:'14px',width:'14px',position:'absolute',left:'257px',top:'11px'}} 
                    onClick={(e) => this.closePanel3Pop()}/>
              </div>
          </div>
    </div>
    {this.state.availableChannelsClick===true ? this.changePanel() : this.changePanelIconClick() }
	</div>
	<Modal
          isOpen={this.state.modalGetCsvRowsIsOpen}
          onRequestClose={this.closeGetCsvRowsModal}
          style={{
              overlay: {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 140, 255, 0.45)'
              },
              content : {
              top                   : '50%',
              left                  : '50%',
              right                 : 'auto',
              bottom                : 'auto',
              marginRight           : '-50%',
              transform             : 'translate(-50%, -50%)',
              borderRadius          : '18px',
              backgroundColor       : '#f2f7ff',
              overflow              : 'hidden',
              width                 : '764px',
              height                :'464px',
              padding               :'27px'
              }
          }}
          >
                    <div className="row_content_modal">
                        <div className="modal_header">
                            <div className="modal_close_holder">
                                <img src={img_elClose} alt="close" className="img_close type_a" onClick={this.closeGetCsvRowsModal}/>
                            </div>
                            <div className="modal_header_text_holder ml20">
                                <div className="content_modal_sub_header">Data</div>
                            </div>
                        </div>
                        <div className="personalize_container" style={{height:'350px',overflowY:'scroll'}}>
                          <table className="table table-bordered">
                                        <thead>
                                            
                                        </thead>
                                        <tbody>
                                        {this.state.getTotalCsvRows.map((value, key) => (
                                            <tr key={key}>
                                              <td className="colorChange">{value._source.question}</td>
                                              <td className="labelText">{value._source.answer}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                            </table>
                            
                        </div>
                    </div>
                    
                </Modal>

          <Modal
          isOpen={this.state.modalconfirmDeleteCsvIsOpen}
          onRequestClose={this.closeConfirmDeleteCsvModal}
          style={{
              overlay: {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 140, 255, 0.45)'
              },
              content : {
              top                   : '50%',
              left                  : '50%',
              right                 : 'auto',
              bottom                : 'auto',
              marginRight           : '-50%',
              transform             : 'translate(-50%, -50%)',
              borderRadius          : '18px',
              backgroundColor       : '#f2f7ff',
              overflow              : 'hidden',
              width                 : '400px',
              height                :'160px',
              padding               :'27px'
              }
          }}
          >
                    <div className="row_content_modal">
                        <div className="modal_header">
                            <div className="modal_header_text_holder ml20">
                                <div className="content_modal_sub_header">Are you sure want to delete ?</div>
                            </div>
                        </div>
                        <div className="mt12" style={{display:'flex',justifyContent:'space-between'}}>
                          <button className="button mt12 w-132" onClick={this.cancelDeleteCsv}>Cancel</button>
                          <button className="button mt12 w-132" onClick={this.deleteCsvRows}>Delete</button>
                        </div>
                    </div>
                    
                </Modal>
	
	<NotificationContainer/>
  {this.createLoader()}
  
</div>

        );
      }
      
}