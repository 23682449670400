import React, { Component } from 'react';
import SideMenu from './sideMenu.js';

import {Auth} from "aws-amplify";
import axios from 'axios';
var AWS = require("aws-sdk");
var QuickSightEmbedding = require("amazon-quicksight-embedding-sdk");

const jsonConfig = require("./Config.json");

export default class Quicksight extends Component {
    constructor(props) {
        super(props);
        this.state={
            analyseResultVal:true,
        }
    }
    
    getGlobalSettings()
    {
        var globalSettingsTable="globalSettings"
        var docClient = new AWS.DynamoDB.DocumentClient();
        var getParams = {
            TableName : globalSettingsTable,
            FilterExpression : "environment = :val",
            ExpressionAttributeValues: {
                ":val": jsonConfig.environment
            }
          };
          docClient.scan(getParams, (err, data)=> {
            if (err) {
                console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
            } else {
                console.log("GET STAT",data.Items);
                if(data.Items.length>0)
                {
                    if(data.Items[0].displayAnalyseResult!==undefined)
                    {
                        this.setState({analyseResultVal:data.Items[0].displayAnalyseResult})
                    }
                    else{
                        this.setState({analyseResultVal:true})
                    }
                    
                }
                else{
                    this.setState({analyseResultVal:true})
                }
                this.accessForDisplay(data.Items[0].displayAnalyseResult);
            }
          });
    }
    accessForDisplay(resultStat)
    {
        if(resultStat===false)
        {
            this.props.history.push("/access-denied");
        }
        else{
            this.props.history.push("/results");
        }
    }
async getdetails()
{
    var user =  await Auth.currentAuthenticatedUser();
    console.log("user",user)
    console.log("jwt",  user.signInUserSession.accessToken.jwtToken)
    var apiGatewayUrl = "https://gujdzr6g8k.execute-api.us-east-1.amazonaws.com/prod/getDashboardEmbedURL?";


    const cognitoIdentity = new AWS.CognitoIdentity();
    const cognitoAuthenticatedLogins = "cognito-idp.us-east-1.amazonaws.com" + "/" + jsonConfig.userPoolID
    //logins.put(jsonConfig.awsConfigCredentials.IdentityPoolId + "/" + jsonConfig.userPoolID, userSession.getIdToken().getJWTToken());

    const getIdParams = {
        IdentityPoolId: "us-east-1:a3cc4997-ffcf-4ff4-89d7-9cca3b96a7ea",
        Logins: {[cognitoAuthenticatedLogins]: user.signInUserSession.idToken.jwtToken}
    };

    cognitoIdentity.getId(getIdParams, (err, data) => {
        if (err) {
            console.log('Error obtaining Cognito ID.');
        } else {
            data.Logins = {[cognitoAuthenticatedLogins]: user.signInUserSession.idToken.jwtToken};

            cognitoIdentity.getOpenIdToken(data, (err, openIdToken) => {
                if (err) {
                    console.log('Error obtaining authentication token.');
                } else {
                    console.log("identity success", openIdToken)
                    const parameters = {
                        dashboardId: "87cea301-cc49-474f-8c98-e0d5c426a292",
                        openIdToken: openIdToken.Token,
                        authenticated: true,
                        sessionName: "quicksight",
                        resetDisabled: false,
                        undoRedoDisabled: false
                    }
                    const headers = { 'Content-Type' : 'application/json' }
                    var queryString = Object.keys(parameters).map(key => key + '=' + parameters[key]).join('&');
                
                    apiGatewayUrl = apiGatewayUrl + queryString;
                    axios.get(apiGatewayUrl, { headers: headers})
                    .then((response) => {
                        console.log("respose", response)
                        this.embedDashboard(response.data.EmbedUrl);;
                    })
                    .catch(function (error) {
                        console.log('Error obtaining QuickSight dashboard embed url.');
                    });
                }
            });
        }
    });


}
onVisualLoaded() {
    var div = document.getElementById("loadedContainer");
    div.innerHTML = "Dashboard fully loaded";
}
onError() {
    var div = document.getElementById("errorContainer");
    div.innerHTML = "your seesion has expired";
}
embedDashboard(embedUrl) {
    var containerDiv = document.getElementById("quicksightdashboard");
    var params = {
            url: embedUrl,
            container: containerDiv,
            height: "768px"
        };
        var dashboard = QuickSightEmbedding.embedDashboard(params);
        dashboard.on('error', this.onError);
        dashboard.on('load', this.onVisualLoaded);
}
    componentDidMount()
    {

        this.getdetails();
        this.getGlobalSettings();

    }

    render() {
        
        return (
            <div id="Result" className="commonWidthHeight">
                <SideMenu/>
                <div className="middlePanelOthers_Ar">
                    
                    <div id="Group_2_A1_Group_47_Ar" style={{padding:'24px 24px 0px 24px',marginTop:'29px'}}>
                        <div id="quicksightdashboard"></div>
                    </div>
                </div>
            </div>
        )
    }
}