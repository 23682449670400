/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:a3cc4997-ffcf-4ff4-89d7-9cca3b96a7ea",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_lXJCH1Yhy",
    "aws_user_pools_web_client_id": "vbrs628gd1lfm230r5aubbkbf",
    "oauth": {}
};


export default awsmobile;
